import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('auth');
const checkoutFactory = new AsyncActionFactory('checkoutReducer');
const helperFactory = new AsyncActionFactory('helperReducer');

const AUTH_REQUEST = 'AUTH_REQUEST';
const SIGN_IN_START = 'SIGN_IN_START';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
const RESET_REQUEST = 'RESET_REQUEST';
const RESET_REQUEST_START = 'RESET_REQUEST_START';
const RESET_REQUEST_SUCCESS = 'RESET_REQUEST_SUCCESS';
const RESET_REQUEST_FAIL = 'RESET_REQUEST_FAIL';
const CHECK_PASS_REQUEST = 'CHECK_PASS_REQUEST';
const CHECK_PASS_REQUEST_SUCCESS = 'CHECK_PASS_REQUEST_SUCCESS';
const CHECK_PASS_REQUEST_FAIL = 'CHECK_PASS_REQUEST_FAIL';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const GET_CURRENT_USER = 'GET_CURRENT_USER';
const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL';
const GET_CURRENT_USER_START = 'GET_CURRENT_USER_START';
const CHECK_BY_EMAIL_START = 'CHECK_BY_EMAIL_START';
const CHECK_BY_EMAIL_SUCCESS = 'CHECK_BY_EMAIL_SUCCESS';
const CHECK_BY_EMAIL_FAIL = 'CHECK_BY_EMAIL_FAIL';
const CHECK_BY_EMAIL_REQUEST = 'CHECK_BY_EMAIL_REQUEST';
const SEND_RECOVERY_CODE_START = 'SEND_RECOVERY_CODE_START';
const SEND_RECOVERY_CODE_SUCCESS = 'SEND_RECOVERY_CODE_SUCCESS';
const SEND_RECOVERY_CODE_FAIL = 'SEND_RECOVERY_CODE_FAIL';
const SEND_RECOVERY_CODE_REQUEST = 'SEND_RECOVERY_CODE_REQUEST';
const PASSWORD_RESET_VERIFY_CODE_START = 'PASSWORD_RESET_VERIFY_CODE_START';
const PASSWORD_RESET_VERIFY_CODE_SUCCESS = 'PASSWORD_RESET_VERIFY_CODE_SUCCESS';
const PASSWORD_RESET_VERIFY_CODE_FAIL = 'PASSWORD_RESET_VERIFY_CODE_FAIL';
const PASSWORD_RESET_VERIFY_CODE_REQUEST = 'PASSWORD_RESET_VERIFY_CODE_REQUEST';
const RESET_CHANGE_PASSWORD_START = 'RESET_CHANGE_PASSWORD_START';
const RESET_CHANGE_PASSWORD_SUCCESS = 'RESET_CHANGE_PASSWORD_SUCCESS';
const RESET_CHANGE_PASSWORD_FAIL = 'RESET_CHANGE_PASSWORD_FAIL';
const RESET_CHANGE_PASSWORD_REQUEST = 'RESET_CHANGE_PASSWORD_REQUEST';
const TOGGLE_MENU = 'TOGGLE_MENU';
const GET_ERROR = 'GET_ERROR';
const SEARCH_ADDRESS_REQUEST = 'SEARCH_ADDRESS_REQUEST';
const SEARCH_ADDRESS_START = 'SEARCH_ADDRESS_START';
const SEARCH_ADDRESS_SUCCESS = 'SEARCH_ADDRESS_SUCCESS';
const SEARCH_ADDRESS_FAIL = 'SEARCH_ADDRESS_FAIL';
const SEARCH_ADDRESS_VALUE = 'SEARCH_ADDRESS_VALUE';
const SEARCH_ADDRESS_RESET = 'SEARCH_ADDRESS_RESET';
const SEARCH_FMS_UNIT_REQUEST = 'SEARCH_FMS_UNIT_REQUEST';
const SEARCH_FMS_UNIT_START = 'SEARCH_FMS_UNIT_START';
const SEARCH_FMS_UNIT_SUCCESS = 'SEARCH_FMS_UNIT_SUCCESS';
const SEARCH_FMS_UNIT_FAIL = 'SEARCH_FMS_UNIT_FAIL';
const SEARCH_FMS_UNIT_VALUE = 'SEARCH_FMS_UNIT_VALUE';
const SEARCH_FMS_UNIT_RESET = 'SEARCH_FMS_UNIT_RESET';
const SET_PRODUCT_VALUES = 'SET_PRODUCT_VALUES';
const SET_INSURER_VALUES = 'SET_INSURER_VALUES';
const SET_INSURED_VALUES = 'SET_INSURED_VALUES';
const SET_BENEFICIARY_VALUES = 'SET_BENEFICIARY_VALUES';
const CHANGE_BENEFICIARY_COUNT = 'CHANGE_BENEFICIARY_COUNT';
const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
const CHECK_PRODUCT_REQUEST = 'CHECK_PRODUCT_REQUEST';
const CHECK_PRODUCT_START = 'CHECK_PRODUCT_START';
const CHECK_PRODUCT_SUCCESS = 'CHECK_PRODUCT_SUCCESS';
const CHECK_PRODUCT_FAIL = 'CHECK_PRODUCT_FAIL';
const GET_PRODUCT_FORM_REQUEST = 'GET_PRODUCT_FORM_REQUEST';
const GET_PRODUCT_FORM_START = 'GET_PRODUCT_FORM_START';
const GET_PRODUCT_FORM_SUCCESS = 'GET_PRODUCT_FORM_SUCCESS';
const GET_PRODUCT_FORM_FAIL = 'GET_PRODUCT_FORM_FAIL';
const CHECK_SUM_START = 'CHECK_SUM_START';
const CHECK_SUM_REQUEST = 'CHECK_SUM_REQUEST';
const CHECK_SUM_SUCCESS = 'CHECK_SUM_SUCCESS';
const CHECK_SUM_FAIL = 'CHECK_SUM_FAIL';
const CHECK_PACKAGE_SERVICE_START = 'CHECK_PACKAGE_SERVICE_START';
const CHECK_PACKAGE_SERVICE_REQUEST = 'CHECK_PACKAGE_SERVICE_REQUEST';
const CHECK_PACKAGE_SERVICE_SUCCESS = 'CHECK_PACKAGE_SERVICE_SUCCESS';
const CHECK_PACKAGE_SERVICE_FAIL = 'CHECK_PACKAGE_SERVICE_FAIL';
const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
const GET_OPTIONS_FAIL = 'GET_OPTIONS_FAIL';
const GET_MULTIPLE_OPTIONS = 'GET_MULTIPLE_OPTIONS';
const SAVE_FORMS_REQUEST = 'SAVE_FORMS_REQUEST';
const SAVE_FORMS_FORM_START = 'SAVE_FORMS_FORM_START';
const SAVE_FORMS_SUCCESS = 'SAVE_FORMS_SUCCESS';
const SAVE_FORMS_FAIL = 'SAVE_FORMS_FAIL';
const RESET_CTS_IDS = 'RESET_CTS_IDS';
const RESET_FORMS = 'RESET_FORMS';
const RESET_ERRORS = 'RESET_ERRORS';
const GET_DATA_CONTRACT_REQUEST = 'GET_DATA_CONTRACT_REQUEST';
const GET_DATA_CONTRACT_START = 'GET_DATA_CONTRACT_START';
const GET_DATA_CONTRACT_SUCCESS = 'GET_DATA_CONTRACT_SUCCESS';
const GET_DATA_CONTRACT_FAIL = 'GET_DATA_CONTRACT_FAIL';
const UPLOAD_SCAN_REQUEST = 'UPLOAD_SCAN_REQUEST';
const UPLOAD_SCAN_START = 'UPLOAD_SCAN_START';
const UPLOAD_SCAN_SUCCESS = 'UPLOAD_SCAN_SUCCESS';
const UPLOAD_SCAN_FAIL = 'UPLOAD_SCAN_FAIL';
const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL';
const DELETE_FILE = 'DELETE_FILE';
const SET_FILE_TYPE = 'SET_FILE_TYPE';
const SAVE_SCAN_REQUEST = 'SAVE_SCAN_REQUEST';
const SAVE_SCAN_SUCCESS = 'SAVE_SCAN_SUCCESS';
const SAVE_SCAN_FAIL = 'SAVE_SCAN_FAIL';
const GET_CONTRACT_FILE_REQUEST = 'GET_CONTRACT_FILE_REQUEST';
const GET_CONTRACT_FILE_SUCCESS = 'GET_CONTRACT_FILE_SUCCESS';
const GET_CONTRACT_FILE_FAIL = 'GET_CONTRACT_FILE_FAIL';
const GET_REGENERATE_CONTRACT_FILE_REQUEST = 'GET_REGENERATE_CONTRACT_FILE_REQUEST';
const GET_REGENERATE_CONTRACT_FILE_SUCCESS = 'GET_REGENERATE_CONTRACT_FILE_SUCCESS';
const GET_REGENERATE_CONTRACT_FILE_FAIL = 'GET_REGENERATE_CONTRACT_FILE_FAIL';
const SET_INSURED_IS_SAME = 'SET_INSURED_IS_SAME';
const START_VALIDATE_TABS = 'START_VALIDATE_TABS';
const CLEAR_VALIDATE_TABS = 'CLEAR_VALIDATE_TABS';
const SET_PRODUCT_SCENARIO = 'SET_PRODUCT_SCENARIO';
const GET_IP_ADDRESS_SUCCESS = 'GET_IP_ADDRESS_SUCCESS';
const GET_IP_ADDRESS_FAIL = 'GET_IP_ADDRESS_FAIL';
const GET_IP_ADDRESS_REQUEST = 'GET_IP_ADDRESS_REQUEST';
const GET_CAPTCHA_REQUEST = 'auth/GET_CAPTCHA_REQUEST';
const GET_CAPTCHA_START = 'auth/GET_CAPTCHA_START';
const GET_CAPTCHA_FAIL = 'auth/GET_CAPTCHA_FAIL';
const GET_CAPTCHA_SUCCESS = 'auth/GET_CAPTCHA_SUCCESS';
const LOG_OUT = 'LOG_OUT_REQUEST';
const LOG_OUT_START = 'LOG_OUT_START';
const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
const RECAPTCHA = 'GET_RECAPTCHA';
const RESET_TEMPORARY_PASSWORD_REQUEST = 'RESET_TEMPORARY_PASSWORD_REQUEST';
const RESET_TEMPORARY_PASSWORD_START = 'RESET_TEMPORARY_PASSWORD_START';
const RESET_TEMPORARY_PASSWORD_FAIL = 'RESET_TEMPORARY_PASSWORD_FAIL';
const RESET_TEMPORARY_PASSWORD_SUCCESS = 'RESET_TEMPORARY_PASSWORD_SUCCESS';
const SET_STORE_TOKEN = 'SET_STORE_TOKEN';

const GET_BRANCHES_FOR_AUTH_REQUEST = 'auth/GET_BRANCHES_FOR_AUTH_REQUEST';
const GET_BRANCHES_FOR_AUTH_START = 'auth/GET_BRANCHES_FOR_AUTH_START';
const GET_BRANCHES_FOR_AUTH_SUCCESS = 'auth/GET_BRANCHES_FOR_AUTH_SUCCESS';
const GET_BRANCHES_FOR_AUTH_FAIL = 'auth/GET_BRANCHES_FOR_AUTH_FAIL';

const GET_PARTNERS_FOR_AUTH_REQUEST = 'auth/GET_PARTNERS_FOR_AUTH_REQUEST';
const GET_PARTNERS_FOR_AUTH_START = 'auth/GET_PARTNERS_FOR_AUTH_START';
const GET_PARTNERS_FOR_AUTH_SUCCESS = 'auth/GET_PARTNERS_FOR_AUTH_SUCCESS';
const GET_PARTNERS_FOR_AUTH_FAIL = 'auth/GET_PARTNERS_FOR_AUTH_FAIL';

const SET_AFFILIATION_REQUEST = 'auth/SET_AFFILIATION_REQUEST';
const SET_AFFILIATION_START = 'auth/SET_AFFILIATION_START';
const SET_AFFILIATION_SUCCESS = 'auth/SET_AFFILIATION_SUCCESS';
const SET_AFFILIATION_FAIL = 'auth/SET_AFFILIATION_FAIL';

const TOGGLE_MODAL_AFFILIATION = 'auth/TOGGLE_MODAL_AFFILIATION';
const SELECT_BRANCH_FOR_AUTH = 'auth/SELECT_BRANCH_FOR_AUTH';
const SELECT_PARTNER_FOR_AUTH = 'auth/SELECT_PARTNER_FOR_AUTH';

const SET_FUND_DESCRIPTION = 'SET_FUND_DESCRIPTION';

const GET_CONTRACT_COPY_REQUEST = 'GET_CONTRACT_COPY_REQUEST';
const GET_CONTRACT_COPY_START = 'GET_CONTRACT_COPY_START';
const GET_CONTRACT_COPY_FAIL = 'GET_CONTRACT_COPY_FAIL';
const GET_CONTRACT_COPY_SUCCESS = 'GET_CONTRACT_COPY_SUCCESS';

const PARSE_XML_FILE_FOR_SELECTS_REQUEST = 'PARSE_XML_FILE_FOR_SELECTS_REQUEST';
const PARSE_XML_FILE_FOR_SELECTS_START = 'PARSE_XML_FILE_FOR_SELECTS_START';
const PARSE_XML_FILE_FOR_SELECTS_SUCCESS = 'PARSE_XML_FILE_FOR_SELECTS_SUCCESS';
const PARSE_XML_FILE_FOR_SELECTS_FAIL = 'PARSE_XML_FILE_FOR_SELECTS_FAIL';

const START_AUTO_IMPORT_REQUEST = 'START_AUTO_IMPORT_REQUEST';
const START_AUTO_IMPORT_START = 'START_AUTO_IMPORT_START';
const START_AUTO_IMPORT_SUCCESS = 'START_AUTO_IMPORT_SUCCESS';
const START_AUTO_IMPORT_FAIL = 'START_AUTO_IMPORT_FAIL';

const GET_AUTO_IMPORT_JOBS_REQUEST = 'GET_AUTO_IMPORT_JOBS_REQUEST';
const GET_AUTO_IMPORT_JOBS_START = 'GET_AUTO_IMPORT_JOBS_START';
const GET_AUTO_IMPORT_JOBS_FAIL = 'GET_AUTO_IMPORT_JOBS_FAIL';
const GET_AUTO_IMPORT_JOBS_SUCCESS = 'GET_AUTO_IMPORT_JOBS_SUCCESS';

const CANCEL_CURRENT_JOB_REQUEST = 'CANCEL_CURRENT_JOB_REQUEST';
const CANCEL_CURRENT_JOB_START = 'CANCEL_CURRENT_JOB_START';
const CANCEL_CURRENT_JOB_SUCCESS = 'CANCEL_CURRENT_JOB_SUCCESS';
const CANCEL_CURRENT_JOB_FAIL = 'CANCEL_CURRENT_JOB_FAIL';

const CLOSE_IMPORT_FILE_FROM1C_POPUP = 'CLOSE_IMPORT_FILE_FROM1C_POPUP';

const CHANGE_AUTO_IMPORT_SETTINGS = 'CHANGE_AUTO_IMPORT_SETTINGS';

const GET_BUILD_HASH_VERSION_REQUEST = 'GET_BUILD_HASH_VERSION_REQUEST';
const GET_BUILD_HASH_VERSION_START = 'GET_BUILD_HASH_VERSION_START';
const GET_BUILD_HASH_VERSION_SUCCESS = 'GET_BUILD_HASH_VERSION_SUCCESS';
const GET_BUILD_HASH_VERSION_FAIL = 'GET_BUILD_HASH_VERSION_FAIL';

const GET_ADDITIONAL_DOCS_REQUEST = 'checkoutReducer/GET_ADDITIONAL_DOCS_REQUEST';
const GET_ADDITIONAL_DOCS_SUCCESS = 'checkoutReducer/GET_ADDITIONAL_DOCS_SUCCESS';
const GET_ADDITIONAL_DOCS_FAIL = 'checkoutReducer/GET_ADDITIONAL_DOCS_FAIL';
const GET_ADDITIONAL_DOCS_START = 'checkoutReducer/GET_ADDITIONAL_DOCS_START';

const DOWNLOAD_ADDITIONAL_FILE_REQUEST = 'checkoutReducer/DOWNLOAD_ADDITIONAL_FILE_REQUEST';
const DOWNLOAD_ADDITIONAL_FILE_SUCCESS = 'checkoutReducer/DOWNLOAD_ADDITIONAL_FILE_SUCCESS';
const DOWNLOAD_ADDITIONAL_FILE_FAIL = 'checkoutReducer/DOWNLOAD_ADDITIONAL_FILE_FAIL';
const DOWNLOAD_ADDITIONAL_FILE_START = 'checkoutReducer/DOWNLOAD_ADDITIONAL_FILE_START';

const CHECK_ACCOUNT_NUMBERS_REQUEST = 'CHECK_ACCOUNT_NUMBERS_REQUEST';
const CHECK_ACCOUNT_NUMBERS_START = 'CHECK_ACCOUNT_NUMBERS_START';
const CHECK_ACCOUNT_NUMBERS_FAIL = 'CHECK_ACCOUNT_NUMBERS_FAIL';
const CHECK_ACCOUNT_NUMBERS_SUCCESS = 'CHECK_ACCOUNT_NUMBERS_SUCCESS';
const CLEAR_ACTIVE_IMPORT_TASK = 'CLEAR_ACTIVE_IMPORT_TASK';

const SET_ANNUL_CONTRACT = 'SET_ANNUL_CONTRACT';
const SET_CONTRACT_FORMED = 'SET_CONTRACT_FORMED';
const SET_EDIT_BASKET = 'SET_EDIT_BASKET';

const SET_INIT_DATA = 'checkoutReducer/SET_INIT_DATA';

const CHECK_AUTH_SESSION = factory.createAsync('CHECK_AUTH_SESSION');
const OPEN_WINDOW_BLOCK_UNITED_FRONT = factory.create('OPEN_WINDOW_BLOCK_UNITED_FRONT');
const SET_ANNUL_STATUS = factory.createAsync('SET_ANNUL_STATUS');
const GET_SIGNING_TYPE = factory.createAsync('GET_SIGNING_TYPE');

const SET_UNITED_FRONTEND_PARAMS = factory.create('SET_UNITED_FRONTEND_PARAMS');
const UPDATE_UNITED_FRONTEND_SESSION = factory.createAsync('UPDATE_UNITED_FRONTEND_SESSION');
const TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION = factory.create('TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION');

const FILL_INSURER_DATA_CHECKOUT = checkoutFactory.create('FILL_INSURER_DATA_CHECKOUT');
const SET_IS_TEST_BRANCH = factory.create('SET_IS_TEST_BRANCH');
const FILL_INSURED_DATA_ISZ = checkoutFactory.create('FILL_INSURED_DATA_ISZ');
const FILL_BENEFECIARES_DATA_ISZ = checkoutFactory.create('FILL_BENEFECIARES_DATA_ISZ');
const ACCEPT_AGREEMENT_RULES = factory.createAsync('ACCEPT_AGREEMENT_RULES');
const GET_ISZ_RESTIRCTIONS = checkoutFactory.createAsync('GET_ISZ_RESTIRCTIONS');
const CLEAR_FORM_PARTNER_CHANNEL = factory.create('CLEAR_FORM_PARTNER_CHANNEL');
const CHANGE_FORM_PARTNER_CHANNEL = factory.create('CHANGE_FORM_PARTNER_CHANNEL');
const GET_CURRENT_COURSE = checkoutFactory.createAsync('GET_CURRENT_COURSE');
const DOWNLOAD_PRESENTATION = factory.createAsync('DOWNLOAD_PRESENTATION');

const SEARCH_REGION = helperFactory.createAsync('SEARCH_REGION');
const SEARCH_REGION_RESET = helperFactory.create('SEARCH_REGION_RESET');

const PASSWORD_RECOVERY = factory.createAsync('PASSWORD_RECOVERY');
const LOGIN_RECOVERY = factory.createAsync('LOGIN_RECOVERY');

const SET_CTS_ID_ARR = 'SET_CTS_ID_ARR';

const CHANGE_RECOVERY_LOGIN_MODAL = 'CHANGE_RECOVERY_LOGIN_MODAL';

const SET_SIGNING_TYPE = 'SET_SIGNING_TYPE';
const SET_IS_SCAN_UPLOAD_BY_CLIENT = 'SET_IS_SCAN_UPLOAD_BY_CLIENT';
const CHECK_IS_ACTIVE_CONFIRM_BUTTON = factory.createAsync('CHECK_IS_ACTIVE_CONFIRM_BUTTON');
const SET_INIT_STATE_SIGNING_TYPE = 'SET_INIT_STATE_SIGNING_TYPE';
const SET_SMS_SENT = 'SET_SMS_SENT';
const SENT_SMS = factory.createAsync('SENT_SMS');

const CLIENT_TEST_CHECK_NEED_TESTING_REQUEST = 'CLIENT_TEST_CHECK_NEED_TESTING_REQUEST';
const CLIENT_TEST_CHECK_NEED_TESTING_START = 'CLIENT_TEST_CHECK_NEED_TESTING_START';
const CLIENT_TEST_CHECK_NEED_TESTING_SUCCESS = 'CLIENT_TEST_CHECK_NEED_TESTING_SUCCESS';
const CLIENT_TEST_CHECK_NEED_TESTING_FAIL = 'CLIENT_TEST_CHECK_NEED_TESTING_FAIL';

const CLIENT_TEST_CHECK_TEST_STATUS_REQUEST = 'CLIENT_TEST_CHECK_TEST_STATUS_REQUEST';
const CLIENT_TEST_CHECK_TEST_STATUS_START = 'CLIENT_TEST_CHECK_TEST_STATUS_START';
const CLIENT_TEST_CHECK_TEST_STATUS_SUCCESS = 'CLIENT_TEST_CHECK_TEST_STATUS_SUCCESS';
const CLIENT_TEST_CHECK_TEST_STATUS_FAIL = 'CLIENT_TEST_CHECK_TEST_STATUS_FAIL';

const CLIENT_TEST_CREATE_RECORD_REQUEST = 'CLIENT_TEST_CREATE_RECORD_REQUEST';
const CLIENT_TEST_CREATE_RECORD_START = 'CLIENT_TEST_CREATE_RECORD_START';
const CLIENT_TEST_CREATE_RECORD_SUCCESS = 'CLIENT_TEST_CREATE_RECORD_SUCCESS';
const CLIENT_TEST_CREATE_RECORD_FAIL = 'CLIENT_TEST_CREATE_RECORD_FAIL';

const CLIENT_TEST_UPDATE_CREATE_RECORD_REQUEST = 'CLIENT_TEST_UPDATE_CREATE_RECORD_REQUEST';
const CLIENT_TEST_UPDATE_CREATE_RECORD_START = 'CLIENT_TEST_UPDATE_CREATE_RECORD_START';
const CLIENT_TEST_UPDATE_CREATE_RECORD_SUCCESS = 'CLIENT_TEST_UPDATE_CREATE_RECORD_SUCCESS';
const CLIENT_TEST_UPDATE_CREATE_RECORD_FAIL = 'CLIENT_TEST_UPDATE_CREATE_RECORD_FAIL';

const CLIENT_TEST_GET_QUESTIONS_REQUEST = 'CLIENT_TEST_GET_QUESTIONS_REQUEST';
const CLIENT_TEST_GET_QUESTIONS_START = 'CLIENT_TEST_GET_QUESTIONS_START';
const CLIENT_TEST_GET_QUESTIONS_SUCCESS = 'CLIENT_TEST_GET_QUESTIONS_SUCCESS';
const CLIENT_TEST_GET_QUESTIONS_FAIL = 'CLIENT_TEST_GET_QUESTIONS_FAIL';

const CLIENT_TEST_UPDATE_TEST_STATUS_REQUEST = 'CLIENT_TEST_UPDATE_TEST_STATUS_REQUEST';
const CLIENT_TEST_UPDATE_TEST_STATUS_START = 'CLIENT_TEST_UPDATE_TEST_STATUS_START';
const CLIENT_TEST_UPDATE_TEST_STATUS_SUCCESS = 'CLIENT_TEST_UPDATE_TEST_STATUS_SUCCESS';
const CLIENT_TEST_UPDATE_TEST_STATUS_FAIL = 'CLIENT_TEST_UPDATE_TEST_STATUS_FAIL';

const CLIENT_TEST_SET_PARAMS = 'CLIENT_TEST_SET_PARAMS';

const CLIENT_TEST_GET_RECORD_FIELDS_REQUEST = 'CLIENT_TEST_GET_RECORD_FIELDS_REQUEST';
const CLIENT_TEST_GET_RECORD_FIELDS_START = 'CLIENT_TEST_GET_RECORD_FIELDS_START';
const CLIENT_TEST_GET_RECORD_FIELDS_SUCCESS = 'CLIENT_TEST_GET_RECORD_FIELDS_SUCCESS';
const CLIENT_TEST_GET_RECORD_FIELDS_FAIL = 'CLIENT_TEST_GET_RECORD_FIELDS_FAIL';

const CLIENT_TEST_CHECK_TEST_LINK_REQUEST = 'CLIENT_TEST_CHECK_TEST_LINK_REQUEST';
const CLIENT_TEST_CHECK_TEST_LINK_START = 'CLIENT_TEST_CHECK_TEST_LINK_START';
const CLIENT_TEST_CHECK_TEST_LINK_SUCCESS = 'CLIENT_TEST_CHECK_TEST_LINK_SUCCESS';
const CLIENT_TEST_CHECK_TEST_LINK_FAIL = 'CLIENT_TEST_CHECK_TEST_LINK_FAIL';

const CLIENT_TEST_CHECK_USER_NOTBCS_REQUEST = 'CLIENT_TEST_CHECK_USER_NOTBCS_REQUEST';
const CLIENT_TEST_CHECK_USER_NOTBCS_START = 'CLIENT_TEST_CHECK_USER_NOTBCS_START';
const CLIENT_TEST_CHECK_USER_NOTBCS_SUCCESS = 'CLIENT_TEST_CHECK_USER_NOTBCS_SUCCESS';
const CLIENT_TEST_CHECK_USER_NOTBCS_FAIL = 'CLIENT_TEST_CHECK_USER_NOTBCS_FAIL';

const CLIENT_TEST_SET_RECORD_FIELDS = 'CLIENT_TEST_SET_RECORD_FIELDS';

const SET_INSURER_IS_RESIDENT_RF = 'SET_INSURER_IS_RESIDENT_RF';
const SET_INSURED_IS_RESIDENT_RF = 'SET_INSURED_IS_RESIDENT_RF';

export {
  TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION,
  UPDATE_UNITED_FRONTEND_SESSION,
  SET_UNITED_FRONTEND_PARAMS,
  SET_ANNUL_STATUS,
  OPEN_WINDOW_BLOCK_UNITED_FRONT,
  CHECK_AUTH_SESSION,
  SET_INIT_DATA,
  CLEAR_ACTIVE_IMPORT_TASK,
  CHECK_ACCOUNT_NUMBERS_REQUEST,
  CHECK_ACCOUNT_NUMBERS_START,
  CHECK_ACCOUNT_NUMBERS_FAIL,
  CHECK_ACCOUNT_NUMBERS_SUCCESS,
  GET_ADDITIONAL_DOCS_REQUEST,
  GET_ADDITIONAL_DOCS_SUCCESS,
  GET_ADDITIONAL_DOCS_FAIL,
  GET_ADDITIONAL_DOCS_START,
  DOWNLOAD_ADDITIONAL_FILE_REQUEST,
  DOWNLOAD_ADDITIONAL_FILE_SUCCESS,
  DOWNLOAD_ADDITIONAL_FILE_FAIL,
  DOWNLOAD_ADDITIONAL_FILE_START,
  CHANGE_AUTO_IMPORT_SETTINGS,
  CLOSE_IMPORT_FILE_FROM1C_POPUP,
  CANCEL_CURRENT_JOB_FAIL,
  CANCEL_CURRENT_JOB_SUCCESS,
  CANCEL_CURRENT_JOB_START,
  CANCEL_CURRENT_JOB_REQUEST,
  GET_AUTO_IMPORT_JOBS_FAIL,
  GET_AUTO_IMPORT_JOBS_SUCCESS,
  GET_AUTO_IMPORT_JOBS_START,
  GET_AUTO_IMPORT_JOBS_REQUEST,
  START_AUTO_IMPORT_FAIL,
  START_AUTO_IMPORT_SUCCESS,
  START_AUTO_IMPORT_START,
  START_AUTO_IMPORT_REQUEST,
  PARSE_XML_FILE_FOR_SELECTS_FAIL,
  PARSE_XML_FILE_FOR_SELECTS_SUCCESS,
  PARSE_XML_FILE_FOR_SELECTS_START,
  PARSE_XML_FILE_FOR_SELECTS_REQUEST,
  GET_BUILD_HASH_VERSION_SUCCESS,
  GET_BUILD_HASH_VERSION_FAIL,
  GET_BUILD_HASH_VERSION_START,
  GET_BUILD_HASH_VERSION_REQUEST,
  GET_CONTRACT_COPY_REQUEST,
  GET_CONTRACT_COPY_START,
  GET_CONTRACT_COPY_FAIL,
  GET_CONTRACT_COPY_SUCCESS,
  SET_FUND_DESCRIPTION,
  SELECT_PARTNER_FOR_AUTH,
  SELECT_BRANCH_FOR_AUTH,
  TOGGLE_MODAL_AFFILIATION,
  SET_AFFILIATION_FAIL,
  SET_AFFILIATION_REQUEST,
  SET_AFFILIATION_START,
  SET_AFFILIATION_SUCCESS,
  GET_PARTNERS_FOR_AUTH_FAIL,
  GET_PARTNERS_FOR_AUTH_REQUEST,
  GET_PARTNERS_FOR_AUTH_START,
  GET_PARTNERS_FOR_AUTH_SUCCESS,
  GET_BRANCHES_FOR_AUTH_REQUEST,
  GET_BRANCHES_FOR_AUTH_FAIL,
  GET_BRANCHES_FOR_AUTH_START,
  GET_BRANCHES_FOR_AUTH_SUCCESS,
  RECAPTCHA,
  LOG_OUT_FAIL,
  LOG_OUT_START,
  LOG_OUT_SUCCESS,
  GET_CAPTCHA_FAIL,
  GET_CAPTCHA_REQUEST,
  GET_CAPTCHA_START,
  GET_CAPTCHA_SUCCESS,
  RESET_TEMPORARY_PASSWORD_FAIL,
  RESET_TEMPORARY_PASSWORD_START,
  RESET_TEMPORARY_PASSWORD_SUCCESS,
  RESET_TEMPORARY_PASSWORD_REQUEST,
  GET_IP_ADDRESS_FAIL,
  GET_IP_ADDRESS_REQUEST,
  GET_IP_ADDRESS_SUCCESS,
  CLEAR_VALIDATE_TABS,
  AUTH_REQUEST,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  RESET_REQUEST,
  RESET_REQUEST_START,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  CHECK_PASS_REQUEST,
  CHECK_PASS_REQUEST_SUCCESS,
  CHECK_PASS_REQUEST_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  GET_CURRENT_USER_START,
  CHECK_BY_EMAIL_START,
  CHECK_BY_EMAIL_SUCCESS,
  CHECK_BY_EMAIL_FAIL,
  CHECK_BY_EMAIL_REQUEST,
  SEND_RECOVERY_CODE_START,
  SEND_RECOVERY_CODE_SUCCESS,
  SEND_RECOVERY_CODE_FAIL,
  SEND_RECOVERY_CODE_REQUEST,
  PASSWORD_RESET_VERIFY_CODE_START,
  PASSWORD_RESET_VERIFY_CODE_SUCCESS,
  PASSWORD_RESET_VERIFY_CODE_FAIL,
  PASSWORD_RESET_VERIFY_CODE_REQUEST,
  RESET_CHANGE_PASSWORD_START,
  RESET_CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_FAIL,
  RESET_CHANGE_PASSWORD_REQUEST,
  TOGGLE_MENU,
  LOG_OUT,
  GET_ERROR,
  SEARCH_ADDRESS_REQUEST,
  SEARCH_ADDRESS_START,
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_FAIL,
  SEARCH_ADDRESS_VALUE,
  SEARCH_ADDRESS_RESET,
  SEARCH_FMS_UNIT_REQUEST,
  SEARCH_FMS_UNIT_START,
  SEARCH_FMS_UNIT_SUCCESS,
  SEARCH_FMS_UNIT_FAIL,
  SEARCH_FMS_UNIT_VALUE,
  SEARCH_FMS_UNIT_RESET,
  SET_PRODUCT_VALUES,
  SET_INSURER_VALUES,
  SET_INSURED_VALUES,
  SET_BENEFICIARY_VALUES,
  CHANGE_BENEFICIARY_COUNT,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  CHECK_PRODUCT_REQUEST,
  CHECK_PRODUCT_START,
  CHECK_PRODUCT_SUCCESS,
  CHECK_PRODUCT_FAIL,
  GET_PRODUCT_FORM_REQUEST,
  GET_PRODUCT_FORM_START,
  GET_PRODUCT_FORM_SUCCESS,
  GET_PRODUCT_FORM_FAIL,
  CHECK_SUM_START,
  CHECK_SUM_REQUEST,
  CHECK_SUM_SUCCESS,
  CHECK_SUM_FAIL,
  CHECK_PACKAGE_SERVICE_REQUEST,
  CHECK_PACKAGE_SERVICE_START,
  CHECK_PACKAGE_SERVICE_SUCCESS,
  CHECK_PACKAGE_SERVICE_FAIL,
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAIL,
  GET_MULTIPLE_OPTIONS,
  SAVE_FORMS_REQUEST,
  SAVE_FORMS_FORM_START,
  SAVE_FORMS_SUCCESS,
  SAVE_FORMS_FAIL,
  RESET_FORMS,
  RESET_ERRORS,
  GET_DATA_CONTRACT_REQUEST,
  GET_DATA_CONTRACT_START,
  GET_DATA_CONTRACT_SUCCESS,
  GET_DATA_CONTRACT_FAIL,
  UPLOAD_SCAN_REQUEST,
  UPLOAD_SCAN_START,
  UPLOAD_SCAN_SUCCESS,
  UPLOAD_SCAN_FAIL,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  DELETE_FILE,
  SET_FILE_TYPE,
  SAVE_SCAN_REQUEST,
  SAVE_SCAN_SUCCESS,
  SAVE_SCAN_FAIL,
  GET_CONTRACT_FILE_REQUEST,
  GET_CONTRACT_FILE_SUCCESS,
  GET_CONTRACT_FILE_FAIL,
  GET_REGENERATE_CONTRACT_FILE_REQUEST,
  GET_REGENERATE_CONTRACT_FILE_SUCCESS,
  GET_REGENERATE_CONTRACT_FILE_FAIL,
  START_VALIDATE_TABS,
  SET_PRODUCT_SCENARIO,
  SET_INSURED_IS_SAME,
  SET_STORE_TOKEN,
  FILL_INSURER_DATA_CHECKOUT,
  SET_IS_TEST_BRANCH,
  FILL_INSURED_DATA_ISZ,
  FILL_BENEFECIARES_DATA_ISZ,
  ACCEPT_AGREEMENT_RULES,
  GET_ISZ_RESTIRCTIONS,
  CHANGE_FORM_PARTNER_CHANNEL,
  CLEAR_FORM_PARTNER_CHANNEL,
  GET_CURRENT_COURSE,
  SET_ANNUL_CONTRACT,
  SET_CONTRACT_FORMED,
  RESET_CTS_IDS,
  SET_EDIT_BASKET,
  SET_CTS_ID_ARR,
  DOWNLOAD_PRESENTATION,
  SEARCH_REGION,
  SEARCH_REGION_RESET,
  PASSWORD_RECOVERY,
  CHANGE_RECOVERY_LOGIN_MODAL,
  LOGIN_RECOVERY,
  GET_SIGNING_TYPE,
  SET_SIGNING_TYPE,
  SET_IS_SCAN_UPLOAD_BY_CLIENT,
  CHECK_IS_ACTIVE_CONFIRM_BUTTON,
  SET_INIT_STATE_SIGNING_TYPE,
  SET_SMS_SENT,
  SENT_SMS,
  CLIENT_TEST_CHECK_NEED_TESTING_FAIL,
  CLIENT_TEST_CHECK_NEED_TESTING_START,
  CLIENT_TEST_CHECK_NEED_TESTING_SUCCESS,
  CLIENT_TEST_CHECK_NEED_TESTING_REQUEST,
  CLIENT_TEST_CHECK_TEST_STATUS_FAIL,
  CLIENT_TEST_CHECK_TEST_STATUS_START,
  CLIENT_TEST_CHECK_TEST_STATUS_SUCCESS,
  CLIENT_TEST_CHECK_TEST_STATUS_REQUEST,
  CLIENT_TEST_CREATE_RECORD_FAIL,
  CLIENT_TEST_CREATE_RECORD_START,
  CLIENT_TEST_CREATE_RECORD_SUCCESS,
  CLIENT_TEST_CREATE_RECORD_REQUEST,
  CLIENT_TEST_UPDATE_CREATE_RECORD_FAIL,
  CLIENT_TEST_UPDATE_CREATE_RECORD_START,
  CLIENT_TEST_UPDATE_CREATE_RECORD_SUCCESS,
  CLIENT_TEST_UPDATE_CREATE_RECORD_REQUEST,
  CLIENT_TEST_GET_QUESTIONS_FAIL,
  CLIENT_TEST_GET_QUESTIONS_START,
  CLIENT_TEST_GET_QUESTIONS_SUCCESS,
  CLIENT_TEST_GET_QUESTIONS_REQUEST,
  CLIENT_TEST_SET_PARAMS,
  CLIENT_TEST_UPDATE_TEST_STATUS_FAIL,
  CLIENT_TEST_UPDATE_TEST_STATUS_START,
  CLIENT_TEST_UPDATE_TEST_STATUS_SUCCESS,
  CLIENT_TEST_UPDATE_TEST_STATUS_REQUEST,
  CLIENT_TEST_GET_RECORD_FIELDS_FAIL,
  CLIENT_TEST_GET_RECORD_FIELDS_START,
  CLIENT_TEST_GET_RECORD_FIELDS_SUCCESS,
  CLIENT_TEST_GET_RECORD_FIELDS_REQUEST,
  CLIENT_TEST_CHECK_TEST_LINK_FAIL,
  CLIENT_TEST_CHECK_TEST_LINK_START,
  CLIENT_TEST_CHECK_TEST_LINK_SUCCESS,
  CLIENT_TEST_CHECK_TEST_LINK_REQUEST,
  CLIENT_TEST_CHECK_USER_NOTBCS_FAIL,
  CLIENT_TEST_CHECK_USER_NOTBCS_START,
  CLIENT_TEST_CHECK_USER_NOTBCS_SUCCESS,
  CLIENT_TEST_CHECK_USER_NOTBCS_REQUEST,
  CLIENT_TEST_SET_RECORD_FIELDS,
  SET_INSURER_IS_RESIDENT_RF,
  SET_INSURED_IS_RESIDENT_RF,
};
