import React, {
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { Icon, Button } from 'semantic-ui-react';
import { BASKET_CONST, LANG_DICTIONARY, REGEXP } from 'consts';
import {
  BarrierFields,
  CustomMultiSelect,
  Select,
  Input, CustomCheckBox,
} from 'components';
import { block } from 'bem-cn';
import { useReduxForm } from 'hooks/useReduxForm';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBarrierOption,
  selectCurrencies,
  selectCurrencyCode,
  selectedMainOptionType,
  selectFormByUuid,
  getStrategies,
  selectOptionType,
  selectProductOptionOptions,
  selectProductPayouts,
  selectProductTerms,
  selectTermIdFirstForm,
  selectMaxGuaranteeLevel,
  selectGuaranteedPayouts,
  selectSettingsProduct,
} from 'redux/basketConstructor/selectors';
import { useActions } from 'hooks/useActions';
import * as basketActions from 'redux/basketConstructor/actions';
import { removeLeadingZeros } from 'helpers';
import FieldRow from '../FieldRow';
import MainLabel from '../MainLabel';
import StrategyTitle from '../StrategyTitle';
import Currencies from '../Currencies';
import GuaranteedPayout from '../GuaranteedPayout';
import { STRATEGY_FIELD_NAME } from './fieldNames';
import { schema } from './schema';
import {
  getCountBarrier,
  getColor,
  declOfDay,
  getBarrierDescriptionMessage,
} from './helpers';
import './styles.scss';

const b = block('strategy-basket-creating');

const defaultProps = {
  calculatedSelected: {},
  regularSelected: {},
  readySelected: {},
  setIsDisabledCalculate: () => null,
};

const propTypes = {
  isMain: PropTypes.bool.isRequired,
  isCopyStrategy: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  calculatedSelected: PropTypes.object,
  regularSelected: PropTypes.object,
  readySelected: PropTypes.object,
  setIsDisabledCalculate: PropTypes.func,
  successFeeAvailable: PropTypes.bool.isRequired,
};

const {
  SELECT_STRATEGY_TYPE,
  SELECT_STRATEGY_TERM,
  BASKET_TERM_HELP_MESSAGE,
  CHOOSE_ASSETS,
  MAX_ASSETS,
  BASKET_ASSETS_HELP_MESSAGE,
  CHOOSE_CURRENCY_CONTRACT,
  STRATEGY_CURRENCY,
  CHOOSE_PAYOUT_TIMES_CONTRACT,
  BASKET_PAYOUT_COUPON_HELP_MESSAGE,
  BASKET_LEVEL_BARRIERS_HELP_MESSAGE,
  CHOOSE_BARRIERS_LEVELS,
  PARTICIPATION_COEFFICIENT,
  COUPON_PERCENT_YEAR,
  BASKET_HELP_MESSAGE_COEFFICIENT_OR_COUPON,
  BARRIERS_MINMAX,
  WARNING_MESSAGE_DIFFERENT_COLORS_ASSETS,
  SPECIFY_AUTOCALL_BARRIER,
  AUTOCALL_DESCRIPTION,
  IF_9999_OPTION_DISABLED,
  SPECIFY_BONUS_COUPON,
  BONUS_COUPON_DESCRIPTION,
  AUTOCALL_FIELD_NOT_AFFECT_CALCULATION_BONUS_COUPON,
  AUTOCALL_IS_ENABLED,
  AUTOCALL_IS_NOT_ENABLED,
  UNEQUAL_ASSET_CLASS,
  ASSETS_HAVE_DIFFERENT_CLASSES,
  GUARANTEE_LEVEL_WARNING,
  ADD_GUARANTEED_PAYOUT,
  NOTIONAL_BARRIER_DESCRIPTION,
  BARRIER_NOMINAL,
  BARRIER_GEARED_PUT,
  GEARED_BARRIER_DESTIPTION,
  LOOKBACK_OPTION_ENABLED,
  LOOKBACK_OPTION_DESCRIPTION,
  BARRIER_LEVEL_RESTRICTION_ERROR,
  GROWTH_CAP,
  SUCCESS_FEE_ADD,
  SUCCESS_FEE_DESCRIPTION,
} = LANG_DICTIONARY;

const unavailableAssetColor = 'grey';

export const Strategy = ({
  isMain,
  isCopyStrategy,
  uuid,
  index,
  setIsDisabledCalculate,
}) => {
  const dispatch = useDispatch();
  const productOptionTypes = useSelector(selectOptionType);
  const productOptionOptions = useSelector(selectProductOptionOptions);
  const productPayoutOptions = useSelector(selectProductPayouts);
  const productTermOptions = useSelector(selectProductTerms);
  const currencyCode = useSelector(selectCurrencyCode);
  const currencies = useSelector(selectCurrencies);
  const { id: productId } = useParams();
  const barrierAutocall = useSelector(selectBarrierOption);
  const mainOptionType = useSelector(selectedMainOptionType);
  const strategies = useSelector(getStrategies);
  const childStrategy = useSelector(() => selectFormByUuid(
    strategies, uuid,
  ));
  const maxGuaranteeLevel = useSelector(selectMaxGuaranteeLevel);
  const guaranteedPayouts = useSelector(selectGuaranteedPayouts);
  const settings = useSelector(selectSettingsProduct);

  const {
    copyStrategy,
    deleteStrategy,
    addNewStrategy,
    changeCurrencyFormCreating,
    getTerms,
    getAssetOptions,
    getOptionTypesRestrictions,
    setAssetsDescription,
    checkMultipleAssets,
    getMaxGuaranteeLevel,
    addNewGuaranteedPayout,
    changeFieldsForName,
    deleteGuaranteedPayout,
    getPayoutFrequency,
    changeLookbackFlag,
    changeSuccessFeeFlag,
  } = useActions(basketActions);

  const {
    handleChange,
    values,
    visibleErrors,
    handleFocus,
    handleBlur,
  } = useReduxForm({
    isOverwriteOnStateInit: false,
    reducerPath: `basketConstructor.formCreating.strategies.${uuid}`,
    schema: (schemaPayload) => schema({
      ...schemaPayload,
      isMain,
      productOptionOptions,
      productOptionTypes,
      currentOptionType,
      successFeeFlag: values[STRATEGY_FIELD_NAME.SUCCESS_FEE_FLAG],
    }),
    initState: {
      [STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]: null,
      [STRATEGY_FIELD_NAME.TERM_OPTIONS]: [],
      [STRATEGY_FIELD_NAME.CURRENCY_RADIO_BUTTON]: null,
      [STRATEGY_FIELD_NAME.TERM]: null,
      [STRATEGY_FIELD_NAME.ASSET_OPTIONS]: [],
      [STRATEGY_FIELD_NAME.ASSET_CODES]: [],
      [STRATEGY_FIELD_NAME.PAYOUT_CODE]: null,
      [STRATEGY_FIELD_NAME.BARRIERS]: [],
      [STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR]: {},
      [STRATEGY_FIELD_NAME.COUPON]: '',
      [STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT]: '',
      [STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]: '',
      [STRATEGY_FIELD_NAME.BONUS_COUPON]: 0,
      [STRATEGY_FIELD_NAME.ASSET_CLASS]: 0,
      [STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]: [],
      [STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]: '0',
      [STRATEGY_FIELD_NAME.GEARED_BARRIER]: '0',
      [STRATEGY_FIELD_NAME.GROWTH_CAP]: '0',
      [STRATEGY_FIELD_NAME.SUCCESS_FEE]: null,
      [STRATEGY_FIELD_NAME.SUCCESS_FEE_FLAG]: false,
    },
  });

  useEffect(() => {
    getMaxGuaranteeLevel({ currencyCode, productId });
  }, []);

  useEffect(() => {
    if (values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS] === undefined) {
      const { refInsDateUnitValueNumber } = productTermOptions.find(
        (termOption) => termOption.value === values[STRATEGY_FIELD_NAME.TERM],
      );
      getOptionTypesRestrictions(
        values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
        values[STRATEGY_FIELD_NAME.TERM],
        values[STRATEGY_FIELD_NAME.PAYOUT_CODE],
        uuid,
        refInsDateUnitValueNumber,
      );
    }

    if (values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]?.length) {
      const optionTypesRestrictionsAutocall = values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]
        .filter((option) => option.parameter === BASKET_CONST.AUTOCALL_BARRIER);

      if (optionTypesRestrictionsAutocall.length > 0) {
        const maxOptionTypesRestrictionAutocall = _.maxBy(optionTypesRestrictionsAutocall, 'maxValue');

        handleChange({
          target: {
            name: STRATEGY_FIELD_NAME.BARRIER_AUTOCALL,
            value: getInputProps(STRATEGY_FIELD_NAME.BARRIER_AUTOCALL).value
                || String(maxOptionTypesRestrictionAutocall.maxValue || 0),
          },
        });
      }
    }

    handleChange({
      target: {
        name: [STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS],
        value: values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS] || [],
      },
    });
  }, [values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]]);

  useEffect(() => {
    if (!values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]) {
      const defaultOption = productOptionTypes.find(({ isDefault }) => isDefault);
      const value = defaultOption ? defaultOption.name : null;
      handleChange({
        target: {
          name: STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE,
          value,
        },
      }, {
        isSideEffect: true,
        isTouchedOnChange: false,
      });
    }
  }, [productOptionTypes]);

  useEffect(() => {
    if (currencyCode) {
      getTerms({
        productId,
        currencyCode,
      });
    }
  }, [currencyCode]);

  useEffect(() => {
    if (values[STRATEGY_FIELD_NAME.TERM]) {
      getAssetOptions(values[STRATEGY_FIELD_NAME.TERM], currencyCode, uuid);
    }
  }, [values[STRATEGY_FIELD_NAME.TERM]]);

  useEffect(() => {
    if (
      !values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]
        || !values[STRATEGY_FIELD_NAME.PAYOUT_CODE]
        || !values[STRATEGY_FIELD_NAME.TERM]) return;

    const { refInsDateUnitValueNumber } = productTermOptions.find(
      (termOption) => termOption.value === values[STRATEGY_FIELD_NAME.TERM],
    );
    getOptionTypesRestrictions(
      values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
      values[STRATEGY_FIELD_NAME.TERM],
      values[STRATEGY_FIELD_NAME.PAYOUT_CODE],
      uuid,
      refInsDateUnitValueNumber,
    );
    if (!values[STRATEGY_FIELD_NAME.BARRIERS].length) {
      handleChange({ target: { name: STRATEGY_FIELD_NAME.BARRIERS, value: new Array(countBarriers).fill('') } });
    }
  }, [values[STRATEGY_FIELD_NAME.PAYOUT_CODE], values[STRATEGY_FIELD_NAME.TERM]]);

  useEffect(() => {
    if (!values[STRATEGY_FIELD_NAME.TERM]
        || !productTermOptions
        || productTermOptions.length === 0) {
      return;
    }
    const { key: termId } = productTermOptions.find(
      (termOption) => termOption.value === values[STRATEGY_FIELD_NAME.TERM],
    );
    getPayoutFrequency(productId, termId);
  }, [values[STRATEGY_FIELD_NAME.TERM]]);

  const selectedTermOnFirstForm = useSelector(selectTermIdFirstForm);
  const isDisabledTerm = !isMain && !selectedTermOnFirstForm;

  const availableProductTermOptions = useMemo(() => {
    const termValue = values[STRATEGY_FIELD_NAME.TERM];

    if (isMain) {
      return productTermOptions;
    }

    if (!selectedTermOnFirstForm) {
      return [];
    }

    if (termValue > selectedTermOnFirstForm.value) {
      handleChange({
        target: {
          name: STRATEGY_FIELD_NAME.TERM,
          value: null,
        },
      }, {
        isTouchedOnChange: false,
      });
    }

    return Array
      .from({ length: selectedTermOnFirstForm.value }, (item, i) => i + 1)
      .map((item) => ({
        key: item,
        value: item,
        text: item,
      }));
  },
  [
    productTermOptions,
    selectedTermOnFirstForm,
    values[STRATEGY_FIELD_NAME.TERM],
  ]);

  const assetIsNotEmpty = Boolean(values[STRATEGY_FIELD_NAME.ASSET_CLASS]);

  const isDisabledCalculateByClass = strategies[uuid]?.values?.isMultipleAssetClass;

  const activesOptions = useMemo(
    () => (values[STRATEGY_FIELD_NAME.ASSET_OPTIONS]
      .map(({
        assetApprove,
        code,
        description,
        name,
        tvCode,
        assetClass,
      }) => {
        const checkIsEqualAssetClass = (assetIsNotEmpty
                    && assetClass !== values[STRATEGY_FIELD_NAME.ASSET_CLASS])
                || isDisabledCalculateByClass;

        const optionText = () => (
          <div className={b('option')}>
            <div>{name}</div>
            <div className={b('option-text')}>
              (
              {code}
              )
            </div>
            <div className={b('option-text-error')}>{UNEQUAL_ASSET_CLASS}</div>
          </div>
        );

        const assetTitle = `${name} (${code})`;

        return {
          value: code,
          text: checkIsEqualAssetClass ? optionText() : assetTitle,
          key: `${code}/${uuid}`,
          desc: description,
          disabled: checkIsEqualAssetClass,
          color: checkIsEqualAssetClass ? unavailableAssetColor : assetApprove,
          name,
          tvcode: tvCode,
          assetClass,
          title: assetTitle,
          colorLabel: assetApprove,
        };
      })),
    [values[STRATEGY_FIELD_NAME.ASSET_OPTIONS],
      values[STRATEGY_FIELD_NAME.ASSET_CLASS],
      values[STRATEGY_FIELD_NAME.IS_MULTIPLE_ASSET_CLASS]],
  );

  const activesOptionsMap = useMemo(() => new Map(activesOptions.map((item) => [item.value, item])),
    [activesOptions]);

  const handleCopyStrategy = useCallback(() => {
    const newUuid = uuidV4();
    addNewStrategy(newUuid);
    copyStrategy(uuid, newUuid);
  }, []);

  const handleDeleteStrategy = useCallback(() => {
    deleteStrategy(uuid);
  }, []);

  const getAssetClass = (value) => activesOptions.find(
    (active) => active.value === value,
  )?.assetClass;

  const setAssetClass = (value) => {
    if (value.length <= 1) {
      handleChange(
        { target: { name: STRATEGY_FIELD_NAME.ASSET_CLASS, value: getAssetClass(value[0]) } },
      );
    }
  };

  const handleChangeSemanticItem = useCallback((e, { value, name }) => {
    setAssetClass(value);
    handleChange({ target: { value, name } });
  }, [activesOptionsMap]);

  const handleChangeCurrency = useCallback((e, { value }) => {
    changeCurrencyFormCreating({ currencyCode: value, productId });
    getMaxGuaranteeLevel({ currencyCode: value, productId });
  }, []);

  const handleChangeBarriers = useCallback((barriers) => {
    handleChange({ target: { name: STRATEGY_FIELD_NAME.BARRIERS, value: barriers } });
  }, []);

  const handleChangeErrorBarrier = useCallback(({ index: indexError, status }) => {
    handleChange({
      target: {
        name: STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR,
        value: {
          ...values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR],
          [indexError]: status,
        },
      },
    });
  }, [values]);

  const handleClickOnAsset = useCallback(
    (e, { selectedOption }) => {
      setAssetsDescription([{
        name: selectedOption.name,
        code: selectedOption.value,
        tvCode: selectedOption.tvcode,
        description: selectedOption.desc,
        assetWeight: null,
      }]);
    }, [],
  );

  const renderLabel = (label) => ({
    color: getColor(label.colorLabel),
    content: label.title,
  });

  const currencyFieldDescription = isMain ? CHOOSE_CURRENCY_CONTRACT : STRATEGY_CURRENCY;
  const optionDescription = useMemo(() => {
    const option = productOptionOptions.find(
      (item) => item.value === values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
    );

    return option?.descriptionFull ? option?.descriptionFull : option?.text;
  }, [
    productOptionOptions,
    values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
  ]);

  const productOptionTypeCode = values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE];
  const currentProductOption = productOptionOptions?.find(
    (productOption) => productOption.value === values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE],
  );
  const currentOptionType = settings?.optionTypes?.find(
    (optionType) => optionType.refIszOptionTypeId === currentProductOption?.id,
  );

  const handleLookbackFlag = useCallback((lookbackData) => {
    dispatch(changeLookbackFlag({
      uuid,
      lookbackFlag: !values.lookbackFlag,
      lookbackData: !values.lookbackFlag ? lookbackData : [],
    }));
  }, [values.lookbackFlag]);

  const couponFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isCoupon;

    return acc;
  }, {});

  const isCoupon = useMemo(() => (
    couponFlagsByProductOption[productOptionTypeCode]
  ), [couponFlagsByProductOption[productOptionTypeCode]]);

  const isKu = useMemo(() => (
    !couponFlagsByProductOption[productOptionTypeCode]
  ), [!couponFlagsByProductOption[productOptionTypeCode]]);

  const autocallFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isAutocall;

    return acc;
  }, {});

  const isAutocall = useMemo(() => (
    autocallFlagsByProductOption[productOptionTypeCode]
  ), [autocallFlagsByProductOption[productOptionTypeCode]]);

  const monoBarrierFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isMonoBarrier;

    return acc;
  }, {});

  const isMonoBarrier = useMemo(() => (
    monoBarrierFlagsByProductOption[productOptionTypeCode]
  ), [monoBarrierFlagsByProductOption[productOptionTypeCode]]);

  const capitalProtectedFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isCapitalProtected;

    return acc;
  }, {});

  const isCapitalProtected = useMemo(() => (
    capitalProtectedFlagsByProductOption[productOptionTypeCode]
  ), [capitalProtectedFlagsByProductOption[productOptionTypeCode]]);

  const countBarriers = useMemo(() => getCountBarrier({
    term: values[STRATEGY_FIELD_NAME.TERM],
    payoutCode: values[STRATEGY_FIELD_NAME.PAYOUT_CODE],
    isMonoBarrier,
  }), [
    values[STRATEGY_FIELD_NAME.TERM],
    values[STRATEGY_FIELD_NAME.PAYOUT_CODE],
  ]);

  const barrierLevelRestriction = values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]
    ?.find((restriction) => restriction.parameter === BASKET_CONST.COUPON_BARRIER);
  const minBarrierLevelValue = barrierLevelRestriction?.minValue;
  const maxBarrierLevelValue = barrierLevelRestriction?.maxValue;

  const barrierDescription = barrierLevelRestriction ? getBarrierDescriptionMessage(
    values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS],
  ) : '';

  const hasRestrictions = Boolean(values[STRATEGY_FIELD_NAME.OPTION_TYPES_RESTRICTIONS]?.length);

  const barrierError = useMemo(() => {
    if (!values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR]) {
      return '';
    }

    if (!barrierLevelRestriction) {
      return BARRIER_LEVEL_RESTRICTION_ERROR;
    }

    const isExistError = !_.isEmpty(values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR]);
    const hasWrongBarrierValue = Object
      .values(values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR])
      .some(Boolean);

    const isBarrierError = isExistError && hasWrongBarrierValue;

    return isBarrierError ? BARRIERS_MINMAX(barrierLevelRestriction.minValue, barrierLevelRestriction.maxValue) : '';
  }, [values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR], barrierLevelRestriction]);

  const getInputProps = (inputName) => ({
    name: inputName,
    error: visibleErrors[inputName],
    value: values[inputName],
    onKeyDown: (event) => {
      if (inputName === STRATEGY_FIELD_NAME.SUCCESS_FEE) {
        const regex = /^\d*[.,]?\d{0,1}$/;
        if (event.key >= '0' && event.key <= '9') {
          if (!regex.test(event.target.value)) {
            event.preventDefault();
          }
        }
      }
    },
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: (event) => {
      if (inputName === STRATEGY_FIELD_NAME.SUCCESS_FEE) {
        const onlyNumbersValue = removeLeadingZeros(event.target.value.replace(REGEXP.ALL_BUT_NUMBERS_AND_POINT, ''));
        handleChange({
          target: {
            name: STRATEGY_FIELD_NAME.SUCCESS_FEE,
            value: onlyNumbersValue.replace(REGEXP.MORE_THAN_ONE_DECIMAL, '$1'),
          },
        });

        return;
      }
      handleChange(event);
    },
  });

  const selectedAssets = values[STRATEGY_FIELD_NAME.ASSET_CODES];

  let isDifferentAssetsColorsWarning = false;

  if (selectedAssets.length) {
    const firstSelectAsset = activesOptionsMap.get(selectedAssets[0]);

    if (firstSelectAsset) {
      const firstSelectAssetApprove = firstSelectAsset.approve;
      isDifferentAssetsColorsWarning = selectedAssets.some((item) => {
        const assetApprove = activesOptionsMap.get(item)?.approve;

        return assetApprove !== firstSelectAssetApprove;
      });
    }
  }

  const isMainAutocall = isMain && isAutocall;

  const isMainAuto = () => (
    productOptionOptions?.some((item) => item.value === mainOptionType && item.isAutocall)
  );

  const setChildsOptionTypes = () => {
    if (isMainAuto()) {
      return productOptionOptions.filter((item) => item.isAutocall);
    }

    return productOptionOptions.filter((item) => !item.isAutocall);
  };

  useEffect(() => {
    setChildsOptionTypes();
  }, []);

  useEffect(() => {
    setChildsOptionTypes();
  }, [values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]]);

  const productOptionTypeChild = !isMain && childStrategy?.values.productOptionTypeCode;

  const isChildAuto = () => (
    productOptionOptions?.some((item) => item.value === productOptionTypeChild && !item.isAutocall)
  );

  const isChildAutocall = !isMain && isAutocall && isMainAuto();

  const assetLimit = useMemo(() => productOptionOptions?.find(
    (item) => item.value === productOptionTypeCode,
  )?.assetLimit, [productOptionTypeCode]);

  useEffect(() => {
    setIsDisabledCalculate(
      assetLimit < getInputProps(STRATEGY_FIELD_NAME.ASSET_CODES).value.length,
    );
  }, [getInputProps(STRATEGY_FIELD_NAME.ASSET_CODES).value, assetLimit]);

  useEffect(() => {
    handleChange({
      target: {
        name: STRATEGY_FIELD_NAME.ASSET_CLASS,
        value: 0,
      },
    });
  }, [getInputProps(STRATEGY_FIELD_NAME.TERM).value]);

  const handleCheckMultipleAssets = (e, { value }) => {
    handleChangeSemanticItem(e, { value, name: STRATEGY_FIELD_NAME.ASSET_CODES });
    checkMultipleAssets(value, uuid);
  };

  const handleAddGuaranteePayout = () => {
    addNewGuaranteedPayout({});
  };

  /* eslint-disable-next-line */
  const isGrowthCapDisabled = !(values[STRATEGY_FIELD_NAME.TERM] && values[STRATEGY_FIELD_NAME.PAYOUT_CODE]);

  const handleSuccessFeeFlag = useCallback((successFee) => {
    dispatch(changeSuccessFeeFlag({
      uuid,
      successFeeFlag: !values.successFeeFlag,
      successFee: successFee || currentOptionType?.minValue || 0,
    }));
  }, [values.successFeeFlag]);

  return (
    <>
      <div className={b()}>
        <StrategyTitle
          index={index}
          isDeleteStrategy={!isMain}
          isCopyStrategy={isCopyStrategy}
          onDeleteStrategy={handleDeleteStrategy}
          onCopyStrategy={handleCopyStrategy}
        />
        {isMain && (<MainLabel />)}
        <FieldRow
          label={SELECT_STRATEGY_TYPE}
          helpMessage={optionDescription}
        >
          <Select
            options={isMain ? productOptionOptions : setChildsOptionTypes()}
            required
            {...getInputProps(STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE)}
          />
        </FieldRow>
        {isMain && isCapitalProtected &&
              (
              <p>{GUARANTEE_LEVEL_WARNING(maxGuaranteeLevel)}</p>
              )}
        {isMainAutocall && (
          <div className={b('autocall-enabled-decs')}>
            {AUTOCALL_IS_ENABLED}
          </div>
        )}
        {!isMainAuto() && isChildAuto() && (
          <div className={b('autocall-disabled-decs')}>
            {AUTOCALL_IS_NOT_ENABLED}
          </div>
        )}
        <FieldRow
          label={currencyFieldDescription}
          helpMessage="Валюта"
        >
          <Currencies
            value={currencyCode}
            handleChange={handleChangeCurrency}
            name={STRATEGY_FIELD_NAME.CURRENCY_RADIO_BUTTON}
            currencies={currencies}
            index={index}
            disabled={!isMain}
          />
        </FieldRow>
        <FieldRow
          label={SELECT_STRATEGY_TERM}
          helpMessage={BASKET_TERM_HELP_MESSAGE}
        >
          <Select
            {...getInputProps(STRATEGY_FIELD_NAME.TERM)}
            options={availableProductTermOptions}
            disabled={isDisabledTerm}
            required
            search
          />
        </FieldRow>
        <FieldRow
          label={`${CHOOSE_ASSETS}: ${MAX_ASSETS(assetLimit)}`}
          helpMessage={BASKET_ASSETS_HELP_MESSAGE}
        >
          <div className={b('assets-wrapper')}>
            <CustomMultiSelect
              {...getInputProps(STRATEGY_FIELD_NAME.ASSET_CODES)}
              options={activesOptions}
              renderLabel={renderLabel}
              onLabelClick={handleClickOnAsset}
              isSearch
              maxItems={assetLimit}
              required
              className={b('assets').toString()}
              disabled={!values[STRATEGY_FIELD_NAME.TERM]}
              onChange={(e, { value }) => {
                handleCheckMultipleAssets(e, { value });
              }}
            />
            {isDifferentAssetsColorsWarning && (
              <div className={b('asset-warning')}>
                <Icon name="warning sign" className={b('asset-warning-icon').toString()} />
                <p>{`${WARNING_MESSAGE_DIFFERENT_COLORS_ASSETS}!`}</p>
              </div>
            )}
            {values[STRATEGY_FIELD_NAME.IS_MULTIPLE_ASSET_CLASS] && (
              <p className={b('class-warning')}>{ASSETS_HAVE_DIFFERENT_CLASSES}</p>
            )}
          </div>
        </FieldRow>
        <Collapse isOpen={isCoupon}>
          <>
            {!isMain && (
              <FieldRow
                label={COUPON_PERCENT_YEAR}
                helpMessage={BASKET_HELP_MESSAGE_COEFFICIENT_OR_COUPON}
              >
                <Input
                  {...getInputProps(STRATEGY_FIELD_NAME.COUPON)}
                  units={<p className={b('percent')}>%</p>}
                  wrapperClassName={b('percent-input').toString()}
                  required
                />
              </FieldRow>
            )}
          </>
        </Collapse>
        <FieldRow
          label={CHOOSE_PAYOUT_TIMES_CONTRACT}
          helpMessage={BASKET_PAYOUT_COUPON_HELP_MESSAGE}
        >
          <Select
            {...getInputProps(STRATEGY_FIELD_NAME.PAYOUT_CODE)}
            options={productPayoutOptions}
            required
            search
          />
        </FieldRow>
        <FieldRow
          helpMessage={BASKET_LEVEL_BARRIERS_HELP_MESSAGE}
          label={CHOOSE_BARRIERS_LEVELS}
        >
          <BarrierFields
            count={countBarriers}
            values={values[STRATEGY_FIELD_NAME.BARRIERS]}
            onChange={handleChangeBarriers}
            minValue={minBarrierLevelValue}
            maxValue={maxBarrierLevelValue}
            disabled={
                    !values[STRATEGY_FIELD_NAME.TERM] ||
                    !values[STRATEGY_FIELD_NAME.PAYOUT_CODE]
                }
            errors={values[STRATEGY_FIELD_NAME.BARRIER_MANUAL_ERROR]}
            onError={handleChangeErrorBarrier}
            generalError={barrierError}
            description={barrierDescription}
            showError={hasRestrictions}
          />
        </FieldRow>
        <Collapse isOpen={isKu && !isMain}>
          <FieldRow
            label={PARTICIPATION_COEFFICIENT}
            helpMessage={BASKET_HELP_MESSAGE_COEFFICIENT_OR_COUPON}
          >
            <Input
              {...getInputProps(STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT)}
              units={<p className={b('percent')}>%</p>}
              wrapperClassName={b('percent-input').toString()}
              required
            />
          </FieldRow>
        </Collapse>
        {isMain && isAutocall && (
          <>
            <FieldRow
              label={SPECIFY_AUTOCALL_BARRIER}
              helpMessage={(
                <>
                  <p className={b('popup-info')}>{AUTOCALL_DESCRIPTION}</p>
                  <p className={b('popup-info')}>{IF_9999_OPTION_DISABLED}</p>
                </>
                    )}
            >
              <Input
                {...getInputProps(STRATEGY_FIELD_NAME.BARRIER_AUTOCALL)}
                units={<p className={b('percent')}>%</p>}
                wrapperClassName={b('percent-input').toString()}
                required
              />
            </FieldRow>
            <FieldRow
              label={SPECIFY_BONUS_COUPON}
              helpMessage={(
                <>
                  <p className={b('popup-info')}>{BONUS_COUPON_DESCRIPTION}</p>
                  <p className={b('popup-info')}>{AUTOCALL_FIELD_NOT_AFFECT_CALCULATION_BONUS_COUPON}</p>
                </>
                    )}
            >
              <Input
                {...getInputProps(STRATEGY_FIELD_NAME.BONUS_COUPON)}
                units={<p className={b('percent')}>%</p>}
                wrapperClassName={b('percent-input').toString()}
                required
              />
            </FieldRow>
          </>
        )}
        {isChildAutocall && (
          <>
            <FieldRow
              label={SPECIFY_AUTOCALL_BARRIER}
              helpMessage={(
                <>
                  <p className={b('popup-info')}>{AUTOCALL_DESCRIPTION}</p>
                  <p className={b('popup-info')}>{IF_9999_OPTION_DISABLED}</p>
                </>
                    )}
            >
              <Input
                disabled
                value={barrierAutocall}
                units={<p className={b('percent')}>%</p>}
                wrapperClassName={b('percent-input').toString()}
                required
              />
            </FieldRow>
            <FieldRow
              label={SPECIFY_BONUS_COUPON}
              helpMessage={(
                <>
                  <p className={b('popup-info')}>{BONUS_COUPON_DESCRIPTION}</p>
                  <p className={b('popup-info')}>{AUTOCALL_FIELD_NOT_AFFECT_CALCULATION_BONUS_COUPON}</p>
                </>
                    )}
            >
              <Input
                {...getInputProps(STRATEGY_FIELD_NAME.BONUS_COUPON)}
                disabled
                units={<p className={b('percent')}>%</p>}
                wrapperClassName={b('percent-input').toString()}
                required
              />
            </FieldRow>
          </>
        )}
        {
              (currentProductOption?.isFlexRedemption || currentProductOption?.isFixRedemption) && (
              <>
                <FieldRow
                  label={BARRIER_NOMINAL}
                  helpMessage={(
                    <p className={b('popup-info')}>{NOTIONAL_BARRIER_DESCRIPTION}</p>
                        )}
                >
                  <Input
                    {...getInputProps(STRATEGY_FIELD_NAME.NOTIONAL_BARRIER)}
                    units={<p className={b('percent')}>%</p>}
                    wrapperClassName={b('percent-input').toString()}
                    required
                  />
                </FieldRow>
              </>
              )
          }
        {
              (currentProductOption?.isFlexRedemption) && (
              <>
                <FieldRow
                  label={BARRIER_GEARED_PUT}
                  helpMessage={(
                    <p className={b('popup-info')}>{GEARED_BARRIER_DESTIPTION}</p>
                        )}
                >
                  <Input
                    {...getInputProps(STRATEGY_FIELD_NAME.GEARED_BARRIER)}
                    units={<p className={b('percent')}>%</p>}
                    wrapperClassName={b('percent-input').toString()}
                    required
                  />
                </FieldRow>
              </>
              )
          }
        {
              (currentProductOption?.lookbackData?.length) && (
              <FieldRow
                label={LOOKBACK_OPTION_ENABLED}
                helpMessage={(
                  <p className={b('popup-info')}>{LOOKBACK_OPTION_DESCRIPTION}</p>
                      )}
              >
                <div className={b('lookback')}>
                  <CustomCheckBox
                    name={`rule${index}`}
                    checked={values.lookbackFlag}
                    onChange={() => handleLookbackFlag(currentProductOption.lookbackData)}
                  />
                  <span
                    className={b('lookback-title')}
                  >
                    {currentProductOption.lookbackData.map((el) => `+ ${el.periodLength} ${declOfDay(el.periodLength)}`).join('; ')}
                  </span>
                </div>
              </FieldRow>
              )
          }
        {isKu && (
          <FieldRow
            label={GROWTH_CAP}
          >
            <Input
              {...getInputProps(STRATEGY_FIELD_NAME.GROWTH_CAP)}
              disabled={isGrowthCapDisabled}
              units={<p className={b('percent')}>%</p>}
              wrapperClassName={b('percent-input').toString()}
              required
            />
          </FieldRow>
        )}
        {
          settings.successFeeAvailable && (
            <FieldRow
              label={SUCCESS_FEE_ADD}
            >
              <div className={b('sfee')}>
                <div className={b('percent-input-wrapper')}>
                  <CustomCheckBox
                    disabled={!settings.successFeeAvailable}
                    name={`successFeeFlag${index}`}
                    checked={values[STRATEGY_FIELD_NAME.SUCCESS_FEE_FLAG]}
                    onChange={() => handleSuccessFeeFlag(values[STRATEGY_FIELD_NAME.SUCCESS_FEE])}
                  />
                  {values[STRATEGY_FIELD_NAME.SUCCESS_FEE_FLAG] && (
                    <div className={b('input-container')}>
                      <Input
                        {...getInputProps(STRATEGY_FIELD_NAME.SUCCESS_FEE)}
                        type="number"
                        min={currentOptionType.minValue}
                        max={currentOptionType.maxValue}
                        step="0.5"
                        wrapperClassName={`${b('percent-input').toString()} ${b('input-margin')}`}
                      />
                      <p>
                        {SUCCESS_FEE_DESCRIPTION(
                          currentOptionType.minValue,
                          currentOptionType.maxValue,
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </FieldRow>
          )
        }
        {isMain && (
          <Button
            className={b('button').toString()}
            onClick={handleAddGuaranteePayout}
          >
            {ADD_GUARANTEED_PAYOUT}
          </Button>
        )}
        {isMain && guaranteedPayouts?.map((item, ind) => (
          <GuaranteedPayout
            term={values[STRATEGY_FIELD_NAME.TERM]}
            guaranteedPayout={item}
            deleteGuaranteedPayout={deleteGuaranteedPayout}
            changeFieldsForName={changeFieldsForName}
            index={ind}
          />
        ))}
      </div>
    </>
  );
};

Strategy.defaultProps = defaultProps;

Strategy.propTypes = propTypes;

export default React.memo(Strategy);
