import React from 'react';
import { Row, Col } from 'reactstrap';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { connect } from 'react-redux';
import { Input, Select } from 'semantic-ui-react';
import {
  getProducts as parseProducts,
  getStatus,
  getPartners,
  getBranches,
  numberOrStringTypes,
  getConfigMySales,
} from 'helpers';
import { actions, checkAccessAction } from '../../roles';

const defaultProps = {
  roles: {},
};

const propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  roles: PropTypes.object,
  ...numberOrStringTypes(
    'productId',
    'statusId',
    'branchId',
    'partnerId',
    'contractNumber',
    'ammount',
  ),
};

const b = block('form-products');

const {
  SEARCH,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  CONTRACT_NUMBER,
  INSURANCE_SUM,
  PRODUCT,
  STATUS,
  SUBDIVISION,
  USER,
  PARTNER,
} = LANG_DICTIONARY;

const SearchInputs = (props) => {
  const {
    products,
    statusList,
    filterPartners,
    branches,
    handleChange,
    productId,
    statusId,
    branchId,
    partnerId,
    contractNumber,
    ammount,
    insurerFio,
    userFio,
    searchByUser,
    searchByBranch,
    searchByPartner,
    idPartner,
    roles,
  } = props;
  const isCheckAction = checkAccessAction(actions.SHOW_SEARCH_SALES, roles);

  return (
    <div className={b('search-inputs')}>
      <Row>
        <Col md="1" sm="1">
          {isCheckAction && <p className={b('search-text')}>{SEARCH}</p>}
        </Col>
        <Col md="3" sm="6">
          {isCheckAction && (
          <Input
            placeholder={`${LAST_NAME} ${FIRST_NAME} ${PATRONYMIC}`}
            onChange={handleChange}
            value={insurerFio}
            name="insurerFio"
            type="text"
          />
          )}
        </Col>
        <Col md="2" sm="5">
          <Input
            onChange={handleChange}
            name="contractNumber"
            type="text"
            value={contractNumber}
            placeholder={CONTRACT_NUMBER}
          />
        </Col>
        <Col md="2" sm="4">
          <Input
            onChange={handleChange}
            name="ammount"
            type="text"
            value={ammount}
            placeholder={INSURANCE_SUM}
          />
        </Col>
        <Col md="2" sm="4">
          <Select
            search
            options={parseProducts(products)}
            placeholder={PRODUCT}
            name="productId"
            value={productId}
            onChange={handleChange}
          />
        </Col>
        <Col md="2" sm="4">
          <Select
            search
            placeholder={STATUS}
            options={getStatus(statusList)}
            name="statusId"
            value={statusId}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        {searchByBranch && (
        <Col md="4">
          <Select
            search
            placeholder={`${SUBDIVISION}:`}
            options={getBranches(branches)}
            name="branchId"
            value={branchId}
            onChange={handleChange}
          />
        </Col>
        )}
        {searchByUser && (
        <Col md="4">
          <Input
            placeholder={`${USER}:`}
            onChange={handleChange}
            value={userFio}
            name="userFio"
            type="text"
          />
        </Col>
        )}
        {searchByPartner && (
        <Col md="4">
          <Select
            search
            placeholder={`${PARTNER}:`}
            options={getPartners(filterPartners)}
            value={partnerId || partnerId === null ? partnerId : idPartner}
            name="partnerId"
            onChange={handleChange}
          />
        </Col>
        )}

      </Row>
    </div>
  );
};

const mapStateToProps = (
  {
    authReducer: {
      roles,
    },
  },
) => ({
  ...getConfigMySales(roles),
  roles,
});

SearchInputs.propTypes = propTypes;
SearchInputs.defaultProps = defaultProps;
export default connect(mapStateToProps)(SearchInputs);
