import { createSelector } from 'reselect';
import { getRelationships, getGenders } from 'helpers';

const getUserInfo = (state) => state.authReducer;
const getScenarioInfo = (
  {
    checkoutReducer:
    {
      productForm:
      {
        currencyRateId,
        currencyRate,
        scenario,
      },
    },
  },
) => ({ currencyRate, currencyRateId, scenario });

const getPolicySum = (
  {
    checkoutReducer:
    {
      productValues:
      {
        policySum,
        insuranceAmmount,
      },
    },
  },
) => ({ policySum, insuranceAmmount });

const getContractNum = ({ checkoutReducer: { contractData: { contractNum } } }) => contractNum;
const getCountryList = ({ helperReducer: { countries } }) => countries;
const getCurrencyList = ({ helperReducer: { currencies } }) => currencies;
const getCitizenshipList = ({ helperReducer: { citizenships } }) => citizenships;
const getActionRoles = ({ authReducer: { roles } }) => roles;
const getCurrentCaptchaId = ({ authReducer: { captchaId } }) => captchaId;

const getGenderList = ({ helperReducer: { genders } }) => genders;
const getRelationshipsList = ({ helperReducer: { relationships } }) => relationships;
const getSnilsError = ({ helperReducer: { snilsError } }) => snilsError;
const getBeneficiariesList = ({ helperReducer: { beneficiaries } }) => beneficiaries;
const getRegionSearchResults = ({ helperReducer: { regionSearchResult } }) => regionSearchResult;
const getIsRegionSearchLoading =
  ({ helperReducer: { isRegionSearchLoading, isRegionSearchLoadingLiving } }) => ({
    isRegionSearchLoading,
    isRegionSearchLoadingLiving,
  });

const getInsurerValues = ({ checkoutReducer: { insurerValues } }) => insurerValues;
const getInsuredValues = ({ checkoutReducer: { insuredValues } }) => insuredValues;
const getProductValues = ({ checkoutReducer: { productValues } }) => productValues;
const getMainRequestId = ({ checkoutReducer: { mainRequestId } }) => mainRequestId;
const getChildRequestIds = ({ checkoutReducer: { childRequestIds } }) => childRequestIds;
const getProductId = ({ checkoutReducer: { fund } }) => fund.id;

const getRefUnionServicePackageId =
  ({ checkoutReducer: { refUnionServicePackageId } }) => refUnionServicePackageId;

const getCtsIdArr = ({ checkoutReducer: { ctsIdArr } }) => ctsIdArr;
const getIsAnnul = ({ checkoutReducer: { isAnnul } }) => isAnnul;
const getIsContractFormed = ({ checkoutReducer: { isContractFormed } }) => isContractFormed;
const getIsEditBasket = ({ checkoutReducer: { isEditBasket } }) => isEditBasket;

const getIsListBlackAddress = createSelector(
  ({ checkoutReducer: { productForm: { listBlackAddress } } }) => listBlackAddress,
  ({ nszProduct: { nszProductValues: { listBlackAddress } } }) => listBlackAddress,
  ({ kszProduct: { kszProductValues: { listBlackAddress } } }) => listBlackAddress,

  (listBlackAddressIsz, listBlackAddressNsz, listBlackAddressKsz) => listBlackAddressIsz
    || listBlackAddressNsz
    || listBlackAddressKsz,
);

const getBlackAddressInfo = ({
  checkoutReducer: {
    products,
    scenario: iszScenario,
    mainRequestId,
    productValues: {
      policySum,
    },
  },
  nszProduct: {
    calculatorData: {
      accumulationAmount,
      risks,
      scenario: nszScenario,
    },
  },
  kszProduct: {
    fieldValues: {
      creditInsuranceSum,
      productId: insProductId,
    },
    kszProductValues: {
      scenario: kszScenario,
    },
  },
  constructorIszInterface: {
    productGroups: {
      groupedProducts,
      mainProduct,
    },
  },
}) => ({
  products,
  iszScenario,
  nszScenario,
  kszScenario,
  mainRequestId,
  policySum,
  accumulationAmount,
  creditInsuranceSum,
  risks,
  groupedProducts,
  mainProduct,
  insProductId,
});

const getCheckPassportCovid = ({
  checkoutReducer: {
    productForm: {
      checkPassportCovid,
    },
  },
}) => checkPassportCovid;

const getInsurerValuesSelector = createSelector(
  getInsurerValues,
  (insurer) => insurer,
);

const getInsuredValuesSelector = createSelector(
  getInsuredValues,
  (insured) => insured,
);

const getProductValuesSelector = createSelector(
  getProductValues,
  (product) => product,
);

const getGendersSelector = createSelector(
  getGenderList,
  (genders) => getGenders(genders),
);

const getCountriesSelector = createSelector(
  getCountryList,
  (countries) => countries,
);

const getCurrenciesSelector = createSelector(
  getCurrencyList,
  (currencies) => currencies,
);

const getCitizenshipSelector = createSelector(
  getCitizenshipList,
  (citizenships) => citizenships,
);

const getRelationshipsSelector = createSelector(
  getRelationshipsList,
  (relationships) => getRelationships(relationships),
);

const getBeneficiariesListSelector = createSelector(
  getBeneficiariesList,
  (beneficiaries) => beneficiaries.map((item) => ({
    key: item,
    value: item,
    text: item,
  })),
);

const getUserInfoSelector = createSelector(
  getUserInfo,
  (userInfo) => userInfo,
);

const getInsuredIsSameSelector = ({ checkoutReducer: { insuredIsSame } }) => insuredIsSame;
const getIsLoadingFormSelector = ({
  checkoutReducer,
  validation,
}) => checkoutReducer.isLoading || validation.isLoading;
const getStageSelector = ({ checkoutReducer: { stage } }) => stage;
const getErrorMessageFormSelector = ({ checkoutReducer: { error } }) => error;

const getQualificationFormSelector = ({ authReducer: { qualification } }) => qualification;
const getPartnerIdFromSelector = ({ authReducer: { selectedPartnerId } }) => selectedPartnerId;

const getUnitedFrontendParamsSelector = createSelector(
  ({ authReducer: { masterId } }) => masterId,
  ({ authReducer: { riskProfile } }) => riskProfile,
  ({ authReducer: { qualification } }) => qualification,
  ({ authReducer: { isExistOtherUnitedFrontSession } }) => isExistOtherUnitedFrontSession,
  ({ authReducer: { isUnitedFront } }) => isUnitedFront,
  ({ authReducer: { isOpenBlockUnitedFront } }) => isOpenBlockUnitedFront,
  ({ authReducer: { unionServicePackageId } }) => unionServicePackageId,

  ({ shared: { windowTimestamp } }) => windowTimestamp,
  ({ shared: { windowUuid } }) => windowUuid,

  (
    masterId,
    riskProfile,
    qualification,
    isExistOtherUnitedFrontSession,
    isUnitedFront,
    isOpenBlockUnitedFront,
    unionServicePackageId,
    windowTimestamp,
    windowUuid,
  ) => ({
    masterId,
    windowUuid,
    windowTimestamp,
    riskProfile,
    qualification,
    isExistOtherUnitedFrontSession,
    isUnitedFront,
    isOpenBlockUnitedFront,
    unionServicePackageId,
  }),
);

export {
  getUnitedFrontendParamsSelector,
  getErrorMessageFormSelector,
  getStageSelector,
  getUserInfoSelector,
  getInsurerValuesSelector,
  getInsuredValuesSelector,
  getProductValuesSelector,
  getInsuredIsSameSelector,
  getIsLoadingFormSelector,
  getBeneficiariesListSelector,
  getSnilsError,
  getRelationshipsSelector,
  getGendersSelector,
  getCountriesSelector,
  getCurrenciesSelector,
  getCitizenshipSelector,
  getCurrentCaptchaId,
  getActionRoles,
  getContractNum,
  getPolicySum,
  getRefUnionServicePackageId,
  getUserInfo,
  getCheckPassportCovid,
  getScenarioInfo,
  getMainRequestId,
  getBlackAddressInfo,
  getChildRequestIds,
  getCtsIdArr,
  getIsAnnul,
  getIsContractFormed,
  getIsEditBasket,
  getQualificationFormSelector,
  getRegionSearchResults,
  getIsRegionSearchLoading,
  getIsListBlackAddress,
  getProductId,
  getPartnerIdFromSelector,
};
