import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { MainBtn } from 'components';
import { withCustomRouter } from 'HOC';
import {
  LANG_DICTIONARY,
} from 'consts';

import useTestDataGenerate from './useTestDataGenerate';
import './styles.scss';

const defaultProps = {
  scenario: '',
  type: '',
  isTestBranch: false,
  isResidentRF: true,
  isBankRU: false,
  availableForNonResident: false,
};

const propTypes = {
  scenario: PropTypes.string,
  type: PropTypes.string,
  isTestBranch: PropTypes.bool,
  isResidentRF: PropTypes.bool,
  isBankRU: PropTypes.bool,
  availableForNonResident: PropTypes.bool,
};

const b = block('fill-test-data-button');
const {
  FILL_TEST_DATA,
} = LANG_DICTIONARY;

const TestDataGenerator = ({
  scenario,
  type,
  isTestBranch,
  isResidentRF,
  isBankRU,
  availableForNonResident,
}) => {
  const { action, isHide } = useTestDataGenerate(scenario, type, {
    isResidentRF,
    isBankRU,
    availableForNonResident,
  });

  const isHidden = !isTestBranch || isHide;

  return (
    <MainBtn className={b({ hidden: isHidden })} text={FILL_TEST_DATA} onClick={action} />
  );
};

TestDataGenerator.propTypes = propTypes;
TestDataGenerator.defaultProps = defaultProps;

const mapStateToProps = ({
  authReducer: {
    isTestBranch,
  },
}) => ({
  isTestBranch,
});

export default withCustomRouter(connect(
  mapStateToProps,
  {},
)(TestDataGenerator));
