import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';
import * as checkoutActions from 'redux/actions/checkoutActions';
import * as nszActions from 'redux/nszProduct/actions';
import * as kszActions from 'redux/kszProduct/actions';

const getAdditionalData = (data) => {
  const {
    fillInsurerDataCheckout,
    fillInsuredDataIsz,
    fillBenefeciaryDataIsz,
    fillTestDataBeneficiaryNsz,
    fillTestDataInsuredNsz,
    fillTestDataInsurerNsz,
    fillInsurerTestDataKsz,
    fillInsuredDataKsz,
    fillBeneficiaryTestDataKsz,
  } = useActions({
    ...checkoutActions,
    ...nszActions,
    ...kszActions,
  });

  const {
    checkoutReducer: {
      productForm: {
        beneficiaryInLaw,
        hideInn: hideInnIsz,
        hideSnils: hideSnilsIsz,
        hideBankInfo: hideBankInfoIsz,
        insurerIsInsured: iszInsurerIsInsured,
      },
      productValues: {
        birthDate,
      },
    },
    nszProduct: {
      nszProductValues: {
        insurerIsInsured: nszInsurerIsInsured,
        hideInn: hideInnNsz,
        hideSnils: hideSnilsNsz,
        beneficiaryInLaw: beneficiaryByLawNsz,
      },
      calculatorData: {
        insuredBirthDate,
        insuredSexId,
        childInsuredBirthDate,
        childInsuredSexId,
      },
    },
    kszProduct: {
      kszProductValues: {
        beneficiaryInLaw: beneficiaryByLawKsz,
        insurerIsInsured: kszInsurerIsInsured,
        hideInn: hideInnKsz,
        hideSnils: hideSnilsKsz,
      },
    },
    helperReducer: {
      beneficiaries,
    },
  } = useSelector((state) => state);

  const numberOfBeneficiaries = beneficiaries && beneficiaries.length <= 4
    ? beneficiaries.length
    : 4;

  return {
    fillInsurerDataCheckout,
    fillInsuredDataIsz,
    fillBenefeciaryDataIsz,
    beneficiaryByLawIsz: beneficiaryInLaw,
    fillTestDataBeneficiaryNsz,
    fillTestDataInsuredNsz,
    fillTestDataInsurerNsz,
    nszInsurerIsInsured,
    insuredBirthDate,
    insuredSexId,
    childInsuredBirthDate,
    childInsuredSexId,
    beneficiaryByLawNsz,
    kszInsurerIsInsured,
    beneficiaryByLawKsz,
    fillInsurerTestDataKsz,
    fillInsuredDataKsz,
    fillBeneficiaryTestDataKsz,
    hideInnIsz,
    hideSnilsIsz,
    hideBankInfoIsz,
    hideInnKsz,
    hideSnilsKsz,
    hideInnNsz,
    hideSnilsNsz,
    numberOfBeneficiaries,
    iszInsurerIsInsured,
    birthDate,
    ...data,
  };
};

export default getAdditionalData;
