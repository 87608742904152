import React from 'react';
import {
  NszAgentData,
  KszAgentData,
  TestDataGenerator,
} from 'containers';
import { AgentCheckingErrorPopup, MainBtn } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  NAMES_GROUP_SETTINGS,
  BLOCK_NAMES,
  LANG_DICTIONARY,
  TYPE_PERSON,
  INS_LINE_TYPES,
  TEST_DATA_ENUMS,
} from 'consts';
import PropTypes from 'prop-types';
import {
  getAgentPersonBlockSelector,
  getAgentDocumentBlockSelector,
  getAgentAddressBlockSelector,
  getAgentBankBlockSelector,
  getAgentAddressLivingBlockSelector,
  getAgentInnSnilsBlockSelector,
  getAgentContactBlockSelector,
  getAgentRelationBlockSelector,
  getAgentBankBlockNotRFSelector,
  getAgentContactBlockNotRFSelector,
  getAgentDocumentBlockNotRFSelector,
  getAgentAddressBlockNotRFSelector,
  getAgentAddressLivingBlockNotRFSelector,
  getInsuredIsSame,
  getMainRequestId,
} from 'redux/rootSelectors';
import {
  getErrorsFromValidationError,
  rebuildAgentErrorsBlock,
  getProductConfig,
  simpleDate,
  scrollToError,
  getStoreKeysByInsLineType,
  getAgentChecking,
} from 'helpers';
import {
  validateAgentFieldValues,
  setAgentAllTouchFields,
  clearAgentErrors,
  changeInsuredIsSame,
  changeDisableInsuredIsSame,
  checkAgent,
} from 'redux/rootActions';
import { withCustomRouter } from 'HOC';
import { block } from 'bem-cn';
import { memoize } from 'lodash';
import { Schema, SchemaNotRF } from './schema';
import '../styles.scss';

const { insurerAgent } = NAMES_GROUP_SETTINGS;
const formType = 'insurer';
const { NEXT, INSURER } = LANG_DICTIONARY;
const b = block('contragent-data');
const { insurer } = TYPE_PERSON;

const propTypes = {
  scenario: PropTypes.string.isRequired,
  isInsured: PropTypes.bool.isRequired,
  insuredIsSame: PropTypes.bool.isRequired,
  checkAccountNumbersError: PropTypes.func.isRequired,
  checkNumbers: PropTypes.func.isRequired,
  personBlockProps: PropTypes.object.isRequired,
  documentBlockProps: PropTypes.object.isRequired,
  addressBlockProps: PropTypes.object.isRequired,
  bankBlockProps: PropTypes.object.isRequired,
  addressLivingBlockProps: PropTypes.object.isRequired,
  insSnilsBlockProps: PropTypes.object.isRequired,
  validateFieldValues: PropTypes.object.isRequired,
  contactBlockProps: PropTypes.object.isRequired,
  documentBlockNotRFProps: PropTypes.object.isRequired,
  addressBlockNotRFProps: PropTypes.object.isRequired,
  bankBlockNotRFProps: PropTypes.object.isRequired,
  addressLivingBlockNotRFProps: PropTypes.object.isRequired,
  contactBlockNotRFProps: PropTypes.object.isRequired,
  relationBlockProps: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setAllTouchFields: PropTypes.func.isRequired,
  passportError: PropTypes.string.isRequired,
  snilsError: PropTypes.string.isRequired,
  setStage: PropTypes.func.isRequired,
  calculatorData: PropTypes.object.isRequired,
  setInsuredIsSame: PropTypes.func.isRequired,
  setDisableInsuredIsSame: PropTypes.func.isRequired,
  checkPassportCovid: PropTypes.bool,
  hideBankInfo: PropTypes.bool,
  stage: PropTypes.number,
  insuredDataUrl: PropTypes.string.isRequired,
  preliminaryCalculationUrl: PropTypes.string.isRequired,
  additionalParams: PropTypes.object.isRequired,
  checkAgentAction: PropTypes.func.isRequired,
  isLoadingPodft: PropTypes.bool.isRequired,
  addressCheckingPayload: PropTypes.object.isRequired,
  mainRequestId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
};

const defaultProps = {
  checkPassportCovid: false,
  hideBankInfo: false,
  stage: 0,
};

class Insurer extends React.Component {
  constructor(props) {
    super(props);

    const {
      stage,
      history,
      match: { params: { id } },
      preliminaryCalculationUrl,
      additionalParams,
    } = props;

    if (stage < 2) {
      history.push(`${preliminaryCalculationUrl}/${id}`);
    }

    switch (additionalParams.type) {
      case INS_LINE_TYPES.NSZ:
        this.InsurerMainComponent = NszAgentData(formType);
        break;
      case INS_LINE_TYPES.KSZ:
        this.InsurerMainComponent = KszAgentData(formType);
        break;
      default:
    }
  }

  getConfig = memoize((scenario) => getProductConfig(scenario, insurerAgent));

  validateForm = ({ newValue, isExecution }) => {
    const {
      isInsured,
      insuredIsSame,
      checkAccountNumbersError,
      checkNumbers,
      personBlockProps,
      documentBlockProps,
      addressBlockProps,
      bankBlockProps,
      addressLivingBlockProps,
      insSnilsBlockProps,
      validateFieldValues,
      contactBlockProps,
      documentBlockNotRFProps,
      addressBlockNotRFProps,
      bankBlockNotRFProps,
      addressLivingBlockNotRFProps,
      contactBlockNotRFProps,
      relationBlockProps,
      clearErrors,
      scenario,
      checkPassportCovid,
      hideBankInfo,
    } = this.props;

    const { isBank } = this.getConfig(scenario);
    const { documentType } = documentBlockProps;
    const { values: { livingAddressIsSame } } = addressLivingBlockProps;
    const { values: { isBankRU, intermediaryBank } } = bankBlockNotRFProps;
    const { values: { isResidentRF } } = personBlockProps;
    const notOnlyPassport = documentType === 2;

    const values = {
      ...personBlockProps.values,
      ...insSnilsBlockProps.values,
      ...relationBlockProps.values,
      ...(isResidentRF ? {
        ...documentBlockProps.values,
        ...addressBlockProps.values,
        ...bankBlockProps.values,
        ...addressLivingBlockProps.values,
        ...contactBlockProps.values,
      } : {
        ...documentBlockNotRFProps.values,
        ...addressBlockNotRFProps.values,
        ...bankBlockNotRFProps.values,
        ...bankBlockProps.values,
        ...addressLivingBlockNotRFProps.values,
        ...contactBlockNotRFProps.values,
      }),
      ...(newValue ? { [newValue.name]: newValue.value } : {}),
    };

    const blockValues = {
      [BLOCK_NAMES.personBlock]: personBlockProps.values,
      [BLOCK_NAMES.documentBlock]: documentBlockProps.values,
      [BLOCK_NAMES.addressBlock]: addressBlockProps.values,
      [BLOCK_NAMES.bankBlock]: bankBlockProps.values,
      [BLOCK_NAMES.addressBlockLiving]: addressLivingBlockProps.values,
      [BLOCK_NAMES.innSnilsBlock]: insSnilsBlockProps.values,
      [BLOCK_NAMES.contactBlock]: contactBlockProps.values,
      [BLOCK_NAMES.relationBlock]: relationBlockProps.values,
      [BLOCK_NAMES.documentBlockNotRF]: documentBlockNotRFProps.values,
      [BLOCK_NAMES.addressBlockNotRF]: addressBlockNotRFProps.values,
      [BLOCK_NAMES.bankBlockNotRF]: bankBlockNotRFProps.values,
      [BLOCK_NAMES.addressLivingBlockNotRF]: addressLivingBlockNotRFProps.values,
      [BLOCK_NAMES.contactBlockNotRF]: contactBlockNotRFProps.values,
    };

    try {
      if (isResidentRF) {
        Schema({
          livingAddressIsSame,
          isBank: isBank && !hideBankInfo,
          isInsured,
          insuredIsSame,
          notOnlyPassport,
          checkAccountNumbersError,
          checkNumbers,
          birthDate: values.birthDate,
          isCheckPasswordCovid: checkPassportCovid,
        })
          .validateSync({ ...values }, { abortEarly: false });
      } else {
        SchemaNotRF({
          livingAddressIsSame,
          isBank: isBank && !hideBankInfo,
          isInsured,
          insuredIsSame,
          notOnlyPassport,
          checkAccountNumbersError,
          checkNumbers,
          birthDate: values.birthDate,
          isCheckPasswordCovid: checkPassportCovid,
          intermediaryBank,
          isBankRU,
        })
          .validateSync({ ...values }, { abortEarly: false });
      }

      clearErrors(formType);

      return true;
    } catch (error) {
      const validationErrors = getErrorsFromValidationError(error);

      if (isExecution) {
        scrollToError(validationErrors);
      }

      const errorsAtBlocks = rebuildAgentErrorsBlock(blockValues, validationErrors);
      validateFieldValues(errorsAtBlocks, formType);

      return !error.errors.length;
    }
  };

  validateAgent = (next) => {
    const {
      personBlockProps: { values: personValues },
      documentBlockProps: { values: documentValues },
      addressBlockProps: { values: addressValues },
      addressLivingBlockProps: { values: addressLivingValues },
      checkAgentAction,
      productId,
      addressCheckingPayload,
      mainRequestId,
    } = this.props;

    const body = {
      agent: getAgentChecking({
        ...personValues,
        ...documentValues,
        ...addressValues,
        ...addressLivingValues,
      }, addressLivingValues.livingAddressIsSame),
      ...addressCheckingPayload,
      productId,
      agentType: TYPE_PERSON.insurer,
      mainRequestId: mainRequestId || undefined,
    };

    checkAgentAction({
      next,
      data: [{
        agentName: INSURER,
        body,
      }],
    });
  }

  nextStep = () => {
    const {
      match: { params: { id } },
      history,
      setAllTouchFields,
      passportError,
      snilsError,
      setStage,
      scenario,
      calculatorData: {
        insuredSexId,
        insuredBirthDate,
      },
      personBlockProps: {
        values: {
          gender,
          birthDate,
        },
      },
      setInsuredIsSame,
      setDisableInsuredIsSame,
      insuredDataUrl,
    } = this.props;

    const isValidationMainForm = this.validateForm({ isExecution: true });
    setAllTouchFields(formType);

    if (scenario.includes(INS_LINE_TYPES.NSZ)) {
      const insuredBirthCalculator = simpleDate(insuredBirthDate);
      const insuredBirthInsurer = simpleDate(birthDate);
      const insuredIsSameValue = insuredSexId === gender &&
        insuredBirthCalculator === insuredBirthInsurer;
      setInsuredIsSame(insuredIsSameValue);
      setDisableInsuredIsSame(!insuredIsSameValue);
    }

    const isValid = !passportError && !snilsError && isValidationMainForm;
    if (!isValid) {
      return null;
    }

    this.validateAgent(() => {
      setStage(3);
      history.push(`${insuredDataUrl}/${id}`);
    });

    return null;
  };

  render() {
    const { InsurerMainComponent } = this;
    const {
      scenario,
      isLoadingPodft,
      personBlockProps,
      bankBlockNotRFProps,
    } = this.props;
    const config = this.getConfig(scenario);
    const { values: { isBankRU } } = bankBlockNotRFProps;
    const { values: { isResidentRF, availableForNonResident } } = personBlockProps;

    return (
      <div className={b()}>
        <AgentCheckingErrorPopup />
        <InsurerMainComponent
          config={config}
          validate={this.validateForm}
          formType={formType}
          form={insurer}
          {...this.props}
        />
        <div className={b('control-interface')}>
          <TestDataGenerator
            scenario={scenario}
            type={TEST_DATA_ENUMS.insurer}
            isResidentRF={isResidentRF}
            isBankRU={isBankRU}
            availableForNonResident={availableForNonResident}
          />
          <MainBtn
            onClick={this.nextStep}
            text={NEXT}
            isLoading={isLoadingPodft}
            disabled={isLoadingPodft}
            className={b('btn').toString()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    product,
    productValues,
  } = getStoreKeysByInsLineType(ownProps.additionalParams.type);

  const {
    [product]: {
      checkAccountNumbersError,
      childInsuredValues,
      stage,
      calculatorData,
      [productValues]: {
        personType,
        hideInn,
        hideSnils,
        hideBankInfo,
        listBlackAddress,
        checkPassportCovid,
        restrictions,
        productTerms,
        scenario,
        insurerIsInsured: insurerIsInsuredSetting,
      },
    },
    validation: {
      isLoading: isLoadingPodft,
      form,
    },
    authReducer,
    helperReducer: {
      passportError,
      snilsError,
      addressValues,
    },
  } = state;

  return {
    hideBankInfo,
    userInfo: authReducer,
    passportError,
    snilsError,
    addressValues,
    scenario,
    isLoadingPodft,
    checkAccountNumbersError,
    childInsuredValues,
    stage,
    calculatorData,
    personType,
    hideInn,
    hideSnils,
    restrictions,
    productTerms,
    insurerIsInsuredSetting,
    isListBlackAddress: listBlackAddress,
    personBlockProps: getAgentPersonBlockSelector(state, formType),
    documentBlockProps: getAgentDocumentBlockSelector(state, formType),
    addressBlockProps: getAgentAddressBlockSelector(state, formType),
    bankBlockProps: getAgentBankBlockSelector(state, formType),
    addressLivingBlockProps: getAgentAddressLivingBlockSelector(state, formType),
    insSnilsBlockProps: getAgentInnSnilsBlockSelector(state, formType),
    contactBlockProps: getAgentContactBlockSelector(state, formType),
    documentBlockNotRFProps: getAgentDocumentBlockNotRFSelector(state, formType),
    addressBlockNotRFProps: getAgentAddressBlockNotRFSelector(state, formType),
    bankBlockNotRFProps: getAgentBankBlockNotRFSelector(state, formType),
    addressLivingBlockNotRFProps: getAgentAddressLivingBlockNotRFSelector(state, formType),
    contactBlockNotRFProps: getAgentContactBlockNotRFSelector(state, formType),
    relationBlockProps: getAgentRelationBlockSelector(state, formType),
    insuredIsSame: getInsuredIsSame(state),
    checkPassportCovid,
    errorValidationForm: form,
    mainRequestId: getMainRequestId(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  validateFieldValues: bindActionCreators(validateAgentFieldValues, dispatch),
  setAllTouchFields: bindActionCreators(setAgentAllTouchFields, dispatch),
  clearErrors: bindActionCreators(clearAgentErrors, dispatch),
  setStage: bindActionCreators(ownProps.setStage, dispatch),
  setInsuredIsSame: bindActionCreators(changeInsuredIsSame, dispatch),
  setDisableInsuredIsSame: bindActionCreators(changeDisableInsuredIsSame, dispatch),
  checkAgentAction: bindActionCreators(checkAgent, dispatch),
});

Insurer.propTypes = propTypes;
Insurer.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Insurer));
