import { createSelector } from 'reselect';
import { STRATEGY_FIELD_NAME } from 'components/_forms/_constructorForms/BasketCreatingForm/components/Strategy/fieldNames';

export const selectProductTypes = ({
  basketConstructor: { refs: { productTypes } },
}) => productTypes;
export const selectOptionType = ({
  basketConstructor: { refs: { productOptions } },
}) => productOptions;

export const selectMaxGuaranteeLevel = ({
  basketConstructor: { maxGuaranteeLevel },
}) => maxGuaranteeLevel;

export const selectProductTypeOptions = createSelector(
  selectProductTypes,
  (productTypes) => productTypes.map(({
    id,
    name,
    code,
    description,
  }) => ({
    key: id,
    value: code,
    text: name,
    desc: description,
  })),
);

export const selectProductTypeCode =
    ({ basketConstructor: { productTypeCode } }) => productTypeCode;

export const selectOnlyFavorite =
  ({ basketConstructor: { isOnlyFavorite } }) => isOnlyFavorite;

export const selectProductTypeByCode = createSelector(
  selectProductTypes,
  (_, code) => code,
  (productTypes, code) => productTypes.find((item) => item.code === code),
);

export const selectProductOptionOptions = createSelector(
  selectOptionType,
  (productOptions) => productOptions
    .map(({
      id,
      description,
      isDefault,
      name,
      isKu,
      isCoupon,
      descriptionFull,
      lookbackData,
      isAutocall,
      isMonoBarrier,
      assetLimit,
      isFlexRedemption,
      isFixRedemption,
      isCapitalProtected,
    }) => ({
      id,
      value: name,
      text: description,
      def: isDefault,
      isKu,
      isCoupon,
      descriptionFull,
      lookbackData,
      isAutocall,
      isMonoBarrier,
      assetLimit,
      isFlexRedemption,
      isFixRedemption,
      isCapitalProtected,
    })),
);

export const selectStrategyUuids = createSelector(
  ({ basketConstructor: { formCreating: { strategyUuids } } }) => strategyUuids,
  (strategyUuids) => strategyUuids,
);

export const selectStrategyForms = ({ basketConstructor: { formCreating: { strategies } } }) => (
  Object.values(strategies).filter(Boolean)
);

export const selectCurrencies = createSelector(
  ({ basketConstructor: { refs: { currencies } } }) => currencies,
  (currencies) => currencies,
);

export const selectProductTerms = createSelector(
  ({ basketConstructor: { refs: { productTerms } } }) => productTerms,
  (productTerms) => productTerms
    .map(({ id, term, refInsDateUnitValueNumber }) => ({
      value: term,
      text: term,
      key: id,
      refInsDateUnitValueNumber,
    })),
);

export const selectProductPayouts = createSelector(
  ({ basketConstructor: { refs: { productPayouts } } }) => productPayouts,
  (productPayouts) => productPayouts.map(({ id, value, code }) => ({
    value: code,
    text: value,
    key: id,
  })),
);

export const selectFormByUuid = (strategies, uuid) => strategies[uuid];

export const selectBarrierOption =
    ({ basketConstructor: { formCreating: { strategies, strategyUuids } } }) => (
      strategies[strategyUuids[0]]?.values[STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]
    );

export const selectedMainOptionType =
    ({ basketConstructor: { formCreating: { strategies, strategyUuids } } }) => (
      strategies[strategyUuids[0]]?.values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]
    );

export const selectedMainOption =
    ({ basketConstructor: { formCreating: { strategies, strategyUuids } } }) => (
      strategies[strategyUuids[0]]
    );

export const selectTermIdFirstForm = createSelector(
  ({
    basketConstructor: {
      formCreating: {
        strategies,
        strategyUuids,
      },
    },
  }) => strategies[strategyUuids[0]].values[STRATEGY_FIELD_NAME.TERM],
  selectProductTerms,
  (termId, terms) => terms
    .find(({ value }) => value === termId),
);

export const selectGuaranteedPayouts = (
  ({ basketConstructor: { formCreating: { guaranteedPayouts } } }) => guaranteedPayouts
);

export const getStrategies =
    // eslint-disable-next-line no-shadow
    ({ basketConstructor: { formCreating: { strategies } } }) => strategies;

export const selectMinInsuranceSumFormCreating = (
  ({ basketConstructor: { formCreating: { minInsuranceSum } } }) => minInsuranceSum
);

export const selectIsMinInsuranceSumClickedFormCreating = (
  ({
    basketConstructor: { formCreating: { isMinInsuranceSumClicked } },
  }) => isMinInsuranceSumClicked
);

export const selectMinInsuranceSumTables = (
  ({ basketConstructor: { minInsuranceSum } }) => minInsuranceSum
);

export const selectIsMinInsuranceSumClickedTables = (
  ({ basketConstructor: { isMinInsuranceSumClicked } }) => isMinInsuranceSumClicked
);

export const selectPartnersInsProductId = ({
  basketConstructor: { partnersInsProductId },
}) => Number(partnersInsProductId);

export const selectCalculatingStatus =
  ({ basketConstructor: { formCreating: { calculatingStatus } } }) => calculatingStatus;

export const selectCalculatingStatusMessage =
  ({ basketConstructor: { formCreating: { message } } }) => message;

export const selectProductGeneralOptionCode =
  ({ basketConstructor: { productOptionCode } }) => productOptionCode;

export const selectGuaranteeLevel = ({ basketConstructor: { guaranteeLevel } }) => guaranteeLevel;

export const selectCurrencyCode =
  ({ basketConstructor: { currencyCode } }) => currencyCode;

export const selectGuaranteeLevelError =
  ({ basketConstructor: { guaranteeLevelError } }) => guaranteeLevelError;

export const selectMaxFavoritesBaskets =
  ({ basketConstructor: { maxFavoritesBaskets } }) => maxFavoritesBaskets;

export const selectAmountFavoritesBasket =
  ({ basketConstructor: { amountFavoritesBasket } }) => amountFavoritesBasket;

export const selectConstructorTableControlData = createSelector(
  selectProductTypeOptions,
  selectProductOptionOptions,
  selectCurrencies,

  selectProductTypeCode,
  selectOnlyFavorite,
  selectGuaranteeLevel,
  selectProductGeneralOptionCode,
  selectCurrencyCode,
  selectGuaranteeLevelError,
  selectMaxFavoritesBaskets,
  selectAmountFavoritesBasket,
  (
    productTypeOptions,
    productOptionOptions,
    productCurrenciesOptions,
    productTypeCode,
    isOnlyFavorite,
    guaranteeLevel,
    generalOptionCode,
    currencyCode,
    guaranteeLevelError,
    maxFavoritesBaskets,
    amountFavoritesBasket,
  ) => ({
    productTypeOptions,
    productOptionOptions,
    productCurrenciesOptions,
    productTypeCode,
    isOnlyFavorite,
    guaranteeLevel,
    generalOptionCode,
    currencyCode,
    guaranteeLevelError,
    maxFavoritesBaskets,
    amountFavoritesBasket,
  }),
);

export const selectBasketAssets = ({ basketConstructor: { assets } }) => ({
  ...assets,
  data: assets.data
    .reduce((acc, asset) => {
      const assetArr = asset.code.split(' ');

      return ([
        ...acc,
        ...(asset.description ? [{
          name: asset.name,
          desc: asset.description,
          code: assetArr.length >= 2 ? assetArr[0] : asset.code,
          tvCode: asset.tvCode,
        }] : []),
      ]);
    }, [])
    .sort((ai, bi) => (ai.name < bi.name ? -1 : 1)),
});

export const selectDpPolicyExecutionUser = ({
  basketConstructor: {
    dpPolicyExecutionUser,
  },
}) => dpPolicyExecutionUser;

export const selectSettingsProduct = ({ basketConstructor: { settings } }) => settings;

export const selectIsValueLessThanOneHalfMillion =
  ({
    basketConstructor: {
      settings: { isValueLessThanOneHalfMillion },
    },
  }) => isValueLessThanOneHalfMillion;

export const selectCalculatedPersonBasket =
  ({ basketConstructor: { calculatedPersonBaskets } }) => calculatedPersonBaskets;

export const selectBasketFiltering = ({ basketConstructor: { filtering } }) => filtering;

export const selectBasketSorting = ({ basketConstructor: { sorting } }) => sorting;

export const selectReadyBasketSorting =
  ({ basketConstructor: { readyBasketsSorting } }) => readyBasketsSorting;

export const selectReadyBasketFiltering =
  ({ basketConstructor: { readyBasketsFiltering } }) => readyBasketsFiltering;

export const selectRegularBasketSorting =
  ({ basketConstructor: { regularBasketsSorting } }) => regularBasketsSorting;

export const selectRegularBasketFiltering =
  ({ basketConstructor: { regularBasketsFiltering } }) => regularBasketsFiltering;

export const selectIsLoadingCreatingForm =
    ({ basketConstructor: { formCreating: { isLoading } } }) => isLoading;

export const selectAvailableQuantityRequests =
  ({ basketConstructor: { availableQuantityRequests } }) => availableQuantityRequests;

export const selectReadyBaskets =
  ({ basketConstructor: { readyBaskets } }) => readyBaskets;

export const selectLoadingReadyAndRegularBasket = ({
  basketConstructor: {
    readyBaskets,
    regularBaskets,
  },
}) => readyBaskets.isTableLoading || regularBaskets.isTableLoading;

export const selectRegularBaskets = ({ basketConstructor: { regularBaskets } }) => regularBaskets;

export const selectStatusPopupWarningNegateCoupon =
  ({ basketConstructor: { isOpenPopupWarningNegateCoupon } }) => isOpenPopupWarningNegateCoupon;

export const selectWarningMessage = ({ basketConstructor: { warningMessage } }) => warningMessage;

export const selectJustNowExecutionBasketPayload =
  ({ basketConstructor: { justNowExecutionBasketPayload } }) => justNowExecutionBasketPayload;

export const selectIsExecutionLoading =
  ({ basketConstructor: { isExecutionLoading } }) => isExecutionLoading;

export const selectExecutionDpPolicyLoading =
  ({ basketConstructor: { isExecutionDpPolicyLoading } }) => isExecutionDpPolicyLoading;

export const selectPreExecutionDpPolicyLoading =
  ({ basketConstructor: { isPreExecutionDpPolicyLoading } }) => isPreExecutionDpPolicyLoading;

export const selectProductForm =
  ({ basketConstructor: { productForm } }) => productForm;

export const selectIsExecutionDpPolicyPopup =
  ({ basketConstructor: { isExecutionDpPolicyPopup } }) => isExecutionDpPolicyPopup;

export const selectIsExecutionDpPolicySuccessPopup =
  ({ basketConstructor: { isExecutionDpPolicySuccessPopup } }) => isExecutionDpPolicySuccessPopup;

export const selectSelectedGarantSchedules =
    ({
      basketConstructor: {
        selected: { insLineIszCalcBasketGarantSchedule },
      },
    }) => insLineIszCalcBasketGarantSchedule;
export const selectRegularBasketsPagination =
    ({ basketConstructor: { regularBaskets: { limit, offset } } }) => ({ limit, offset });

export const selectReadyBasketsPagination =
    ({ basketConstructor: { readyBaskets: { limit, offset } } }) => ({ limit, offset });

const prepareFilters = (filters) => Object.entries(filters)
  .reduce((acc, [name, value]) => {
    if (value) {
      return [
        ...acc,
        {
          name,
          value,
          isExact: false,
        }];
    }

    return acc;
  }, []);

const prepareSorting = (sort) => Object.values(sort)
  .sort((a, b) => a.timestamp - b.timestamp)
  .reduce((acc, payload) => {
    if (!payload.type) {
      return acc;
    }

    return [
      ...acc,
      {
        name: payload.name,
        type: payload.type,
      }];
  }, []);

export const selectCalculatedBasketsPagination =
    ({ basketConstructor: { calculatedPersonBaskets: { limit, offset } } }) => ({ limit, offset });

export const selectRegularBasketsFilters =
    ({
      basketConstructor: {
        regularBasketsFiltering: {
          regularBasketsFilter,
        },
      },
    }) => prepareFilters(regularBasketsFilter);

export const selectRegularBasketsSorting =
    ({ basketConstructor: { regularBasketsSorting } }) => prepareSorting(regularBasketsSorting);

export const selectReadyBasketsFilters =
    ({
      basketConstructor: {
        readyBasketsFiltering: {
          readyBasketsFilter,
        },
      },
    }) => prepareFilters(readyBasketsFilter);

export const selectReadyBasketsSorting =
    ({ basketConstructor: { readyBasketsSorting } }) => prepareSorting(readyBasketsSorting);

export const selectCalculatedBasketsFilters =
    ({ basketConstructor: { filtering: { filter } } }) => prepareFilters(filter);

export const selectCalculatedBasketsSorting =
    ({ basketConstructor: { sorting } }) => prepareSorting(sorting);

export const selectSelectedBasketId =
    ({
      basketConstructor: {
        selected: { id },
      },
    }) => id;
