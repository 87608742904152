import { BLOCK_NAMES } from 'consts';
import {
  getStartOfDayDate,
  initStateLivingAddress,
  initStateLivingAddressNotRF,
  checkLivingAddress,
} from 'helpers';
import _ from 'lodash';
import {
  SET_TOUCH_FIELD,
  VALIDATION_FIELD,
  CHANGE_FIELD_VALUE,
  SET_CURRENT_AGENT_TYPE,
  CHANGE_INSURED_IS_SAME,
  SET_ALL_TOUCHED_FIELD,
  CLEAR_AGENT_ERRORS,
  CLEAR_AGENT_DATA,
  CHANGE_DISABLE_INSURED_IS_SAME,
  COPY_CONTRACT,
  SET_ADDRESS_DATA_AGENT,
  CLEAR_BANK_BLOCKS,
} from '../types';
import { COPY_KSZ_CONTRACT, FILL_TEST_DATA, FILL_TEST_DATA_INSURED_KSZ } from '../../kszProduct/types';
import { FILL_TEST_DATA_INSURER_NSZ, FILL_TEST_DATA_INSURED_NSZ } from '../../nszProduct/types';

const INITIAL_BLOCK = {
  [BLOCK_NAMES.personBlock]: {},
  [BLOCK_NAMES.documentBlock]: {},
  [BLOCK_NAMES.addressBlock]: {},
  [BLOCK_NAMES.addressBlockLiving]: {},
  [BLOCK_NAMES.bankBlock]: {},
  [BLOCK_NAMES.innSnilsBlock]: {},
  [BLOCK_NAMES.contactBlock]: {},
  [BLOCK_NAMES.relationBlock]: {},
  [BLOCK_NAMES.documentBlockNotRF]: {},
  [BLOCK_NAMES.addressBlockNotRF]: {},
  [BLOCK_NAMES.bankBlockNotRF]: {},
  [BLOCK_NAMES.addressBlockLivingNotRF]: {},
  [BLOCK_NAMES.contactBlockNotRF]: {},
};

const INITIAL_VALUES_BLOCK = {
  ...INITIAL_BLOCK,
  [BLOCK_NAMES.addressBlockLiving]: {
    livingAddressIsSame: true,
    countryLiving: '',
    indexLiving: '',
    republicLiving: '',
    areaLiving: '',
    cityLiving: '',
    streetLiving: '',
    houseLiving: '',
    houseNumberLiving: '',
    apartmentLiving: '',
    fiasIdLiving: '',
    kladIdLiving: '',
    addressFiasLiving: {},
    isManualEnterLiving: false,
  },
  [BLOCK_NAMES.addressBlock]: {
    country: '',
    index: '',
    republic: '',
    area: '',
    city: '',
    street: '',
    house: '',
    houseNumber: '',
    apartment: '',
    fiasId: '',
    kladId: '',
    addressFias: {},
    isManualEnter: false,
  },
  [BLOCK_NAMES.personBlock]: {
    lastName: '',
    gender: null,
    firstName: '',
    patronymic: '',
    birthDate: '',
    birthPlace: '',
    citizenship: 'Россия',
    isResidentRF: true,
    availableForNonResident: false,
    taxResidenceCountry: 'Россия',
  },
  [BLOCK_NAMES.documentBlock]: {
    documentType: 1,
    serial: '',
    passportNumber: '',
    issuedBy: '',
    when: '',
    validUntil: '',
    kp: '',
    issuedByAccepted: false,
  },
  [BLOCK_NAMES.innSnilsBlock]: {
    inn: '',
    snils: '',
  },
  [BLOCK_NAMES.bankBlock]: {
    bic: '',
    bankName: '',
    corAccount: '',
    bankInn: '',
    kpp: '',
    curAccount: '',
  },
  [BLOCK_NAMES.contactBlock]: {
    mobilePhone: '',
    homePhone: '',
    workPhone: '',
    contactPhone: '',
    email: '',
  },
  [BLOCK_NAMES.relationBlock]: {
    relationship: null,
  },
  [BLOCK_NAMES.addressBlockLivingNotRF]: {
    livingAddressIsSame: true,
    countryLiving: '',
    indexLiving: '',
    republicLiving: '',
    areaLiving: '',
    cityLiving: '',
    streetLiving: '',
    houseLiving: '',
    houseNumberLiving: '',
    apartmentLiving: '',
    isManualEnterLiving: true,
  },
  [BLOCK_NAMES.addressBlockNotRF]: {
    country: '',
    index: '',
    republic: '',
    area: '',
    city: '',
    street: '',
    house: '',
    houseNumber: '',
    apartment: '',
    isManualEnter: true,
  },
  [BLOCK_NAMES.documentBlockNotRF]: {
    documentType: 6,
    passportNumber: '',
    issuedBy: '',
    when: '',
    issuedByAccepted: false,
  },
  [BLOCK_NAMES.bankBlockNotRF]: {
    isBankRU: true,
    bankInnSwift: '',
    ibanAccountNumber: '',
    swift: '',
    currency: 'RUB',
    recipientFullName: '',
    recipientAddress: '',
    ibBankSwift: '',
    ibAccountNum: '',
    ibRuAccountNum: '',
    intermediaryBank: false,
  },
  [BLOCK_NAMES.contactBlockNotRF]: {
    mobilePhone: '',
    homePhone: '',
    workPhone: '',
    contactPhone: '',
    email: '',
  },
};

const INITIAL_AGENT_VALUE = {
  values: INITIAL_VALUES_BLOCK,
  errors: INITIAL_BLOCK,
  touched: INITIAL_BLOCK,
};

const INITIAL_STATE = {
  insured: INITIAL_AGENT_VALUE,
  insurer: INITIAL_AGENT_VALUE,
  insuredChild: INITIAL_AGENT_VALUE,
  insuredIsSame: true,
  disableInsuredIsSame: false,
};

const combineValues = (initValues, incomeValues) => (
  Object.entries(initValues).reduce((acc, item) => ({
    ...acc,
    [item[0]]: Object.keys(item[1]).reduce((includeAcc, includeItem) => ({
      ...includeAcc,
      [includeItem]: _.isNull(incomeValues[includeItem]) ? '' : incomeValues[includeItem],
    }), {}),
  }), {})
);

const copyChangeData = (values, isRebuild = true) => (isRebuild ? ({
  [BLOCK_NAMES.personBlock]: {
    ...values[BLOCK_NAMES.personBlock],
    birthDate: getStartOfDayDate(values[BLOCK_NAMES.personBlock].birthDate),
  },
  [BLOCK_NAMES.documentBlock]: {
    ...values[BLOCK_NAMES.documentBlock],
    when: getStartOfDayDate(values[BLOCK_NAMES.documentBlock].when),
    validUntil: values[BLOCK_NAMES.documentBlock].validUntil
      ? getStartOfDayDate(values[BLOCK_NAMES.documentBlock].validUntil)
      : null,
  },
  [BLOCK_NAMES.addressBlock]: {
    ...values[BLOCK_NAMES.addressBlock],
    isManualEnter: !values[BLOCK_NAMES.addressBlock].fiasId,
    republic: values[BLOCK_NAMES.addressBlock].republic || values[BLOCK_NAMES.addressBlock].city,
  },
  [BLOCK_NAMES.addressBlockLiving]: {
    ...values[BLOCK_NAMES.addressBlockLiving],
    isManualEnterLiving: !checkLivingAddress({
      ...values[BLOCK_NAMES.addressBlockLiving],
      ...values[BLOCK_NAMES.addressBlock],
    }) && !values[BLOCK_NAMES.addressBlockLiving].fiasIdLiving,
    republicLiving: values[BLOCK_NAMES.addressBlockLiving].republicLiving
      || values[BLOCK_NAMES.addressBlockLiving].cityLiving,
    livingAddressIsSame: checkLivingAddress({
      ...values[BLOCK_NAMES.addressBlockLiving],
      ...values[BLOCK_NAMES.addressBlock],
    }),
    ...(checkLivingAddress({
      ...values[BLOCK_NAMES.addressBlockLiving],
      ...values[BLOCK_NAMES.addressBlock],
    })
      && initStateLivingAddress),
  },
  [BLOCK_NAMES.addressBlockNotRF]: {
    ...values[BLOCK_NAMES.addressBlockNotRF],
    isManualEnter: true,
    republic: values[BLOCK_NAMES.addressBlockNotRF].republic ||
      values[BLOCK_NAMES.addressBlockNotRF].city,
  },
  [BLOCK_NAMES.addressBlockLivingNotRF]: {
    ...values[BLOCK_NAMES.addressBlockLivingNotRF],
    isManualEnterLiving: true,
    republicLiving: values[BLOCK_NAMES.addressBlockLivingNotRF].republicLiving
      || values[BLOCK_NAMES.addressBlockLivingNotRF].cityLiving,
    livingAddressIsSame: checkLivingAddress({
      ...values[BLOCK_NAMES.addressBlockLivingNotRF],
      ...values[BLOCK_NAMES.addressBlockNotRF],
    }),
    ...(checkLivingAddress({
      ...values[BLOCK_NAMES.addressBlockLivingNotRF],
      ...values[BLOCK_NAMES.addressBlockNotRF],
    })
      && initStateLivingAddressNotRF),
  },
  [BLOCK_NAMES.documentBlockNotRF]: {
    ...values[BLOCK_NAMES.documentBlockNotRF],
    when: getStartOfDayDate(values[BLOCK_NAMES.documentBlockNotRF].when),
    validUntil: values[BLOCK_NAMES.documentBlockNotRF].validUntil
      ? getStartOfDayDate(values[BLOCK_NAMES.documentBlockNotRF].validUntil)
      : null,
  },
  [BLOCK_NAMES.bankBlockNotRF]: {
    ...values[BLOCK_NAMES.bankBlockNotRF],
    isBankRU: !values[BLOCK_NAMES.bankBlockNotRF].swift,
    intermediaryBank: Boolean(values[BLOCK_NAMES.bankBlockNotRF].ibBankSwift),
  },
}) : {});

const fillTestData = (testData, isInsured) => {
  const {
    lastName,
    gender,
    firstName,
    patronymic,
    birthDate,
    birthPlace,
    documentType,
    serial,
    passportNumber,
    issuedBy,
    when,
    validUntil,
    kp,
    issuedByAccepted,
    addressFias,
    country,
    index,
    republic,
    area,
    city,
    street,
    house,
    houseNumber,
    apartment,
    fiasId,
    kladId,
    mobilePhone,
    homePhone,
    workPhone,
    contactPhone,
    email,
    bic,
    bankName,
    corAccount,
    bankInn,
    kpp,
    curAccount,
    inn,
    snils,
    livingAddressIsSame,
    countryLiving,
    indexLiving,
    republicLiving,
    areaLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    houseNumberLiving,
    apartmentLiving,
    fiasIdLiving,
    kladIdLiving,
    addressFiasLiving,
    isManualEnterLiving,
    isBankRU,
    bankInnSwift,
    ibanAccountNumber,
    swift,
    currency,
    recipientFullName,
    recipientAddress,
    ibBankSwift,
    ibAccountNum,
    ibRuAccountNum,
    intermediaryBank,
    citizenship,
    isResidentRF,
    availableForNonResident,
    taxResidenceCountry,
  } = testData;

  const personBlock = {
    lastName,
    gender,
    firstName,
    patronymic,
    birthDate,
    birthPlace,
    citizenship,
    isResidentRF,
    availableForNonResident,
    taxResidenceCountry,
  };

  const documentBlock = {
    documentType,
    serial,
    passportNumber,
    issuedBy,
    when,
    validUntil,
    kp,
    issuedByAccepted,
  };

  const addressBlock = {
    isManualEnter: true,
    addressFias,
    country,
    index,
    republic,
    area,
    city,
    street,
    house,
    houseNumber,
    apartment,
    fiasId,
    kladId,
  };

  const addressBlockLiving = {
    livingAddressIsSame,
    countryLiving,
    indexLiving,
    republicLiving,
    areaLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    houseNumberLiving,
    apartmentLiving,
    fiasIdLiving,
    kladIdLiving,
    addressFiasLiving,
    isManualEnterLiving,
  };

  const contactBlock = {
    mobilePhone,
    homePhone,
    workPhone,
    contactPhone,
    email,
  };

  const bankBlock = {
    bic,
    bankName,
    corAccount,
    bankInn,
    kpp,
    curAccount,
  };

  const documentBlockNotRF = {
    documentType,
    passportNumber,
    issuedBy,
    when,
    validUntil,
    issuedByAccepted,
  };

  const addressBlockNotRF = {
    isManualEnter: true,
    country,
    index,
    republic,
    area,
    city,
    street,
    house,
    houseNumber,
    apartment,
  };

  const addressBlockLivingNotRF = {
    livingAddressIsSame,
    countryLiving,
    indexLiving,
    republicLiving,
    areaLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    houseNumberLiving,
    apartmentLiving,
    isManualEnterLiving: true,
  };

  const contactBlockNotRF = {
    mobilePhone,
    homePhone,
    workPhone,
    contactPhone,
    email,
  };

  const bankBlockNotRF = {
    isBankRU,
    bankInnSwift,
    ibanAccountNumber,
    swift,
    currency,
    recipientFullName,
    recipientAddress,
    ibBankSwift,
    ibAccountNum,
    ibRuAccountNum,
    intermediaryBank,
  };

  const innSnilsBlock = {
    inn,
    snils,
  };

  const relationBlock = {
    relationship: 1,
  };

  return {
    personBlock,
    documentBlock,
    addressBlock,
    contactBlock,
    bankBlock,
    innSnilsBlock,
    addressBlockLiving,
    documentBlockNotRF,
    addressBlockNotRF,
    contactBlockNotRF,
    bankBlockNotRF,
    addressBlockLivingNotRF,
    ...(isInsured && { relationBlock }),
  };
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FILL_TEST_DATA_INSURED_NSZ:
    case FILL_TEST_DATA_INSURED_KSZ: {
      const { data, insurerIsInsured, insuredChild: testInsuredChild } = payload;
      const insuredData = fillTestData(data, true);
      const { insured, insuredChild } = state;

      if (insurerIsInsured) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        insured: {
          ...insured,
          values: {
            ...insured.values,
            ...insuredData,
          },
          errors: INITIAL_BLOCK,
        },
        insuredChild: {
          ...insuredChild,
          ...(testInsuredChild && testInsuredChild),
          errors: INITIAL_BLOCK,
        },
        insuredIsSame: false,
      };
    }

    case SET_ADDRESS_DATA_AGENT: {
      const { data, form, blockName } = payload;

      return {
        ...state,
        [form]: {
          ...state[form],
          values: {
            ...state[form].values,
            [blockName]: {
              ...state[form].values[blockName],
              ...data,
            },
          },
        },
      };
    }

    case FILL_TEST_DATA:
    case FILL_TEST_DATA_INSURER_NSZ: {
      const insurerData = fillTestData(payload);
      const { insurer } = state;

      return {
        ...state,
        insurer: {
          ...insurer,
          values: {
            ...insurer.values,
            ...insurerData,
          },
        },
      };
    }

    case CHANGE_FIELD_VALUE:
      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          values: {
            ...state[payload.form].values,
            [payload.blockName]: {
              ...state[payload.form].values[payload.blockName],
              [payload.name]: payload.value,
            },
          },
        },
      };

    case SET_TOUCH_FIELD:
      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          touched: {
            ...state[payload.form].touched,
            [payload.blockName]: {
              ...state[payload.form].touched[payload.blockName],
              [payload.name]: payload.value,
            },
          },
        },
      };

    case VALIDATION_FIELD: {
      return {
        ...state,
        [payload.form]: {
          ...state[payload.form],
          errors: {
            ...state[payload.form].errors,
            ...Object.entries(payload.errors).reduce((acc, item) => {
              if (JSON.stringify(item[1]) !== JSON.stringify(state[payload.form].errors[item[0]])) {
                return {
                  ...acc,
                  [item[0]]: item[1],
                };
              }

              return {
                ...acc,
              };
            }, { }),
          },
        },
      };
    }

    case SET_CURRENT_AGENT_TYPE:
      return {
        ...state,
        currentAgentType: payload,
      };

    case CHANGE_INSURED_IS_SAME:
      return {
        ...state,
        insuredIsSame: payload,
      };

    case CHANGE_DISABLE_INSURED_IS_SAME:
      return {
        ...state,
        disableInsuredIsSame: payload,
      };

    case COPY_KSZ_CONTRACT.success:
    case COPY_CONTRACT.success: {
      const insurerValues = combineValues(state.insurer.values, payload.insurer);
      const insuredValues = combineValues(state.insured.values, payload.insured);
      const childInsuredValues = payload.childInsured
        ? combineValues(state.insuredChild.values, payload.childInsured)
        : INITIAL_AGENT_VALUE;

      return {
        ...state,
        insurer: {
          ...state.insurer,
          values: {
            ...insurerValues,
            ...copyChangeData(insurerValues),
          },
        },
        insured: {
          ...state.insured,
          values: {
            ...insuredValues,
            ...copyChangeData(insuredValues),
          },
        },
        insuredChild: {
          ...state.insuredChild,
          values: {
            ...childInsuredValues,
            ...copyChangeData(childInsuredValues, Boolean(payload.childInsured)),
          },
        },
      };
    }

    case SET_ALL_TOUCHED_FIELD:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          touched: Object.entries(state[payload].values).reduce((acc, item) => ({
            ...acc,
            [item[0]]: Object.keys(item[1]).reduce((includeAcc, includeItem) => ({
              ...includeAcc,
              [includeItem]: true,
            }), {}),
          }), {}),
        },
      };

    case CLEAR_AGENT_ERRORS:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          errors: INITIAL_BLOCK,
        },
      };

    case CLEAR_AGENT_DATA:
      return INITIAL_STATE;

    case CLEAR_BANK_BLOCKS: return {
      ...state,
      [payload.form]: {
        ...state[payload.form],
        values: {
          ...state[payload.form].values,
          bankBlock: INITIAL_STATE.insurer.values[BLOCK_NAMES.bankBlock],
          bankBlockNotRF: INITIAL_STATE.insurer.values[BLOCK_NAMES.bankBlockNotRF],
        },
      },
    };

    default:
      return state;
  }
};
