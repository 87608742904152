import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LANG_DICTIONARY } from 'consts';
import { SelectInput, TextInput } from 'components/_deprecated';
import PropTypes from 'prop-types';
import { DEFAULT_COUNTRY } from 'config';
import { getOptionsForSelect } from 'helpers';
import { connect } from 'react-redux';
import { withCustomRouter } from 'HOC';

const propTypes = {
  inputProps: PropTypes.object,
  isLivingAddress: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  payloadRequestCheckCode: PropTypes.object,
  isListBlackAddress: PropTypes.bool,
  countries: PropTypes.object,
};

const defaultProps = {
  inputProps: {},
  isLivingAddress: false,
  inputs: [],
  index: 0,
  payloadRequestCheckCode: {},
  isListBlackAddress: false,
  countries: {},
};

const {
  INDEX,
  REPUBLIC,
  AREA,
  CITY,
  STREET,
  HOUSE,
  HOUSE_NUMBER,
  APARTMENT,
} = LANG_DICTIONARY;

const AddressBlockNotRF = ({
  inputProps,
  isLivingAddress,
  inputs,
  countries,
}) => {
  const living = isLivingAddress ? 'Living' : '';
  const countryList = countries.countries || [];

  const getRepublicValue = () => {
    const { values } = inputProps;

    return values[`republic${living}`] || '';
  };

  const getInputProps = (name) => ({
    ...inputProps,
    name: `${name}${living}`,
  });

  const getTotalValue = () => {
    const { values } = inputProps;
    const noShowFieldInTotal = [`index${living}`];
    const arrayValue = inputs.map((item) => !noShowFieldInTotal.includes(item) && values[item]);

    return arrayValue
      .filter(Boolean)
      .join(', ');
  };

  return (
    <Container>
      <Row>
        <Col>
          <TextInput
            disabled
            value={getTotalValue()}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="12">
          <SelectInput
            name={`country${living}`}
            options={
              getOptionsForSelect(countryList.filter((item) => inputProps.values.isResidentRF || item !== 'Россия')
                .sort((a, d) => a.localeCompare(d)))
            }
            required
            {...getInputProps('country')}
            value={inputProps.values[`country${living}`] || DEFAULT_COUNTRY}
          />
        </Col>
        <Col md="3" sm="12">
          <TextInput
            placeholder={INDEX}
            required
            {...getInputProps('index')}
          />
        </Col>
        <Col md="3" sm="12">
          <TextInput
            placeholder={REPUBLIC}
            name={`republic${living}`}
            value={getRepublicValue()}
            {...getInputProps(`republic${living}`)}
          />
        </Col>
        <Col md="3" sm="12" className="margin-none">
          <TextInput
            placeholder={AREA}
            {...getInputProps('area')}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="12">
          <TextInput
            required
            placeholder={CITY}
            {...getInputProps('city')}
          />
        </Col>
        <Col md="3" sm="12">
          <TextInput
            placeholder={STREET}
            {...getInputProps('street')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={HOUSE}
            required
            {...getInputProps('house')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={HOUSE_NUMBER}
            {...getInputProps('houseNumber')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={APARTMENT}
            maxLength="10"
            {...getInputProps('apartment')}
          />
        </Col>
      </Row>
    </Container>
  );
};

AddressBlockNotRF.defaultProps = defaultProps;
AddressBlockNotRF.propTypes = propTypes;

function mapStateToProps(state) {
  const {
    helperReducer: {
      countries,
    },
  } = state;

  return {
    countries,
  };
}

export default withCustomRouter(connect(
  mapStateToProps, {},
)(AddressBlockNotRF));
