export default {
  bankInnSwift: '123456789012345',
  ibanAccountNumber: 'DE89370400440532013000SRDFG5W23J34',
  swift: 'ABCD1234567',
  currency: 'EUR',
  recipientFullName: 'Pierre Olivier Charles Jean',
  recipientAddress: '12 Rue de la Paix, 75002 Paris, France',
  ibBankSwift: 'XYZ9876543Z',
  ibAccountNum: 'GB29NWBK60161331926819SPOI34523232',
  ibRuAccountNum: 'MONEYTRANSFER2023XYZ',
  intermediaryBank: true,
};
