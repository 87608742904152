import { INPUT_NAMES } from 'consts';

const {
  bankBic,
  bankName,
  bankInn,
  bankKpp,
  correctionAccount,
  checkingAccount,
  relationship,
  bankInnSwift,
  ibanAccountNumber,
  swift,
  currency,
  recipientFullName,
  recipientAddress,
  ibBankSwift,
  ibAccountNum,
  ibRuAccountNum,
} = INPUT_NAMES;

const INSURER_FIELDS = [
  'lastName',
  'firstName',
  'patronymic',
  'documentType',
  'serial',
  'passportNumber',
  'birthDate',
  'birthPlace',
  'issuedBy',
  'issuedByAccepted',
  'when',
  'validUntil',
  'kp',
  'gender',
  'addressFias',
  'country',
  'index',
  'republic',
  'area',
  'city',
  'street',
  'house',
  'houseNumber',
  'apartment',
  'inn',
  'snils',
  'mobilePhone',
  'homePhone',
  'workPhone',
  'contactPhone',
  'email',
  'livingAddressIsSame',
  'isResidentRF',
  'citizenship',
  'taxResidenceCountry',
];

const CHILD_INSURED_FIELDS = INSURER_FIELDS.map((item) => `child${item}`);

const BENEFICIARY_FIELDS = [
  ...INSURER_FIELDS,
  'relationships',
  'insuredSum',
];

const INSURED_FIELDS_LIVING = [
  'addressFiasLiving',
  'countryLiving',
  'indexLiving',
  'republicLiving',
  'areaLiving',
  'cityLiving',
  'streetLiving',
  'houseLiving',
  'houseNumberLiving',
  'apartmentLiving',
];

const CHILD_INSURED_FIELDS_LIVING = INSURED_FIELDS_LIVING.map((item) => `child${item}`);

const DOCUMENT_FIELDS = [
  'documentType',
  'serial',
  'passportNumber',
  'issuedBy',
  'issuedByAccepted',
  'when',
  'validUntil',
  'kp',
  'inn',
  'snils',
];

const ADDRESS_FIELDS = [
  'country',
  'index',
  'republic',
  'area',
  'city',
  'street',
  'house',
  'houseNumber',
  'apartment',
  'isSameAddress',
  'countryLiving',
  'indexLiving',
  'republicLiving',
  'areaLiving',
  'cityLiving',
  'streetLiving',
  'houseLiving',
  'houseNumberLiving',
  'apartmentLiving',
  'fiasId',
  'kladId',
  'fiasIdLiving',
  'kladIdLiving',
];

const CONTACT_FIELDS = [
  'mobilePhone',
  'homePhone',
  'workPhone',
  'contactPhone',
  'email',
];

const BANK_FIELDS = [
  bankBic,
  bankName,
  bankInn,
  bankKpp,
  correctionAccount,
  checkingAccount,
  bankInnSwift,
  ibanAccountNumber,
  swift,
  currency,
  recipientFullName,
  recipientAddress,
  ibBankSwift,
  ibAccountNum,
  ibRuAccountNum,
];

const RELATIONSHIP_FIELD = [
  relationship,
];

const INSURED_FIELDS = [
  ...INSURER_FIELDS.filter((item) => !(item in [
    'isResidentRF',
    'citizenship',
    'taxResidenceCountry',
  ])),
  ...ADDRESS_FIELDS,
  ...INSURED_FIELDS_LIVING,
  ...BANK_FIELDS.filter((item) => !(item in [
    bankInnSwift,
    ibanAccountNumber,
    swift,
    currency,
    recipientFullName,
    recipientAddress,
    ibBankSwift,
    ibAccountNum,
    ibRuAccountNum,
  ])),
  'relationship',
];

export {
  INSURER_FIELDS,
  INSURED_FIELDS,
  BENEFICIARY_FIELDS,
  INSURED_FIELDS_LIVING,
  DOCUMENT_FIELDS,
  ADDRESS_FIELDS,
  CONTACT_FIELDS,
  BANK_FIELDS,
  RELATIONSHIP_FIELD,
  CHILD_INSURED_FIELDS,
  CHILD_INSURED_FIELDS_LIVING,
};
