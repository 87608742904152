import React from 'react';
import { LANG_DICTIONARY, DATE_PICKER_FORMAT, MASK } from 'consts';
import PropTypes from 'prop-types';
import {
  FlexTable, CustomCheckBox, InputField, CalendarIcon,
} from 'components';
import { block } from 'bem-cn';
import { onlyNumbers } from 'helpers';
import DatePicker from 'react-datepicker';
import template from './template';
import './styles.scss';

const { birthDateMask } = MASK;
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  changeProduct: PropTypes.func,
};

const defaultProps = {
  data: [],
  changeProduct: () => null,
};

const b = block('admin-products-table');
const {
  FULL_TREE_BRANCH,
  NAMING_BRANCH,
  ACTIVE_DISTRIBUTING,
  START_PRODUCTS_SALE,
  ENDING_PRODUCTS_SALE,
} = LANG_DICTIONARY;

const FlexTableAdminProducts = ({ data, changeProduct }) => {
  const headers = [
    FULL_TREE_BRANCH,
    NAMING_BRANCH,
    ACTIVE_DISTRIBUTING,
    START_PRODUCTS_SALE,
    ENDING_PRODUCTS_SALE,
  ];

  const handleChangeDate = (index, name) => (e) => {
    changeProduct(name, e, index);
  };
  const rebuildData = data.map(({
    fullTreeName,
    branchName,
    active,
    dataStart,
    dataEnd,
    id,
    dateStartHave,
    dateEndtHave,
  }, index) => template(headers, [
    fullTreeName,
    branchName,
    <CustomCheckBox
      checked={active}
      onChange={changeProduct}
      name="active"
      index={index}
    />,
    <div className={b('calendar')}>
      <DatePicker
        locale="ru"
        selected={dataStart}
        onChangeRaw={(e) => changeProduct('dateStartHave', onlyNumbers(e.target.value), index)}
        onBlur={() => (dateStartHave.length ? null : changeProduct('dataStart', null, index))}
        onChange={handleChangeDate(index, 'dataStart')}
        dateFormat={DATE_PICKER_FORMAT}
        name="dateStartHavePicker"
        maxDate={dataEnd}
        customInput={(
          <InputField
            value={dataStart}
            mask={birthDateMask}
            name="dateStartHave"
          />
      )}
      />
      <CalendarIcon />
    </div>,
    <div className={b('calendar')}>
      <DatePicker
        locale="ru"
        selected={dataEnd}
        name="dataEndPicker"
        onChange={handleChangeDate(index, 'dataEnd')}
        onChangeRaw={(e) => changeProduct('dateEndtHave', onlyNumbers(e.target.value), index)}
        onBlur={() => (dateEndtHave.length ? null : changeProduct('dataEnd', null, index))}
        dateFormat={DATE_PICKER_FORMAT}
        minDate={dataStart}
        customInput={(
          <InputField
            value={dataStart}
            mask={birthDateMask}
            name="dataEnd"
          />
      )}
      />
      <CalendarIcon />
    </div>,
    `${id}adminProduct`,
  ]));

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableAdminProducts.propTypes = propTypes;
FlexTableAdminProducts.defaultProps = defaultProps;
export default FlexTableAdminProducts;
