import {
  put,
  throttle,
} from 'redux-saga/effects';
import {
  GET_CURRENCIES,
  GET_OPTION_TYPES,
  GET_PRODUCT_SETTINGS,
  CHANGE_GUARANTEE_LEVEL,
} from 'redux/basketConstructor/types';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';
import { GET_OPTION_TYPES_RESTRICTIONS } from '../types';

export const DEFAULT_THROTTLE_VALUE = 1000;
export const MIN_THROTTLE_VALUE = 500;

export function* getCurrencies({ payload: { productId, refProductTypeCode } }) {
  yield put({ type: GET_CURRENCIES.start });
  const { data } = yield apiCall({
    type: 'GET',
    url: API.GET_BASKET_CURRENCY_TYPES,
    query: { productId, refProductTypeCode },
  });
  yield put({ type: GET_CURRENCIES.success, payload: data });

  return data;
}

export function* getOptionTypes({ payload: { productId, refProductTypeCode } }) {
  try {
    yield put({ type: GET_OPTION_TYPES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_OPTION_TYPES,
      query: { productId, refProductTypeCode },
    });
    yield put({ type: GET_OPTION_TYPES.success, payload: data });

    return data;
  } catch (e) {
    yield put({ type: GET_OPTION_TYPES.fail, payload: getError(e) });

    return [];
  }
}

export function* getOptionTypesRestrictions(
  {
    payload: {
      refIszOptionTypeName, term, couponFrequency, strategyUuid, refInsDateUnitValue,
    },
  },
) {
  try {
    yield put({ type: GET_OPTION_TYPES_RESTRICTIONS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_OPTION_TYPES_RESTRICTIONS,
      query: {
        refIszOptionTypeName,
        term,
        couponFrequency,
        refInsDateUnitValue,
      },
    });
    yield put({ type: GET_OPTION_TYPES_RESTRICTIONS.success, payload: { data, strategyUuid } });

    return data;
  } catch (e) {
    yield put({ type: GET_OPTION_TYPES_RESTRICTIONS.fail, payload: getError(e) });

    return [];
  }
}

function* getConstructorSettings({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_SETTINGS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONSTRUCTOR_SETTINGS,
      query: { partnersInsProductId: payload.productId, currencyCode: payload.currencyCode },
    });
    yield put({ type: GET_PRODUCT_SETTINGS.success, payload: data });
    yield put({ type: CHANGE_GUARANTEE_LEVEL, payload: data.minGuaranteeLevel });
  } catch (e) {
    yield put({ type: GET_PRODUCT_SETTINGS.fail, error: getError(e) });
  }
}

export function* commonSaga() {
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_OPTION_TYPES.request, getOptionTypes);
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_OPTION_TYPES_RESTRICTIONS.request,
    getOptionTypesRestrictions,
  );
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_CURRENCIES.request, getCurrencies);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_PRODUCT_SETTINGS.request, getConstructorSettings);
}
