import { PRODUCT_VERSION } from 'consts';
import insurer from '../commonData/insurer';
import insurerNotRF from '../commonData/insurerNotRF';
import bankData from '../commonData/bankData';
import bankDataNotRF from '../commonData/bankDataNotRF';
import insured from '../commonData/insured';
import beneficiaries from '../commonData/beneficiaries';
import insuredChild from '../commonData/insuredChild';

const dataToScenario = (scenario, isResidentRF, isBankRU) => {
  const insurerData = isResidentRF ? insurer : insurerNotRF;
  const bank = isBankRU ? bankData : bankDataNotRF;

  const data = {
    [PRODUCT_VERSION.nsz_1]: {
      insurer: {
        ...insurerData,
        ...bank,
      },
      insured: {
        ...insured,
        ...bankData,
      },
      beneficiaries,
    },
    [PRODUCT_VERSION.nsz_2]: {
      insurer: {
        ...insurerData,
        ...bank,
      },
      insured: {
        ...insured,
        ...bankData,
      },
      beneficiaries,
      insuredChild,
    },
    [PRODUCT_VERSION.nsz_3]: {
      insurer: {
        ...insurerData,
        ...bank,
      },
      insured: {
        ...insured,
        ...bankData,
      },
      beneficiaries,
    },
  };

  return data[scenario];
};

export default dataToScenario;
