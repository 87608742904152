export default {
  addressFias: {
    data: {
      postal_code: '353040',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Южный',
      region_fias_id: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
      region_kladr_id: '2300000000000',
      region_iso_code: 'RU-KDA',
      region_with_type: 'Краснодарский край',
      region_type: 'край',
      region_type_full: 'край',
      region: 'Краснодарский',
      area_fias_id: '4fc852b0-5e19-4729-9428-fdb9750d7929',
      area_kladr_id: '2300500000000',
      area_with_type: 'Белоглинский р-н',
      area_type: 'р-н',
      area_type_full: 'район',
      area: 'Белоглинский',
      city_fias_id: '',
      city_kladr_id: '',
      city_with_type: '',
      city_type: '',
      city_type_full: '',
      city: '',
      city_area: '',
      city_district_fias_id: '',
      city_district_kladr_id: '',
      city_district_with_type: '',
      city_district_type: '',
      city_district_type_full: '',
      city_district: '',
      settlement_fias_id: 'cb24b65c-7d91-49af-8446-e74de6ee1e77',
      settlement_kladr_id: '2300500000100',
      settlement_with_type: 'село Белая Глина',
      settlement_type: 'с',
      settlement_type_full: 'село',
      settlement: 'Белая Глина',
      street_fias_id: '9104023c-e276-4155-9942-b01cf9836f72',
      street_kladr_id: '23005000001000300',
      street_with_type: 'ул Белоглинская',
      street_type: 'ул',
      street_type_full: 'улица',
      street: 'Белоглинская',
      house_fias_id: '94aa8620-efad-4d40-bfc1-0d01fec63166',
      house_kladr_id: '2300500000100030015',
      house_type: 'д',
      house_type_full: 'дом',
      house: '3',
      block_type: '',
      block_type_full: '',
      block: '',
      entrance: '',
      floor: '',
      flat_fias_id: '',
      flat_type: '',
      flat_type_full: '',
      flat: '',
      flat_area: '',
      square_meter_price: '',
      flat_price: '',
      postal_box: '',
      fias_id: '94aa8620-efad-4d40-bfc1-0d01fec63166',
      fias_code: '23005000001000000030015',
      fias_level: '8',
      fias_actuality_state: '0',
      kladr_id: '2300500000100030015',
      geoname_id: '542415',
      capital_marker: '1',
      okato: '03207802001',
      oktmo: '03607402101',
      tax_office: '2360',
      tax_office_legal: '2360',
      timezone: '',
      geo_lat: '46.0916808',
      geo_lon: '40.8639104',
      beltway_hit: '',
      beltway_distance: '',
      metro: '',
      qc_geo: '0',
      qc_complete: '',
      qc_house: '',
      history_values: '',
      unparsed_parts: '',
      source: '',
      qc: '',
    },
    unrestricted_value: '353040, Краснодарский край, Белоглинский р-н, село Белая Глина, ул Белоглинская, д 3',
    value: 'Краснодарский край, село Белая Глина, ул Белоглинская, д 3',
    houseNumber: '1',
    apartment: 'кв 11',
  },
  addressFiasLiving: {
    data: {
      postal_code: '107140',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: 'Центральный',
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: '2d4b988b-f7ea-4f42-bf16-c5bd9219943c',
      street_kladr_id: '77000000000066600',
      street_with_type: '1-й Новый пер',
      street_type: 'пер',
      street_type_full: 'переулок',
      street: '1-й Новый',
      house_fias_id: null,
      house_kladr_id: null,
      house_type: 'д',
      house_type_full: 'дом',
      house: '1',
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      postal_box: null,
      fias_id: '2d4b988b-f7ea-4f42-bf16-c5bd9219943c',
      fias_code: '77000000000000006660000',
      fias_level: '7',
      fias_actuality_state: '0',
      kladr_id: '77000000000066600',
      geoname_id: '524901',
      capital_marker: '0',
      okato: '45286565000',
      oktmo: '45378000',
      tax_office: '7708',
      tax_office_legal: '7708',
      timezone: null,
      geo_lat: '55.7788182',
      geo_lon: '37.6690613',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      qc_geo: '0',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    },
    unrestricted_value: '107140, г Москва, 1-й Новый пер, д 1',
    value: 'г Москва, 1-й Новый пер, д 1',
  },
  apartment: 'кв 11',
  apartmentLiving: 'кв 2',
  area: 'р-н Белоглинский',
  areaLiving: '',
  birthDate: new Date('1999-12-31T21:00:00.000Z'),
  birthPlace: 'Тест место рождения',
  city: 'село Белая Глина',
  cityLiving: 'г Москва',
  contactPhone: '4444444440',
  country: 'Россия',
  countryLiving: 'Россия',
  documentType: 1,
  email: 'Insurer@test.ru',
  fiasId: '94aa8620-efad-4d40-bfc1-0d01fec63166',
  fiasIdLiving: '2d4b988b-f7ea-4f42-bf16-c5bd9219943c',
  firstName: 'Тестов',
  gender: 1,
  homePhone: '82222222220',
  house: 'д 3',
  houseLiving: 'д 1',
  houseNumber: '1',
  houseNumberLiving: '1',
  index: '353040',
  indexLiving: '107140',
  inn: '321123132132',
  issuedBy: 'ОТДЕЛЕНИЕМ ОФМС РОССИИ ПО РЕСП. АДЫГЕЯ В Г. МАЙКОПЕ',
  kladId: '2300500000100030015',
  kladIdLiving: '77000000000066600',
  kp: '010001',
  lastName: 'Тест',
  mobilePhone: '71111111110',
  passportNumber: '123457',
  patronymic: 'Тестович',
  relationship: '',
  republic: 'край Краснодарский',
  republicLiving: 'г Москва',
  serial: '1234',
  snils: '08102957954',
  street: 'ул Белоглинская',
  streetLiving: '1-й Новый пер',
  when: new Date('2020-05-04T21:00:00.000Z'),
  validUntil: null,
  workPhone: '73333333330',
  isManualEnter: false,
  isManualEnterLiving: false,
  livingAddressIsSame: false,
  issuedByAccepted: true,
  taxResidenceCountry: 'Россия',
  citizenship: 'Россия',
};
