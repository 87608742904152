import React, { Component } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import { SelectInput } from 'components/_deprecated';
import { connect } from 'react-redux';
import {
  setAffiliation,
  getAccessBranches,
  getAccessPartners,
  selectBranchAuth,
  selectPartnerAuth,
  toggleModalAffiliation,
  logOut,
} from 'redux/rootActions';

import './styles.scss';
import { PopupContainer } from '../../index';

const propTypes = {
  setAffiliationByUser: PropTypes.func,
  selectedBranchId: PropTypes.number,
  selectedPartnerId: PropTypes.number,
  history: PropTypes.object,
  isAuth: PropTypes.bool,
  getAccessBranchesForUser: PropTypes.func,
  getAccessPartnersForUser: PropTypes.func,
  selectBranch: PropTypes.func,
  selectPartner: PropTypes.func,
  toggleModal: PropTypes.func,
  logOutAction: PropTypes.func,
  modalAffiliation: PropTypes.bool,
  accessBranches: PropTypes.arrayOf(PropTypes.object),
  accessPartners: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  setAffiliationByUser: () => null,
  selectedBranchId: 0,
  selectedPartnerId: 0,
  history: {},
  isAuth: false,
  getAccessBranchesForUser: () => null,
  getAccessPartnersForUser: () => null,
  selectBranch: () => null,
  selectPartner: () => null,
  toggleModal: () => null,
  logOutAction: () => null,
  modalAffiliation: false,
  accessBranches: [],
  accessPartners: [],
};

const {
  SUBDIVISION,
  PARTNER,
  SELECT,
  CANCEL_WORD,
  SELECT_SUBDIVISION_AND_PARTNER,
  SELECT_SUBDIVISION,
} = LANG_DICTIONARY;

const b = block('select-branch-popup');
class UserDependencies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorBranch: '',
      errorPartner: '',
      isSubmitting: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      selectedBranchId,
      selectedPartnerId,
    } = nextProps;

    return {
      errorBranch: selectedBranchId ? '' : 'Выберите подразделение',
      errorPartner: selectedPartnerId ? '' : 'Выберите партнера',
    };
  }

  componentDidMount() {
    const { getAccessPartnersForUser, isAuth, history } = this.props;
    if (isAuth) getAccessPartnersForUser({ history });
  }

  selectBranch = ({ target: { value } }) => {
    const {
      selectBranch,
    } = this.props;
    selectBranch(value);
  };

  selectPartner = ({ target: { value } }) => {
    const {
      selectPartner,
      getAccessBranchesForUser,
      selectBranch,
      history,
    } = this.props;
    selectPartner(value);
    selectBranch(null);
    getAccessBranchesForUser(value, true, history);
  };

  handleSelect = () => {
    const {
      setAffiliationByUser,
      selectedBranchId,
      selectedPartnerId,
      history,
      isAuth,
    } = this.props;

    const {
      errorBranch,
      errorPartner,
    } = this.state;

    this.setState({ isSubmitting: true });
    if (!errorBranch && !errorPartner) {
      setAffiliationByUser({
        branchId: selectedBranchId,
        partnerId: selectedPartnerId,
        history,
        isAuth,
      });
    }
  };

   close = () => {
     const {
       toggleModal,
       history,
       logOutAction,
       isAuth,
     } = this.props;
     toggleModal(false);
     if (isAuth) logOutAction({ history, mute: true, redirect: false });
   };

   getTitle = (accessPartners) => (accessPartners.length > 1
     ? SELECT_SUBDIVISION_AND_PARTNER : SELECT_SUBDIVISION);

   render() {
     const {
       modalAffiliation,
       accessBranches,
       selectedBranchId,
       selectedPartnerId,
       accessPartners,
     } = this.props;

     const {
       errorBranch,
       errorPartner,
       isSubmitting,
     } = this.state;

     const isRender = accessBranches.length > 1 || accessPartners.length > 1;

     if (!isRender) return null;

     return (
       <PopupContainer
         isOpen={modalAffiliation}
         close={this.close}
         className={b()}
       >
         <div className={b('window')}>
           <p className={b('header-title')}>{this.getTitle(accessPartners)}</p>
           {accessPartners.length > 1 && (
             <SelectInput
               placeholder={PARTNER}
               onChange={this.selectPartner}
               name="partner"
               errors={{ partner: errorPartner }}
               touched={{ partner: isSubmitting }}
               values={{ partner: selectedPartnerId }}
               options={accessPartners}
               prefixWrapperClass={b('select').toString()}
               search
             />
           )}
           <SelectInput
             placeholder={SUBDIVISION}
             onChange={this.selectBranch}
             name="branch"
             errors={{ branch: errorBranch }}
             touched={{ branch: isSubmitting }}
             values={{ branch: selectedBranchId }}
             options={accessBranches}
             disabled={!selectedPartnerId}
             prefixWrapperClass={b('select').toString()}
             search
           />
           <div className={b('buttons')}>
             <button
               type="button"
               className={b('btn-select')}
               onClick={this.handleSelect}
             >
               {SELECT}
             </button>
             <button
               type="button"
               className={b('btn-cancel')}
               onClick={this.close}
             >
               {CANCEL_WORD}
             </button>
           </div>
         </div>
       </PopupContainer>
     );
   }
}

const mapStateToProps = ({
  authReducer: {
    modalAffiliation,
    accessBranches,
    accessPartners,
    selectedBranchId,
    selectedPartnerId,
    roles,
  },
}) => ({
  selectedPartnerId,
  modalAffiliation,
  accessBranches,
  selectedBranchId,
  accessPartners,
  roles,
});

const mapDispatchToProps = {
  setAffiliationByUser: setAffiliation,
  getAccessBranchesForUser: getAccessBranches,
  getAccessPartnersForUser: getAccessPartners,
  selectBranch: selectBranchAuth,
  selectPartner: selectPartnerAuth,
  toggleModal: toggleModalAffiliation,
  logOutAction: logOut,
};

UserDependencies.propTypes = propTypes;
UserDependencies.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(UserDependencies));
