/*
  Промежуточный (рефакторинг) компонент для сценариев НСЖ и КСЖ
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Beneficiaries } from 'containers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAgentPersonBlockSelector,
  getAgentDocumentBlockSelector,
  getAgentAddressBlockSelector,
  getAgentBankBlockSelector,
  getAgentAddressLivingBlockSelector,
  getAgentInnSnilsBlockSelector,
  getAgentContactBlockSelector,
  getAgentRelationBlockSelector,
  getInsuredIsSame,
  getAgentDocumentBlockNotRFSelector,
  getAgentAddressBlockNotRFSelector,
  getAgentBankBlockNotRFSelector,
  getAgentAddressLivingBlockNotRFSelector,
  getAgentContactBlockNotRFSelector,
} from 'redux/rootSelectors';
import { withCustomRouter } from 'HOC';
import { getStoreKeysByInsLineType } from 'helpers';

const propTypes = {
  insuredIsSame: PropTypes.bool,
  productValues: PropTypes.object,
  stage: PropTypes.number,
  scenario: PropTypes.string,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  personBlockPropsInsurer: PropTypes.object.isRequired,
  documentBlockPropsInsurer: PropTypes.object.isRequired,
  addressBlockPropsInsurer: PropTypes.object.isRequired,
  bankBlockPropsInsurer: PropTypes.object.isRequired,
  addressLivingBlockPropsInsurer: PropTypes.object.isRequired,
  insSnilsBlockPropsInsurer: PropTypes.object.isRequired,
  contactBlockPropsInsurer: PropTypes.object.isRequired,
  relationBlockPropsInsurer: PropTypes.object.isRequired,
  documentBlockNotRFPropsInsurer: PropTypes.object.isRequired,
  addressBlockNotRFPropsInsurer: PropTypes.object.isRequired,
  bankBlockNotRFPropsInsurer: PropTypes.object.isRequired,
  addressLivingBlockNotRFPropsInsurer: PropTypes.object.isRequired,
  contactBlockNotRFPropsInsurer: PropTypes.object.isRequired,
  personBlockPropsInsured: PropTypes.object.isRequired,
  documentBlockPropsInsured: PropTypes.object.isRequired,
  addressBlockPropsInsured: PropTypes.object.isRequired,
  bankBlockPropsInsured: PropTypes.object.isRequired,
  addressLivingBlockPropsInsured: PropTypes.object.isRequired,
  insSnilsBlockPropsInsured: PropTypes.object.isRequired,
  contactBlockPropsInsured: PropTypes.object.isRequired,
  relationBlockPropsInsured: PropTypes.object.isRequired,
  personBlockPropsChildInsured: PropTypes.object.isRequired,
  documentBlockPropsChildInsured: PropTypes.object.isRequired,
  addressBlockPropsChildInsured: PropTypes.object.isRequired,
  bankBlockPropsChildInsured: PropTypes.object.isRequired,
  addressLivingBlockPropsChildInsured: PropTypes.object.isRequired,
  insSnilsBlockPropsChildInsured: PropTypes.object.isRequired,
  contactBlockPropsChildInsured: PropTypes.object.isRequired,
  relationBlockPropsChildInsured: PropTypes.object.isRequired,
  productForm: PropTypes.object.isRequired,
  setStage: PropTypes.func.isRequired,
  preliminaryCalculationUrl: PropTypes.string.isRequired,
  executionUrl: PropTypes.string.isRequired,
  addressCheckingPayload: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired,
};

const defaultProps = {
  insuredIsSame: false,
  productValues: {},
  stage: 0,
  scenario: '',
};

const BeneficiariesCommon = ({
  productForm,
  productValues,
  stage,
  scenario,
  history,
  match: { params: { id } },
  insuredIsSame,
  personBlockPropsInsurer,
  documentBlockPropsInsurer,
  addressBlockPropsInsurer,
  bankBlockPropsInsurer,
  addressLivingBlockPropsInsurer,
  insSnilsBlockPropsInsurer,
  contactBlockPropsInsurer,
  documentBlockNotRFPropsInsurer,
  addressBlockNotRFPropsInsurer,
  bankBlockNotRFPropsInsurer,
  addressLivingBlockNotRFPropsInsurer,
  contactBlockNotRFPropsInsurer,
  relationBlockPropsInsurer,
  personBlockPropsInsured,
  documentBlockPropsInsured,
  addressBlockPropsInsured,
  bankBlockPropsInsured,
  addressLivingBlockPropsInsured,
  insSnilsBlockPropsInsured,
  contactBlockPropsInsured,
  relationBlockPropsInsured,
  personBlockPropsChildInsured,
  documentBlockPropsChildInsured,
  addressBlockPropsChildInsured,
  bankBlockPropsChildInsured,
  addressLivingBlockPropsChildInsured,
  insSnilsBlockPropsChildInsured,
  contactBlockPropsChildInsured,
  relationBlockPropsChildInsured,
  setStage,
  executionUrl,
  preliminaryCalculationUrl,
  addressCheckingPayload,
  productId,
}) => {
  useEffect(() => {
    if (stage < 4) {
      history.push(`${preliminaryCalculationUrl}/${id}`);
    }
  }, [stage]);

  return (
    <Beneficiaries
      productId={productId}
      addressCheckingPayload={addressCheckingPayload}
      productValues={productValues}
      productForm={productForm}
      childInsuredValues={{
        ...personBlockPropsChildInsured.values,
        ...documentBlockPropsChildInsured.values,
        ...addressBlockPropsChildInsured.values,
        ...bankBlockPropsChildInsured.values,
        ...addressLivingBlockPropsChildInsured.values,
        ...insSnilsBlockPropsChildInsured.values,
        ...contactBlockPropsChildInsured.values,
        ...relationBlockPropsChildInsured.values,
      }}
      insurerValues={{
        ...personBlockPropsInsurer.values,
        ...insSnilsBlockPropsInsurer.values,
        ...relationBlockPropsInsurer.values,
        ...(personBlockPropsInsurer.values.isResidentRF ?
          {
            ...documentBlockPropsInsurer.values,
            ...addressBlockPropsInsurer.values,
            ...bankBlockPropsInsurer.values,
            ...addressLivingBlockPropsInsurer.values,
            ...contactBlockPropsInsurer.values,
          } : {
            ...documentBlockNotRFPropsInsurer.values,
            ...addressBlockNotRFPropsInsurer.values,
            ...bankBlockNotRFPropsInsurer.values,
            ...bankBlockPropsInsurer.values,
            ...addressLivingBlockNotRFPropsInsurer.values,
            ...contactBlockNotRFPropsInsurer.values,
          }),
      }}
      insuredValues={{
        ...personBlockPropsInsured.values,
        ...documentBlockPropsInsured.values,
        ...addressBlockPropsInsured.values,
        ...bankBlockPropsInsured.values,
        ...addressLivingBlockPropsInsured.values,
        ...insSnilsBlockPropsInsured.values,
        ...contactBlockPropsInsured.values,
        ...relationBlockPropsInsured.values,
      }}
      insuredIsSame={insuredIsSame}
      stage={stage}
      scenario={scenario}
      executionPath={executionUrl}
      setNextStage={() => setStage(5)}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    product,
    productValues,
    productFields,
  } = getStoreKeysByInsLineType(ownProps.additionalParams.type);

  const {
    [product]: {
      insurerValues,
      insuredValues,
      childInsuredValues,
      stage,
      [productValues]: {
        scenario,
      },
    },
  } = state;
  const stateProductValues = state[product][productValues];
  const stateProductFields = state[product][productFields];

  return {
    personBlockPropsInsurer: getAgentPersonBlockSelector(state, 'insurer'),
    documentBlockPropsInsurer: getAgentDocumentBlockSelector(state, 'insurer'),
    addressBlockPropsInsurer: getAgentAddressBlockSelector(state, 'insurer'),
    bankBlockPropsInsurer: getAgentBankBlockSelector(state, 'insurer'),
    addressLivingBlockPropsInsurer: getAgentAddressLivingBlockSelector(state, 'insurer'),
    insSnilsBlockPropsInsurer: getAgentInnSnilsBlockSelector(state, 'insurer'),
    contactBlockPropsInsurer: getAgentContactBlockSelector(state, 'insurer'),
    relationBlockPropsInsurer: getAgentRelationBlockSelector(state, 'insurer'),
    documentBlockNotRFPropsInsurer: getAgentDocumentBlockNotRFSelector(state, 'insurer'),
    addressBlockNotRFPropsInsurer: getAgentAddressBlockNotRFSelector(state, 'insurer'),
    bankBlockNotRFPropsInsurer: getAgentBankBlockNotRFSelector(state, 'insurer'),
    addressLivingBlockNotRFPropsInsurer: getAgentAddressLivingBlockNotRFSelector(state, 'insurer'),
    contactBlockNotRFPropsInsurer: getAgentContactBlockNotRFSelector(state, 'insurer'),

    personBlockPropsInsured: getAgentPersonBlockSelector(state, 'insured'),
    documentBlockPropsInsured: getAgentDocumentBlockSelector(state, 'insured'),
    addressBlockPropsInsured: getAgentAddressBlockSelector(state, 'insured'),
    bankBlockPropsInsured: getAgentBankBlockSelector(state, 'insured'),
    addressLivingBlockPropsInsured: getAgentAddressLivingBlockSelector(state, 'insured'),
    insSnilsBlockPropsInsured: getAgentInnSnilsBlockSelector(state, 'insured'),
    contactBlockPropsInsured: getAgentContactBlockSelector(state, 'insured'),
    relationBlockPropsInsured: getAgentRelationBlockSelector(state, 'insured'),

    personBlockPropsChildInsured: getAgentPersonBlockSelector(state, 'insuredChild'),
    documentBlockPropsChildInsured: getAgentDocumentBlockSelector(state, 'insuredChild'),
    addressBlockPropsChildInsured: getAgentAddressBlockSelector(state, 'insuredChild'),
    bankBlockPropsChildInsured: getAgentBankBlockSelector(state, 'insuredChild'),
    addressLivingBlockPropsChildInsured: getAgentAddressLivingBlockSelector(state, 'insuredChild'),
    insSnilsBlockPropsChildInsured: getAgentInnSnilsBlockSelector(state, 'insuredChild'),
    contactBlockPropsChildInsured: getAgentContactBlockSelector(state, 'insuredChild'),
    relationBlockPropsChildInsured: getAgentRelationBlockSelector(state, 'insuredChild'),

    insuredIsSame: getInsuredIsSame(state),
    productValues: stateProductFields,
    childInsuredValues,
    insurerValues,
    insuredValues,
    stage,
    scenario,
    productForm: stateProductValues,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setStage: bindActionCreators(ownProps.setStage, dispatch),
});

BeneficiariesCommon.propTypes = propTypes;
BeneficiariesCommon.defaultProps = defaultProps;
export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(BeneficiariesCommon));
