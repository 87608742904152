import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form,
  Icon,
  Radio,
} from 'semantic-ui-react';
import { withCustomRouter } from 'HOC';
import {
  getError,
  resetValidationTerrorist,
  setCurrentAgentType,
  changeAgentFieldValue,
  validateAgentFieldValues,
  setAgentTouchField,
  changeInsuredIsSame,
  setAgentAllTouchFields,
  setStageProduct,
  setAddressDataAgent,
  setInsuredValues,
  clearStoreAgentData,
  clearBankBlocks,
} from 'redux/rootActions';
import { getBlackAddressInfo } from 'redux/rootSelectors';
import { memoize } from 'lodash';
import {
  checkSnils,
  setFieldValues,
  setSomeFieldsValues,
  getAddress,
  getBankList,
  getFmsUnitList,
} from 'helpers';
import {
  LANG_DICTIONARY,
  INSURED_FIELDS_LIVING,
  CHILD_INSURED_FIELDS_LIVING,
  BLOCK_NAMES,
  INPUT_NAMES,
  INS_LINE_TYPES,
} from 'consts';
import {
  DocumentBlock,
  AddressBlockCommon,
  ContactBlock,
  FormSection,
  InnSnilsBlock,
  BankBlock,
  PersonBlock,
  RelationShipBlock,
  RequiredLabel,
  ErrorMessage,
  CustomCheckBox,
  BankBlockNotRF,
  ContactBlockNotRF,
  DocumentBlockNotRF,
} from 'components';
import { Collapse } from 'reactstrap';
import './styles.scss';
import { simpleDate } from '../../../helpers';

const defaultProps = {
  isLoading: false,
  history: null,
  insuredIsSame: true,
  error: null,
  snilsError: null,
  GetError: null,
  stage: 1,
  hideInn: true,
  hideSnils: true,
  personBlockProps: {},
  documentBlockProps: {},
  addressBlockProps: {},
  bankBlockProps: {},
  addressLivingBlockProps: {},
  insSnilsBlockProps: {},
  contactBlockProps: {},
  relationBlockProps: {},
  bankBlockNotRFProps: {},
  contactBlockNotRFProps: {},
  documentBlockNotRFProps: {},
  addressBlockNotRFProps: {},
  addressLivingBlockNotRFProps: {},
  changeFieldValue: () => null,
  setTouchField: () => null,
  isInsured: false,
  isChildForm: false,
  validate: () => null,
  isChild: false,
  setInsuredIsSame: () => null,
  hideBankInfo: false,
  scenario: '',
  disableInsuredIsSame: false,
  checkPassportCovid: false,
  insurerIsInsuredSetting: false,
  isListBlackAddress: false,
  onChangeAddress: () => null,
  clientTestRecordFields: {},
  ClearStoreAgentData: () => null,
  ClearBankBlocks: () => null,
  nszProductValues: {},
  kszProductValues: {},
};

const propTypes = {
  checkNumbers: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  insuredIsSame: PropTypes.bool,
  error: PropTypes.string,
  snilsError: PropTypes.string,
  GetError: PropTypes.func,
  stage: PropTypes.number,
  hideInn: PropTypes.bool,
  hideSnils: PropTypes.bool,
  personBlockProps: PropTypes.object,
  documentBlockProps: PropTypes.object,
  addressBlockProps: PropTypes.object,
  bankBlockProps: PropTypes.object,
  addressLivingBlockProps: PropTypes.object,
  insSnilsBlockProps: PropTypes.object,
  contactBlockProps: PropTypes.object,
  relationBlockProps: PropTypes.object,
  bankBlockNotRFProps: PropTypes.object,
  contactBlockNotRFProps: PropTypes.object,
  documentBlockNotRFProps: PropTypes.object,
  addressBlockNotRFProps: PropTypes.object,
  addressLivingBlockNotRFProps: PropTypes.object,
  changeFieldValue: PropTypes.func,
  setTouchField: PropTypes.func,
  match: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isInsured: PropTypes.bool,
  isChildForm: PropTypes.bool,
  validate: PropTypes.func,
  setInsuredIsSame: PropTypes.func,
  isChild: PropTypes.bool,
  hideBankInfo: PropTypes.bool,
  scenario: PropTypes.string,
  disableInsuredIsSame: PropTypes.bool,
  checkPassportCovid: PropTypes.bool,
  insurerIsInsuredSetting: PropTypes.bool,
  isListBlackAddress: PropTypes.bool,
  insurerDataUrl: PropTypes.string.isRequired,
  preliminaryCalculationUrl: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func,
  clientTestRecordFields: PropTypes.object,
  ClearStoreAgentData: PropTypes.func,
  ClearBankBlocks: PropTypes.func,
  nszProductValues: PropTypes.object,
  kszProductValues: PropTypes.object,
};

const b = block('agent-data');
const formContainer = block('formContainer');
const {
  DOCUMENTS,
  REGISTRATION_ADDRESS,
  LIVING_ADDRESS,
  SAME_ADDRESS,
  IS_SAME,
  CONTACT_DATA,
  BANK_DETAILS,
  DEFAULT_CONTRY_RUSSIA,
  DOCUMENTS_CHILD,
  REGISTRATION_ADDRESS_CHILD,
  LIVING_ADDRESS_CHILD,
  CONTACT_DATA_CHILD,
  INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_GENERAL_OR_DATE_OF_BIRTH,
  SINGULAR_INSURED,
  SINGULAR_INSURED_CHILD,
  DOCUMENT_TYPE_BIRTH_DOCUMENT,
  FL_RESIDENT_RF,
  FL_NON_RESIDENT_RF,
  BANK_RU_DETAILS,
  FOREIGN_BANK_DETAILS,
} = LANG_DICTIONARY;

const INSURES_INITIAL_LIST = [
  { field: 'countryLiving', value: DEFAULT_CONTRY_RUSSIA },
  { field: 'childcountryLiving', value: DEFAULT_CONTRY_RUSSIA },
];

export default (typeForm) => {
  class AgentData extends Component {
    constructor(props) {
      super(props);
      const {
        stage,
        history,
        isInsured,
        match: { params: { id } },
        config,
        insurerDataUrl,
        preliminaryCalculationUrl,
      } = props;
      const minStage = isInsured ? 3 : 2;
      const redirectPath = isInsured ? insurerDataUrl : preliminaryCalculationUrl;
      this.state = {
        ...config,
        ageError: null,
        disableFIO: false,
        isDisableMobile: false,
      };

      if (stage < minStage) {
        history.push(`${redirectPath}/${id}`);
      }
    }

    componentDidMount() {
      const {
        changeFieldValue,
        clientTestRecordFields,
      } = this.props;

      this.state.disableFIO =
      Boolean(clientTestRecordFields.firstName && clientTestRecordFields.lastName);
      this.state.isDisableMobile = Boolean(clientTestRecordFields.mobileNumber);

      const { disableFIO } = this.state;

      if (disableFIO) {
        changeFieldValue({
          name: 'firstName',
          value: clientTestRecordFields.firstName,
          blockName: BLOCK_NAMES.personBlock,
          form: typeForm,
        });

        changeFieldValue({
          name: 'lastName',
          value: clientTestRecordFields.lastName,
          blockName: BLOCK_NAMES.personBlock,
          form: typeForm,
        });

        changeFieldValue({
          name: 'patronymic',
          value: clientTestRecordFields.middleName,
          blockName: BLOCK_NAMES.personBlock,
          form: typeForm,
        });

        changeFieldValue({
          name: 'mobilePhone',
          value: clientTestRecordFields.mobileNumber,
          blockName: BLOCK_NAMES.contactBlock,
          form: typeForm,
        });
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutInsuredIsSame);
    }

    renderAddressCheckbox = ({
      onChange,
      onBlur,
      setFieldValue,
      values,
    },
    prefix = '') => (
      <Form.Field className={b('addressCheckboxWrap').toString()}>
        <CustomCheckBox
          name={`${prefix}livingAddressIsSame`}
          className={b('addressCheckbox')}
          checked={values.livingAddressIsSame}
          onChange={(name, checked) => {
            setFieldValues(
              prefix ? CHILD_INSURED_FIELDS_LIVING : INSURED_FIELDS_LIVING,
              setFieldValue,
              INSURES_INITIAL_LIST,
            );

            onChange({
              target: {
                value: !checked,
                name,
              },
            });
          }}
          onBlur={onBlur}
        />
        <p>{SAME_ADDRESS}</p>
      </Form.Field>
    );

    renderIsBankRU = ({ isBankRU }) => {
      const { changeFieldValue, ClearBankBlocks } = this.props;
      const handleBankChange = (value) => {
        ClearBankBlocks({ form: typeForm });
        changeFieldValue({
          name: 'isBankRU',
          value,
          blockName: BLOCK_NAMES.bankBlockNotRF,
          form: typeForm,
        });
        changeFieldValue({
          name: 'intermediaryBank',
          value: false,
          blockName: BLOCK_NAMES.bankBlockNotRF,
          form: typeForm,
        });
        changeFieldValue({
          name: 'currency',
          value: 'RU',
          blockName: BLOCK_NAMES.bankBlockNotRF,
          form: typeForm,
        });
      };

      return (
        <Form.Field className={b('bank-not-rf-block__ru')}>
          <Radio
            label={BANK_RU_DETAILS}
            name="bankRU"
            checked={isBankRU}
            onChange={() => handleBankChange(true)}
          />
          <Radio
            label={FOREIGN_BANK_DETAILS}
            name="foriginBank"
            checked={!isBankRU}
            onChange={() => handleBankChange(false)}
          />
        </Form.Field>
      );
    };

    setFmsUnit = (values) => {
      const fmsUnitList = getFmsUnitList(values);
      const actionSetFieldValue = this.customSetFieldValue(BLOCK_NAMES.documentBlock);
      setSomeFieldsValues(fmsUnitList, actionSetFieldValue);
    };

    handleChangeAddress = (values, isLiving) => {
      const { onChangeAddress } = this.props;
      const currentBlock = isLiving ? BLOCK_NAMES.addressBlockLiving : BLOCK_NAMES.addressBlock;
      onChangeAddress(values, typeForm, currentBlock);
    }

    setAddress = (isLiving) => (values) => {
      const currentBlock = isLiving ? BLOCK_NAMES.addressBlockLiving : BLOCK_NAMES.addressBlock;
      const actionSetFieldValue = this.customSetFieldValue(currentBlock);
      const addressList = getAddress(values, isLiving);
      setSomeFieldsValues(addressList, actionSetFieldValue);
    };

    setBankValues = (values) => {
      const bankList = getBankList(values);
      const actionSetFieldValue = this.customSetFieldValue(BLOCK_NAMES.bankBlock);
      setSomeFieldsValues(bankList, actionSetFieldValue);
    };

    validateSnils = (value) => {
      const { snilsError, GetError } = this.props;
      const { result, error } = checkSnils(value);

      if (snilsError !== error) GetError(error, 'snilsError');

      return result;
    };

    isShow = () => {
      const {
        isInsured,
        insuredIsSame,
        isChildForm,
        insurerIsInsuredSetting,
      } = this.props;

      if (!isInsured || isChildForm) return true;

      return !insurerIsInsuredSetting && !insuredIsSame;
    };

    handleChangeStore = (blockName) => (event) => {
      const { changeFieldValue, validate } = this.props;
      const { target } = event;
      const { name, value } = target;

      changeFieldValue({
        name,
        value,
        blockName,
        form: typeForm,
      });
      validate({ newValue: { name, value }, form: typeForm });
    };

    // change field by programmatically
    customSetFieldValue = (blockName, isValidate = true) => (name, value) => {
      const { changeFieldValue, validate } = this.props;

      if (blockName === BLOCK_NAMES.documentBlock && name.endsWith(INPUT_NAMES.issuedBy)) {
        changeFieldValue({
          name: 'issuedByAccepted',
          value: false,
          blockName,
          form: typeForm,
        });
      }

      changeFieldValue({
        name,
        value,
        blockName,
        form: typeForm,
      });

      if (isValidate) {
        validate({ newValue: { name, value }, form: typeForm });
      }
    };

    // change field by hand
    customSetFieldTouch = (blockName) => (name, value) => {
      const { setTouchField, changeFieldValue, validate } = this.props;

      setTouchField({
        name,
        value,
        blockName,
        form: typeForm,
      });

      if (blockName === BLOCK_NAMES.documentBlock && name.endsWith(INPUT_NAMES.issuedBy)) {
        changeFieldValue({
          name: 'issuedByAccepted',
          value: false,
          blockName,
          form: typeForm,
        });
        // validate on next tick
        setTimeout(() => validate({ newValue: { name, value }, form: typeForm }));
      }
    };

    handleBlur = (blockName) => ({ target: { name, value } }) => {
      const { setTouchField, validate } = this.props;
      setTouchField({
        name,
        value: true,
        blockName,
        form: typeForm,
      });
      validate({ newValue: { name, value }, form: typeForm });
    };

    customMemoize = (func) => memoize(func, (data) => JSON.stringify(data));

    getInputProps = ((data, blockName) => {
      const { isChildForm } = this.props;

      return {
        ...data,
        onChange: this.handleChangeStore(blockName),
        onBlur: this.handleBlur(blockName),
        setFieldTouched: this.customSetFieldTouch(blockName),
        setFieldValue: this.customSetFieldValue(blockName),
        prefixScroll: isChildForm ? 'child' : '',
        onKeyDown: (event) => {
          if (event?.target && event.target.name.endsWith(INPUT_NAMES.mobilePhone)) {
            const value = event.target.value.replace(new RegExp('\\+7\\(|\\)|-|_', 'g'), '');
            if (event.key !== '9' && value.length === 0) {
              event.preventDefault();
              this.setFieldValue(event.target.name, '');
            }
          }
        },
      };
    });

    getInputPropsPerson = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.personBlock),
    );

    getInputPropsDocuments = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.documentBlock),
    );

    getInputPropsAddress = this.customMemoize(
      (data) => ({
        ...this.getInputProps(data, BLOCK_NAMES.addressBlock),
        values: { ...data.values, isResidentRF: true },
      }),
    );

    getInputPropsBank = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.bankBlock),
    );

    getInputPropsAddressLiving = this.customMemoize(
      (data) => ({
        ...this.getInputProps(data, BLOCK_NAMES.addressBlockLiving),
        values: { ...data.values, isResidentRF: true },
      }),
    );

    getInputPropsInnSnils = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.innSnilsBlock),
    );

    getInputPropsContact = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.contactBlock),
    );

    getInputPropsRelation = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.relationBlock),
    );

    getInputPropsDocumentsNotRF = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.documentBlockNotRF),
    );

    getInputPropsAddressNotRF = this.customMemoize(
      (data) => ({
        ...this.getInputProps(data, BLOCK_NAMES.addressBlockNotRF),
        values: { ...data.values, isResidentRF: false },
      }),
    );

    getInputPropsBankNotRF = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.bankBlockNotRF),
    );

    getInputPropsAddressLivingNotRF = this.customMemoize(
      (data) => ({
        ...this.getInputProps(data, BLOCK_NAMES.addressBlockLivingNotRF),
        values: { ...data.values, isResidentRF: false },
      }),
    );

    getInputPropsContactNotRF = this.customMemoize(
      (data) => this.getInputProps(data, BLOCK_NAMES.contactBlockNotRF),
    );

    handleClickInsuredIsSame = () => {
      const { disableInsuredIsSame } = this.props;

      if (disableInsuredIsSame) {
        this.setState({
          errorInsuredIsSame:
            INSURER_AND_INSURED_CANNOT_MATCH_AS_THERE_ARE_DIFFERENT_GENERAL_OR_DATE_OF_BIRTH,
        });
        clearTimeout(this.timeoutInsuredIsSame);
        this.timeoutInsuredIsSame = setTimeout(() => this.setState({ errorInsuredIsSame: '' }), 5000);
      }
    };

    getAllValues = (data) => {
      const {
        personBlockProps,
        documentBlockProps,
        addressBlockProps,
        bankBlockProps,
        addressLivingBlockProps,
        insSnilsBlockProps,
        contactBlockProps,
        relationBlockProps,
        bankBlockNotRFProps,
        contactBlockNotRFProps,
        documentBlockNotRFProps,
        addressBlockNotRFProps,
        addressLivingBlockNotRFProps,
      } = this.props;

      return {
        ...personBlockProps.values,
        ...documentBlockProps.values,
        ...addressBlockProps.values,
        ...bankBlockProps.values,
        ...addressLivingBlockProps.values,
        ...insSnilsBlockProps.values,
        ...contactBlockProps.values,
        ...relationBlockProps.values,
        ...bankBlockNotRFProps.values,
        ...contactBlockNotRFProps.values,
        ...documentBlockNotRFProps.values,
        ...addressBlockNotRFProps.values,
        ...addressLivingBlockNotRFProps.values,
        ...data.values,
      };
    };

    getAllValuesForAddress = this.customMemoize(this.getAllValues);

    getAllValuesForAddressLiving = this.customMemoize(this.getAllValues);

    getPayloadRequestCheckCode = () => {
      const { userInfo, personBlockProps, isInsured } = this.props;
      const inputPropsPersonBlock = this.getInputPropsPerson(personBlockProps);

      return {
        firstName: inputPropsPersonBlock.values.firstName,
        lastName: inputPropsPersonBlock.values.lastName,
        patronymic: inputPropsPersonBlock.values.patronymic,
        birthDate: simpleDate(inputPropsPersonBlock.values.birthDate),
        branchId: userInfo.branchId,
        partnerId: userInfo.partnerId,
        personType: isInsured ? 'insured' : 'insurer',
      };
    }

    handleRadioChange = (isResident) => {
      const { changeFieldValue, ClearStoreAgentData } = this.props;
      ClearStoreAgentData();

      changeFieldValue({
        name: 'isResidentRF',
        value: isResident,
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });
      changeFieldValue({
        name: 'isBankRU',
        value: true,
        blockName: BLOCK_NAMES.bankBlockNotRF,
        form: typeForm,
      });
      changeFieldValue({
        name: 'documentType',
        value: isResident ? 1 : 6,
        blockName: BLOCK_NAMES.documentBlockNotRF,
        form: typeForm,
      });
      changeFieldValue({
        name: 'taxResidenceCountry',
        value: isResident ? 'Россия' : '',
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });
      changeFieldValue({
        name: 'citizenship',
        value: isResident ? 'Россия' : '',
        blockName: BLOCK_NAMES.personBlock,
        form: typeForm,
      });
      changeFieldValue({
        name: 'currency',
        value: 'RU',
        blockName: BLOCK_NAMES.bankBlockNotRF,
        form: typeForm,
      });
      changeFieldValue({
        name: 'isManualEnter',
        value: !isResident,
        blockName: BLOCK_NAMES.addressBlockNotRF,
        form: typeForm,
      });
      changeFieldValue({
        name: 'isManualEnterLiving',
        value: !isResident,
        blockName: BLOCK_NAMES.addressBlockLivingNotRF,
        form: typeForm,
      });
    };

    render() {
      const {
        isLoading,
        snilsError,
        error: serverError,
        isInsured,
        insuredIsSame,
        hideInn,
        hideSnils,
        checkNumbers,
        personBlockProps,
        documentBlockProps,
        addressBlockProps,
        bankBlockProps,
        addressLivingBlockProps,
        insSnilsBlockProps,
        contactBlockProps,
        relationBlockProps,
        bankBlockNotRFProps,
        contactBlockNotRFProps,
        documentBlockNotRFProps,
        addressBlockNotRFProps,
        addressLivingBlockNotRFProps,
        isChild,
        isChildForm,
        setInsuredIsSame,
        scenario,
        disableInsuredIsSame,
        checkPassportCovid,
        insurerIsInsuredSetting,
        isListBlackAddress,
        hideBankInfo,
        nszProductValues,
        kszProductValues,
      } = this.props;
      const {
        isPerson,
        isDocuments,
        isAddress,
        isAddressLiving,
        isInnSnils,
        isContacts,
        isBank,
        ageError,
        errorInsuredIsSame,
        disableFIO,
        isDisableMobile,
      } = this.state;
      const isInnSnilsBlock = isInnSnils && (!hideInn || !hideSnils);
      const errorWarning = serverError || ageError;
      const isNsz = scenario.includes(INS_LINE_TYPES.NSZ);
      const isControlDisabled = (insurerIsInsuredSetting || isInsured) && isNsz;
      const { documentType } = documentBlockProps.values;
      const isRequiredEmailAndMobile = !isChildForm ||
        documentType !== DOCUMENT_TYPE_BIRTH_DOCUMENT;

      const inputPropsDocumentBlock = this.getInputPropsDocuments(documentBlockProps);
      const { isResidentRF } = isInsured ?
        { isResidentRF: true } : personBlockProps.values;

      const availableForNonResident = isInsured ? false :
        personBlockProps.values.availableForNonResident ||
        nszProductValues?.availableForNonResident ||
        kszProductValues?.productsGrouping?.filter(
          (el) => el.mainProduct?.availableForNonResident,
        ).length > 0;
      const { isBankRU } = isInsured ? { isBankRU: true } : bankBlockNotRFProps.values;

      const inputPropsAddressBlock = isResidentRF ? this.getInputPropsAddress(addressBlockProps) :
        this.getInputPropsAddressNotRF(addressBlockNotRFProps);
      const inputPropsAddressLivingBlock = isResidentRF ? this.getInputPropsAddressLiving(
        addressLivingBlockProps,
      ) : this.getInputPropsAddressLivingNotRF(
        addressLivingBlockNotRFProps,
      );

      return (
        <Form loading={isLoading}>
          <div className={b('formContainer', 'inputs')}>
            {
                isInsured && !isChildForm && (
                  <div className={formContainer('formSection')}>
                    <Form.Field className={b('addressCheckboxWrap').toString()}>
                      <CustomCheckBox
                        name="insuredIsSame"
                        className={b('addressCheckbox').toString()}
                        checked={insurerIsInsuredSetting || insuredIsSame}
                        isShowDisabled
                        onChange={() => setInsuredIsSame(!insuredIsSame)}
                        onClick={(...args) => !insurerIsInsuredSetting
                          && this.handleClickInsuredIsSame(...args)}
                        disabled={disableInsuredIsSame || insurerIsInsuredSetting}
                      />
                      <p>{IS_SAME}</p>
                    </Form.Field>
                    <Collapse isOpen={Boolean(errorInsuredIsSame)}>
                      <div className={b('insured-is-same-error')}>
                        <Icon name="warning" />
                        <p className={b('insured-is-same-error-text')}>{errorInsuredIsSame}</p>
                      </div>
                    </Collapse>
                  </div>
                )
            }
            <Collapse isOpen={this.isShow()}>
              <>
                {!isInsured && (
                  <Form.Field className={b('residentStatusCheckboxWrap')}>
                    <Radio
                      label={FL_RESIDENT_RF}
                      name="flResidentRF"
                      className={b('flResidentRF')}
                      checked={isResidentRF}
                      disabled={!availableForNonResident}
                      onChange={() => { this.handleRadioChange(true); }}
                    />
                    <Radio
                      label={FL_NON_RESIDENT_RF}
                      name="flNonResidentRF"
                      className={b('flNonResidentRF')}
                      checked={!isResidentRF}
                      disabled={!availableForNonResident}
                      onChange={() => { this.handleRadioChange(false); }}
                    />
                  </Form.Field>
                )}
                {(insuredIsSame || !isChildForm) && <RequiredLabel />}
                {isInsured && isChild && <p className={b('form-title')}>{SINGULAR_INSURED}</p>}
                {isInsured && isChildForm && <p className={b('form-title')}>{SINGULAR_INSURED_CHILD}</p>}
                {
                  isInsured && (
                    <div className={formContainer('formSection')}>
                      <RelationShipBlock
                        className={b('relationship')}
                        inputProps={this.getInputPropsRelation(relationBlockProps)}
                        isChildForm={isChildForm}
                      />
                    </div>
                  )
                }
                {
                  isPerson && (
                    <PersonBlock
                      isInsured={isInsured}
                      inputProps={this.getInputPropsPerson(personBlockProps)}
                      validateFullName={this.validateTerrorist}
                      validateByDate={this.validateTerroristByDate}
                      disableBirthdate={isControlDisabled}
                      disableGender={isControlDisabled}
                      disableAutodetectGender={isControlDisabled}
                      isChildForm={isChildForm}
                      checkPassportCovid={checkPassportCovid}
                      disableFIO={disableFIO}
                    />
                  )
                }
                {
                  isDocuments && (isResidentRF || isInsured) && (
                    <FormSection
                      label={isChildForm ? DOCUMENTS_CHILD : DOCUMENTS}
                      inputs={(
                        <>
                          <DocumentBlock
                            selectSearch={this.setFmsUnit}
                            setFieldValue={this.customSetFieldValue(BLOCK_NAMES.documentBlock)}
                            inputProps={inputPropsDocumentBlock}
                            documentType={documentBlockProps.values.documentType}
                            onlyPassport={!isInsured}
                            isResetDataOnChangeDocumentData
                          />
                        </>
                      )}
                    />
                  )
                }
                {
                  isDocuments && !(isResidentRF || isInsured) && (
                    <FormSection
                      label={isChildForm ? DOCUMENTS_CHILD : DOCUMENTS}
                      inputs={(
                        <>
                          <DocumentBlockNotRF
                            selectSearch={this.setFmsUnit}
                            setFieldValue={this.customSetFieldValue(BLOCK_NAMES.documentBlockNotRF)}
                            inputProps={this.getInputPropsDocumentsNotRF(documentBlockNotRFProps)}
                            documentType={documentBlockNotRFProps.values.documentType}
                            onlyPassport={!isInsured}
                            isResetDataOnChangeDocumentData
                          />
                        </>
                     )}
                    />
                  )
                }
                {
                  isAddress && (
                    <FormSection
                      label={isChildForm ? REGISTRATION_ADDRESS_CHILD : REGISTRATION_ADDRESS}
                      inputs={(
                        <AddressBlockCommon
                          inputProps={{
                            ...inputPropsAddressBlock,
                            setValues: this.handleChangeAddress,
                          }}
                          isLivingAddress={false}
                          insuredIsSame={insuredIsSame}
                          isInsured={isInsured}
                          index={isChildForm ? 1 : 0}
                          isListBlackAddress={isListBlackAddress}
                          selectSearch={this.setAddress()}
                          insuredValues={this.getAllValuesForAddress(addressBlockProps)}
                          childInsuredValues={this.getAllValuesForAddress(addressBlockProps)}
                          isManualEnter={inputPropsAddressBlock.values.isManualEnter}
                          toggleTypeEnter={(value) => inputPropsAddressBlock.onChange({
                            target: {
                              name: 'isManualEnter',
                              value,
                            },
                          })}
                        />
                      )}
                    />
                  )
                }
                {
                  isAddressLiving && (
                    <FormSection
                      label={isChildForm ? LIVING_ADDRESS_CHILD : LIVING_ADDRESS}
                      isShow={!inputPropsAddressLivingBlock.values.livingAddressIsSame}
                      inputs={(
                        <AddressBlockCommon
                          inputProps={{
                            ...inputPropsAddressLivingBlock,
                            setValues: this.handleChangeAddress,
                          }}
                          isLivingAddress
                          insuredIsSame={insuredIsSame}
                          isInsured={isInsured}
                          selectSearch={this.setAddress(true)}
                          isListBlackAddress={isListBlackAddress}
                          insuredValues={this.getAllValuesForAddressLiving(addressLivingBlockProps)}
                          index={isChildForm ? 1 : 0}
                          childInsuredValues={
                            this.getAllValuesForAddressLiving(addressLivingBlockProps)
                          }
                          isManualEnter={inputPropsAddressLivingBlock.values.isManualEnterLiving}
                          toggleTypeEnter={(value) => inputPropsAddressLivingBlock.onChange({
                            target: {
                              name: 'isManualEnterLiving',
                              value,
                            },
                          })}
                        />
                      )}
                      checkBoxButton={() => this.renderAddressCheckbox(
                        inputPropsAddressLivingBlock,
                      )}
                    />
                  )
                }
                {
                  isInnSnilsBlock && (
                    <FormSection
                      inputs={InnSnilsBlock({
                        validateSnils: this.validateSnils,
                        inputProps: this.getInputPropsInnSnils(insSnilsBlockProps),
                        snilsError,
                        hideInn,
                        hideSnils,
                      })}
                    />
                  )
                }
                {
                  isContacts && (isResidentRF || isInsured) && (
                    <FormSection
                      label={isChildForm ? CONTACT_DATA_CHILD : CONTACT_DATA}
                      inputs={
                        ContactBlock({
                          inputProps: this.getInputPropsContact(contactBlockProps),
                          options: {
                            isRequiredEmail: isRequiredEmailAndMobile,
                            isRequiredMobile: isRequiredEmailAndMobile,
                            isDisableMobile,
                          },
                        })
                      }
                    />
                  )
                }
                {
                  isContacts && !(isResidentRF || isInsured) && (
                    <FormSection
                      label={CONTACT_DATA}
                      inputs={
                        ContactBlockNotRF({
                          inputProps: this.getInputPropsContactNotRF(contactBlockNotRFProps),
                          options: {
                            isRequiredEmail: isRequiredEmailAndMobile,
                            isRequiredMobile: isRequiredEmailAndMobile,
                            isDisableMobile,
                          },
                        })
                      }
                    />
                  )
                }
                {
                  isBank && !hideBankInfo && (isBankRU || isInsured) && (
                    <FormSection
                      label={BANK_DETAILS}
                      inputs={(
                        <>
                          {!(isResidentRF || isInsured) ? this.renderIsBankRU({ isBankRU }) : ''}
                          <BankBlock
                            inputProps={this.getInputPropsBank(bankBlockProps)}
                            setFieldValue={this.customSetFieldValue(BLOCK_NAMES.bankBlock)}
                            selectSearch={this.setBankValues}
                            checkNumbers={checkNumbers}
                          />
                        </>
                      )}
                    />
                  )
                }
                {
                  isBank && !hideBankInfo && !(isBankRU || isInsured) && (
                    <FormSection
                      label={BANK_DETAILS}
                      inputs={(
                        <>
                          {this.renderIsBankRU({ isBankRU })}
                          <BankBlockNotRF
                            inputProps={this.getInputPropsBankNotRF(bankBlockNotRFProps)}
                            setFieldValue={this.customSetFieldValue(BLOCK_NAMES.bankBlockNotRF)}
                            selectSearch={this.setBankValues}
                            checkNumbers={checkNumbers}
                          />
                        </>
                      )}
                    />
                  )
                }
              </>
            </Collapse>
            <ErrorMessage message={errorWarning} />
          </div>
        </Form>
      );
    }
  }

  AgentData.propTypes = propTypes;
  AgentData.defaultProps = defaultProps;

  function mapStateToProps(state) {
    const {
      authReducer: {
        clientTestRecordFields,
      },
      nszProduct: {
        nszProductValues,
      },
      kszProduct: {
        kszProductValues,
      },
    } = state;

    return {
      blackAddressInfo: getBlackAddressInfo(state),
      clientTestRecordFields,
      nszProductValues,
      kszProductValues,
    };
  }

  const mapDispatchToProps = (dispatch, ownProps) => ({
    GetError: bindActionCreators(getError, dispatch),
    ResetValidationTerrorist: bindActionCreators(resetValidationTerrorist, dispatch),
    checkNumbers: bindActionCreators(ownProps.checkNumbers, dispatch),
    saveData: bindActionCreators(ownProps.saveData, dispatch),
    changeFieldValue: bindActionCreators(changeAgentFieldValue, dispatch),
    validateFieldValues: bindActionCreators(validateAgentFieldValues, dispatch),
    setTouchField: bindActionCreators(setAgentTouchField, dispatch),
    setAgentType: bindActionCreators(setCurrentAgentType, dispatch),
    setInsuredIsSame: bindActionCreators(changeInsuredIsSame, dispatch),
    setAllTouchFields: bindActionCreators(setAgentAllTouchFields, dispatch),
    setStage: bindActionCreators(setStageProduct, dispatch),
    onChangeAddress: bindActionCreators(setAddressDataAgent, dispatch),
    SetInsuredValues: setInsuredValues,
    ClearStoreAgentData: bindActionCreators(clearStoreAgentData, dispatch),
    ClearBankBlocks: bindActionCreators(clearBankBlocks, dispatch),
  });

  return withCustomRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AgentData));
};
