export default {
  apartment: '88',
  apartmentLiving: '57',
  area: '',
  areaLiving: '',
  birthDate: new Date('1999-12-31T21:00:00.000Z'),
  birthPlace: 'France, Paris',
  city: 'Paris',
  cityLiving: 'Berlin',
  contactPhone: '4444444440',
  country: 'Франция',
  countryLiving: 'Германия',
  documentType: 6,
  email: 'InsurerNotRF@test.ru',
  firstName: 'Jean',
  gender: 1,
  homePhone: '49301234567',
  house: '33',
  houseLiving: '77',
  houseNumber: '123',
  houseNumberLiving: '321',
  index: '75001',
  indexLiving: '10115',
  inn: '321123132132',
  issuedBy: 'French Ministry of the Interior',
  lastName: 'Pierre Olivier Charles',
  mobilePhone: '4915112345678',
  passportNumber: 'A1B2C3D4E5',
  patronymic: '',
  relationship: '',
  republic: '',
  republicLiving: '',
  street: 'Champs-Elysees',
  streetLiving: 'Friedrichstrasse',
  when: new Date('2020-05-04T21:00:00.000Z'),
  workPhone: '49307654321',
  isManualEnter: true,
  isManualEnterLiving: true,
  livingAddressIsSame: false,
  issuedByAccepted: true,
  taxResidenceCountry: 'Франция',
  citizenship: 'Испания',
};
