import { v4 as uuidV4 } from 'uuid';
import _ from 'lodash';
import { FAVORITES_BASKET } from 'consts';
import { STRATEGY_FIELD_NAME } from 'components/_forms/_constructorForms/BasketCreatingForm/components/Strategy/fieldNames';
import {
  GET_PRODUCT_TYPES_TABLE_CONTROL,
  SET_PRODUCT_TYPE_FORM_CREATING,
  GET_OPTION_TYPES,
  GET_OPTION_TYPES_RESTRICTIONS,
  ADD_NEW_STRATEGY,
  DELETE_STRATEGY,
  COPY_STRATEGY,
  CHANGE_CURRENCY,
  GET_TERMS,
  GET_CURRENCIES,
  GET_PAYOUT_FREQUENCY,
  GET_MIN_INSURANCE_SUM_FORM_CREATING,
  CLEAR_MIN_INSURANCE_SUM,
  SAVE_PARTNERS_INS_PRODUCT_ID,
  CALCULATE_COUPON,
  SET_INIT_STATE,
  SET_GENERAL_OPTION_ID,
  CHANGE_GUARANTEE_LEVEL,
  CHANGE_CURRENCY_TABLE_CONTROL,
  CHANGE_GUARANTEE_LEVEL_ERROR,
  GET_PRODUCT_SETTINGS,
  SET_PRODUCT_TYPE_TABLE_CONTROL,
  SET_PRODUCT_ONLY_FAVORITE,
  GET_CALCULATED_PERSON_BASKETS,
  TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKET,
  SELECT_CALCULATED_PERSON_BASKET,
  GET_ASSETS_DESCRIPTION,
  GET_HINTS_FOR_BASKETS,
  GET_HINTS_FOR_READY_BASKETS,
  GET_HINTS_FOR_REGULAR_BASKETS,
  SET_BASKET_FILTER,
  SET_REGULAR_BASKET_FILTER,
  SET_READY_BASKET_FILTER,
  SET_BASKET_SORT,
  SET_REGULAR_BASKET_SORT,
  SET_READY_BASKET_SORT,
  CLEAR_SORTING_AND_FILTERING,
  SET_MAIN_STRATEGY_DATA,
  SET_CHILDREN_STRATEGY_DATA,
  GET_PRODUCT_TYPES_FORM_CREATING,
  GET_REQUEST_DATA_FOR_COPY,
  SET_INIT_STATE_CREATING_FORM,
  GET_MIN_INSURANCE_SUM_TABLES,
  GET_REQUEST_COUNT,
  GET_READY_BASKETS,
  SELECT_READY_BASKET,
  TOGGLE_OPEN_READY_CHILD_BASKET,
  GET_REGULAR_BASKETS,
  SELECT_REGULAR_BASKET,
  TOGGLE_OPEN_REGULAR_CHILD_BASKET,
  REMOVE_LAST_STRATEGIES,
  SELECT_CHILD_CALCULATED_PERSON_BASKET,
  SELECT_CHILD_READY_BASKET,
  SELECT_CHILD_REGULAR_BASKET,
  CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS,
  GET_ACTIVES,
  SET_WARNING_MESSAGE,
  SET_JUST_NOW_EXECUTION_BASKET,
  EXECUTION_CALCULATING_BASKET,
  INS_LINE_ISZ_CALC,
  SET_INIT_STATE_SELECT,
  SET_FAVORITES_DESCRIPTION,
  DELETE_FAVORITES_DESCRIPTION,
  GET_AMOUNT_FAVORITES_BASKET,
  CLOSE_MIN_INSURANCE_SUM_TABLE,
  CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING,
  CHECK_MULTIPLE_ASSETS,
  GET_GENERAL_ASSET_CLASS,
  GET_MAX_GUARANTEE_LEVEL,
  ADD_NEW_GUARANTEED_PAYOUT,
  CHANGE_FIELDS_FOR_NAME,
  DELETE_GUARANTEED_PAYOUT,
  COPY_GUARANTEED_PAYOUTS,
  RECALCULATE_COUPON,
  EXECUTION_CALCULATING_DP_POLICY_BASKET,
  PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET,
  RESET_CALCULATED_STATUS_COUPON,
  CLOSE_EXECUTION_DP_POLICY_POPUP,
  CLEAR_CALCULATED_BASKET,
  CLEAR_READY_BASKET,
  CLEAR_REGULAR_BASKET,
  CHANGE_LOOKBACK_FLAG,
  CHANGE_SUCCESS_FEE_FLAG,
} from '../types';

export const getCountRow = (delimiter = 1) => {
  const minTableRowHeight = 40;

  return Math.ceil((window.innerHeight) / minTableRowHeight / delimiter);
};

const INITIAL_STATE = {
  formCreating: {
    strategyUuids: [
      uuidV4(),
    ],
    strategies: {},
    minInsuranceSum: null,
    calculatingStatus: null,
    isLoading: false,
    guaranteedPayouts: [],
    isUpdateLoading: false,
  },

  refs: {
    productTypes: [],
    productOptions: [],
    productTerms: [],
    productPayouts: [],
    currencies: [],
    selectOptionTypesRestrictions: [],
  },

  settings: {
    fixedIncomeEditable: false,
    fixedIncomePerc: null,
    hideConstrFull: true,
    isIssueBasket: false,
    isValueLessThanOneHalfMillion: true,
  },

  assets: {
    data: [],
    isLoading: false,
  },

  justNowExecutionBasketPayload: {
    id: null,
    refIszOptionTypeCode: null,
    refCurrencyCode: null,
  },

  calculatedPersonBaskets: {
    data: [],
    offset: 0,
    limit: getCountRow(),
    isScrollLoading: false,
    isTableLoading: true,
    selected: null,
    childSelectedIds: {},
    openChildIds: {},
  },

  readyBaskets: {
    data: [],
    offset: 0,
    limit: getCountRow(2),
    isScrollLoading: false,
    isTableLoading: true,
    openChildIds: {},
    childSelectedIds: {},
    selected: null,
  },

  regularBaskets: {
    data: [],
    offset: 0,
    limit: getCountRow(2),
    isScrollLoading: false,
    isTableLoading: false,
    childSelectedIds: {},
    openChildIds: {},
    selected: null,
  },

  sorting: {},
  filtering: {
    filter: {},
    hints: {},
    isLoadingHints: false,
  },

  readyBasketsSorting: {},
  readyBasketsFiltering: {
    readyBasketsFilter: {},
    readyBasketsHints: {},
    isLoadingHints: false,
  },

  regularBasketsSorting: {},
  regularBasketsFiltering: {
    regularBasketsFilter: {},
    regularBasketsHints: {},
    isLoadingHints: false,
  },

  availableQuantityRequests: 0,
  guaranteeLevel: null,
  currencyCode: null,
  guaranteeLevelError: null,
  productTypeCode: null,
  productOptionCode: null,
  partnersInsProductId: null,
  minInsuranceSum: null,
  warningMessage: '',
  isExecutionLoading: false,
  isExecutionDpPolicyLoading: false,
  isPreExecutionDpPolicyLoading: false,
  isExecutionDpPolicyPopup: false,
  isExecutionDpPolicySuccessPopup: false,
  isIszCalcLoading: false,
  productOptionCodePolicy: null,
  currencyCodePolicy: null,
  productTypeCodePolicy: null,
  isOnlyFavorite: false,
  guaranteeLevelPolicy: null,
  maxFavoritesBaskets: 0,
  amountFavoritesBasket: 0,
};

// TODO fix mutation
const COPY_INIT_STATE = _.cloneDeep(INITIAL_STATE);
const getCopyInitState = () => _.cloneDeep(COPY_INIT_STATE);

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_CALCULATED_BASKET:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: [],
        },
      };

    case CLEAR_READY_BASKET:
      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          data: [],
        },
      };

    case CLEAR_REGULAR_BASKET:
      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          data: [],
        },
      };

    case CLOSE_EXECUTION_DP_POLICY_POPUP:
      return {
        ...state,
        isExecutionDpPolicyPopup: false,
        isExecutionDpPolicySuccessPopup: false,
      };

    case PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.start:
      return {
        ...state,
        isPreExecutionDpPolicyLoading: true,
      };

    case PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.fail:
      return {
        ...state,
        isPreExecutionDpPolicyLoading: false,
      };

    case PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.success:
      return {
        ...state,
        productForm: payload.productForm,
        isExecutionDpPolicyPopup: payload.isOpen,
        isPreExecutionDpPolicyLoading: false,
      };

    case EXECUTION_CALCULATING_DP_POLICY_BASKET.start:
      return {
        ...state,
        isExecutionDpPolicyLoading: true,
      };

    case EXECUTION_CALCULATING_DP_POLICY_BASKET.fail:
      return {
        ...state,
        isExecutionDpPolicyLoading: false,
      };

    case EXECUTION_CALCULATING_DP_POLICY_BASKET.success:
      return {
        ...state,
        dpPolicyExecutionUser: payload.user,
        isExecutionDpPolicyPopup: false,
        isExecutionDpPolicySuccessPopup: true,
        isExecutionDpPolicyLoading: false,
      };

    case CALCULATE_COUPON.success:
      return {
        ...state,
        justNowExecutionBasketPayload: {
          id: payload.mainBasketId,
          refIszOptionTypeCode: payload.refIszOptionTypeCode,
          refCurrencyCode: payload.currencyCode,
        },
        formCreating: {
          ...state.formCreating,
          yield1: payload.calculation.yield1,
          yield2: payload.calculation.yield2,
          isCoupon: payload.calculation.isCoupon,
          isKu: payload.calculation.isKu,
          minObjectRestriction: payload.calculation.minObjectRestriction,
          calculatingStatus: payload.status,
          isLoading: false,
          message: payload.message,
        },
      };

    case RECALCULATE_COUPON.success:
      return {
        ...state,
        justNowExecutionBasketPayload: {
          id: payload.mainBasketId,
          refIszOptionTypeCode: payload.refIszOptionTypeCode,
          refCurrencyCode: payload.currencyCode,
        },
        formCreating: {
          ...state.formCreating,
          isLoading: false,
        },
      };

    case GET_PRODUCT_TYPES_TABLE_CONTROL.success:
    case GET_PRODUCT_TYPES_FORM_CREATING.success:
      return {
        ...state,
        refs: {
          ...state.refs,
          productTypes: payload,
        },
      };

    case SAVE_PARTNERS_INS_PRODUCT_ID:
      return {
        ...state,
        partnersInsProductId: payload,
      };

    case GET_OPTION_TYPES.success:
      return {
        ...state,
        refs: {
          ...state.refs,
          productOptions: payload,
        },
      };

    case GET_OPTION_TYPES_RESTRICTIONS.success:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.strategyUuid]: {
              ...state.formCreating.strategies[payload.strategyUuid],
              values: {
                ...state.formCreating.strategies[payload.strategyUuid].values,
                optionTypesRestrictions: payload.data,
              },
            },
          },
        },
      };

    case SET_PRODUCT_TYPE_FORM_CREATING.success:
      return {
        ...state,
        productTypeCode: payload,
      };

    case ADD_NEW_STRATEGY:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategyUuids: [
            ...state.formCreating.strategyUuids,
            payload,
          ],
        },
      };

    case ADD_NEW_GUARANTEED_PAYOUT:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          guaranteedPayouts: [
            ...state.formCreating.guaranteedPayouts,
            payload,
          ],
        },
      };

    case RESET_CALCULATED_STATUS_COUPON: {
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          calculatingStatus: null,
          message: '',
        },
      };
    }

    case COPY_GUARANTEED_PAYOUTS:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          guaranteedPayouts: payload || [],
        },
      };

    case CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          isMinInsuranceSumClicked: false,
        },
      };

    case CLOSE_MIN_INSURANCE_SUM_TABLE:
      return {
        ...state,
        isMinInsuranceSumClicked: false,
      };

    case DELETE_STRATEGY:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategyUuids: state.formCreating.strategyUuids.filter((uuid) => uuid !== payload),
          strategies: {
            ...state.formCreating.strategies,
            [payload]: undefined,
          },
        },
      };

    case COPY_STRATEGY: {
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.newUuid]: _.cloneDeep(state.formCreating.strategies[payload.copyUuid]),
          },
        },
      };
    }

    case CHANGE_CURRENCY.success:
      return {
        ...state,
        currencyCode: payload.currencyCode,
      };

    case GET_TERMS.success:
      return {
        ...state,
        refs: {
          ...state.refs,
          productTerms: payload,
        },
      };

    case GET_CURRENCIES.success:
      return {
        ...state,
        refs: {
          ...state.refs,
          currencies: payload,
        },
      };

    case GET_PAYOUT_FREQUENCY.success:
      return {
        ...state,
        refs: {
          ...state.refs,
          productPayouts: payload,
        },
      };

    case CHANGE_LOOKBACK_FLAG:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.uuid]: {
              ...state.formCreating.strategies[payload.uuid],
              values: {
                ...state.formCreating.strategies[payload.uuid].values,
                lookbackFlag: payload.lookbackFlag,
                lookbackData: payload.lookbackData,
              },
            },
          },
        },
      };

    case GET_MIN_INSURANCE_SUM_FORM_CREATING.success:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          minInsuranceSum: payload.result,
          isMinInsuranceSumClicked: payload.isMinInsuranceSumClicked,
        },
      };

    case CLEAR_MIN_INSURANCE_SUM:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          minInsuranceSum: null,
        },
      };

    case CHANGE_GUARANTEE_LEVEL:
      return {
        ...state,
        guaranteeLevel: payload,
        guaranteeLevelPolicy: payload,
      };

    case SET_GENERAL_OPTION_ID:
      return {
        ...state,
        productOptionCode: payload,
        productOptionCodePolicy: payload,
        sorting: INITIAL_STATE.sorting,
        filtering: INITIAL_STATE.filtering,
      };

    case CHANGE_CURRENCY_TABLE_CONTROL:
      return {
        ...state,
        currencyCode: payload,
        currencyCodePolicy: payload,
      };

    case GET_PRODUCT_SETTINGS.success:
      return {
        ...state,
        settings: payload,
      };

    case CHANGE_GUARANTEE_LEVEL_ERROR:
      return {
        ...state,
        guaranteeLevelError: payload,
      };

    case SET_PRODUCT_TYPE_TABLE_CONTROL:
      return {
        ...state,
        productTypeCode: payload,
        productTypeCodePolicy: payload,
      };

    case SET_PRODUCT_ONLY_FAVORITE:
      return {
        ...state,
        isOnlyFavorite: payload,
      };

    case GET_REGULAR_BASKETS.start: {
      if (payload.isScrollUpdate) {
        return {
          ...state,
          regularBaskets: {
            ...state.regularBaskets,
            isScrollLoading: true,
          },
        };
      }

      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          data: [],
          openChildIds: {},
          childSelectedIds: {},
          selected: null,
          isTableLoading: true,
        },
      };
    }

    case GET_REGULAR_BASKETS.success:
      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          data: payload.data,
          offset: payload.offset,
          limit: payload.limit,
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case GET_REGULAR_BASKETS.fail:
      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          data: [],
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case GET_CALCULATED_PERSON_BASKETS.start: {
      if (payload.isScrollUpdate) {
        return {
          ...state,
          calculatedPersonBaskets: {
            ...state.calculatedPersonBaskets,
            isScrollLoading: true,
          },
        };
      }

      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: [],
          openChildIds: {},
          childSelectedIds: {},
          selected: null,
          isTableLoading: true,
        },
      };
    }

    case GET_CALCULATED_PERSON_BASKETS.success:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: payload.data,
          offset: payload.offset,
          limit: payload.limit,
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case GET_CALCULATED_PERSON_BASKETS.fail:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: [],
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKET:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          openChildIds: {
            ...state.calculatedPersonBaskets.openChildIds,
            [payload.mainId]: payload.basket,
          },
        },
      };

    case TOGGLE_OPEN_REGULAR_CHILD_BASKET:
      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          openChildIds: {
            ...state.regularBaskets.openChildIds,
            [payload.mainId]: payload.status,
          },
        },
      };

    case TOGGLE_OPEN_READY_CHILD_BASKET:
      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          openChildIds: {
            ...state.readyBaskets.openChildIds,
            [payload.mainId]: payload.status,
          },
        },
      };

    case SELECT_CALCULATED_PERSON_BASKET:
      return {
        ...state,
        selected: payload,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          selected: payload,
        },
      };

    case SELECT_REGULAR_BASKET:
      return {
        ...state,
        selected: payload,
        regularBaskets: {
          ...state.regularBaskets,
          selected: payload,
        },
      };

    case SELECT_READY_BASKET:
      return {
        ...state,
        selected: payload,
        readyBaskets: {
          ...state.readyBaskets,
          selected: payload,
        },
      };

    case SET_FAVORITES_DESCRIPTION.start:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: state.calculatedPersonBaskets.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                isFavorite: !item.isFavorite,
              };
            }

            return item;
          }),
        },
        amountFavoritesBasket: state.amountFavoritesBasket + 1,
      };

    case SET_FAVORITES_DESCRIPTION.fail:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: state.calculatedPersonBaskets.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                isFavorite: !item.isFavorite,
              };
            }

            return item;
          }),
        },
        amountFavoritesBasket: state.amountFavoritesBasket - 1,
      };

    case DELETE_FAVORITES_DESCRIPTION.start:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: state.calculatedPersonBaskets.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                isFavorite: !item.isFavorite,
              };
            }

            return item;
          }),
        },
        amountFavoritesBasket: state.amountFavoritesBasket - 1,
      };

    case DELETE_FAVORITES_DESCRIPTION.fail:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          data: state.calculatedPersonBaskets.data.map((item) => {
            if (item.id === payload.id) {
              return {
                ...item,
                isFavorite: !item.isFavorite,
              };
            }

            return item;
          }),
        },
        amountFavoritesBasket: state.amountFavoritesBasket + 1,
      };

    case GET_AMOUNT_FAVORITES_BASKET.start:
      return {
        ...state,
        amountFavoritesBasket: FAVORITES_BASKET.amountFavoritesBasketDefault,
        maxFavoritesBaskets: FAVORITES_BASKET.maxFavoritesBasketsDefault,
      };

    case GET_AMOUNT_FAVORITES_BASKET.success:
      return {
        ...state,
        amountFavoritesBasket: payload.amountFavoritesBasket,
        maxFavoritesBaskets: payload.maxFavoritesBaskets,
      };

    case GET_AMOUNT_FAVORITES_BASKET.fail:
      return {
        ...state,
        amountFavoritesBasket: FAVORITES_BASKET.amountFavoritesBasketDefault,
        maxFavoritesBaskets: FAVORITES_BASKET.maxFavoritesBasketsDefault,
      };

    case GET_ASSETS_DESCRIPTION.start:
      return {
        ...state,
        assets: {
          ...state.assets,
          isLoading: true,
        },
      };

    case GET_ASSETS_DESCRIPTION.success:
      return {
        ...state,
        assets: {
          ...state.assets,
          data: payload,
          isLoading: false,
        },
      };

    case GET_ASSETS_DESCRIPTION.fail:
      return {
        ...state,
        assets: {
          ...state.assets,
          isLoading: false,
        },
      };

    case GET_HINTS_FOR_BASKETS.start:
      return {
        ...state,
        filtering: {
          ...state.filtering,
          isLoadingHints: true,
        },
      };

    case GET_HINTS_FOR_BASKETS.fail:
      return {
        ...state,
        filtering: {
          ...state.filtering,
          isLoadingHints: false,
        },
      };

    case GET_HINTS_FOR_READY_BASKETS.start:
      return {
        ...state,
        readyBasketsFiltering: {
          ...state.readyBasketsFiltering,
          isLoadingHints: true,
        },
      };

    case GET_HINTS_FOR_READY_BASKETS.fail:
      return {
        ...state,
        readyBasketsFiltering: {
          ...state.readyBasketsFiltering,
          isLoadingHints: false,
        },
      };

    case GET_HINTS_FOR_READY_BASKETS.success:
      return {
        ...state,
        readyBasketsFiltering: {
          ...state.readyBasketsFiltering,
          isLoadingHints: false,
          readyBasketsHints: {
            ...state.readyBasketsFiltering.readyBasketsHints,
            [payload.name]: payload.data,
          },
        },
      };

    case GET_HINTS_FOR_REGULAR_BASKETS.start:
      return {
        ...state,
        regularBasketsFiltering: {
          ...state.regularBasketsFiltering,
          isLoadingHints: true,
        },
      };

    case GET_HINTS_FOR_REGULAR_BASKETS.fail:
      return {
        ...state,
        regularBasketsFiltering: {
          ...state.regularBasketsFiltering,
          isLoadingHints: false,
        },
      };

    case GET_HINTS_FOR_REGULAR_BASKETS.success:
      return {
        ...state,
        regularBasketsFiltering: {
          ...state.regularBasketsFiltering,
          isLoadingHints: false,
          regularBasketsHints: {
            ...state.regularBasketsFiltering.regularBasketsHints,
            [payload.name]: payload.data,
          },
        },
      };

    case GET_HINTS_FOR_BASKETS.success:
      return {
        ...state,
        filtering: {
          ...state.filtering,
          isLoadingHints: false,
          hints: {
            ...state.filtering.hints,
            [payload.name]: payload.data,
          },
        },
      };

    case SET_BASKET_FILTER:
      return {
        ...state,
        filtering: {
          ...state.filtering,
          filter: {
            ...state.filtering.filter,
            [payload.name]: payload.value,
          },
        },
      };

    case SET_REGULAR_BASKET_FILTER:
      return {
        ...state,
        regularBasketsFiltering: {
          ...state.regularBasketsFiltering,
          regularBasketsFilter: {
            ...state.regularBasketsFiltering.regularBasketsFilter,
            [payload.name]: payload.value,
          },
        },
      };

    case SET_READY_BASKET_FILTER:
      return {
        ...state,
        readyBasketsFiltering: {
          ...state.readyBasketsFiltering,
          readyBasketsFilter: {
            ...state.readyBasketsFiltering.readyBasketsFilter,
            [payload.name]: payload.value,
          },
        },
      };

    case SET_BASKET_SORT:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [payload.name]: payload,
        },
      };

    case SET_REGULAR_BASKET_SORT:
      return {
        ...state,
        regularBasketsSorting: {
          ...state.regularBasketsSorting,
          [payload.name]: payload,
        },
      };

    case SET_READY_BASKET_SORT:
      return {
        ...state,
        readyBasketsSorting: {
          ...state.readyBasketsSorting,
          [payload.name]: payload,
        },
      };

    case SET_INIT_STATE_SELECT:
      return {
        ...state,
        formCreating: {
          strategyUuids: [
            uuidV4(),
          ],
          strategies: {},
          minInsuranceSum: null,
          calculatingStatus: null,
          isLoading: false,
          guaranteedPayouts: [],
        },

        refs: {
          productTypes: [],
          productOptions: [],
          productTerms: [],
          productPayouts: [],
          currencies: [],
        },
        settings: {
          fixedIncomeEditable: false,
          fixedIncomePerc: null,
          hideConstrFull: true,
          isIssueBasket: false,
          isValueLessThanOneHalfMillion: true,
        },
        availableQuantityRequests: 0,
        guaranteeLevelError: null,
        minInsuranceSum: null,
        warningMessage: '',
        isExecutionLoading: false,
        isIszCalcLoading: false,
        productOptionCode: null,
        productTypeCode: null,
        guaranteeLevel: null,

        assets: {
          data: [],
          isLoading: false,
        },

        justNowExecutionBasketPayload: {
          id: null,
          refIszOptionTypeCode: null,
          refCurrencyCode: null,
        },

        calculatedPersonBaskets: {
          data: [],
          offset: 0,
          limit: getCountRow(),
          isScrollLoading: false,
          isTableLoading: true,
          selected: null,
          childSelectedIds: {},
          openChildIds: {},
        },

        readyBaskets: {
          data: [],
          offset: 0,
          limit: getCountRow(2),
          isScrollLoading: false,
          isTableLoading: true,
          openChildIds: {},
          childSelectedIds: {},
          selected: null,
        },

        regularBaskets: {
          data: [],
          offset: 0,
          limit: getCountRow(2),
          isScrollLoading: false,
          isTableLoading: false,
          childSelectedIds: {},
          openChildIds: {},
          selected: null,
        },

        sorting: {},
        filtering: {
          filter: {},
          hints: {},
          isLoadingHints: false,
        },

        readyBasketsSorting: {},
        readyBasketsFiltering: {
          readyBasketsFilter: {},
          readyBasketsHints: {},
          isLoadingHints: false,
        },

        regularBasketsSorting: {},
        regularBasketsFiltering: {
          regularBasketsFilter: {},
          regularBasketsHints: {},
          isLoadingHints: false,
        },
      };

    case SET_INIT_STATE:
      return INITIAL_STATE;

    case CLEAR_SORTING_AND_FILTERING:
      return {
        ...state,
        sorting: INITIAL_STATE.sorting,
        filtering: INITIAL_STATE.filtering,
        regularBasketsSorting: INITIAL_STATE.regularBasketsSorting,
        readyBasketsSorting: INITIAL_STATE.readyBasketsSorting,
        readyBasketsFiltering: INITIAL_STATE.readyBasketsFiltering,
        regularBasketsFiltering: INITIAL_STATE.regularBasketsFiltering,
        isOnlyFavorite: INITIAL_STATE.isOnlyFavorite,
      };

    case SET_MAIN_STRATEGY_DATA:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [state.formCreating.strategyUuids[0]]: {
              ...state.formCreating.strategies[state.formCreating.strategyUuids[0]],
              values: payload,
            },
          },
        },
      };

    case SET_CHILDREN_STRATEGY_DATA: {
      const { uuids, childrenStrategies } = payload.reduce((acc, item) => ({
        uuids: [
          ...acc.uuids,
          item.uuid,
        ],
        childrenStrategies: {
          ...acc.childrenStrategies,
          [item.uuid]: { values: item.data },
        },
      }), {
        uuids: [],
        childrenStrategies: {},
      });

      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategyUuids: [
            ...state.formCreating.strategyUuids,
            ...uuids,
          ],
          strategies: {
            ...state.formCreating.strategies,
            ...childrenStrategies,
          },
        },
      };
    }

    case RECALCULATE_COUPON.start:
    case GET_REQUEST_DATA_FOR_COPY.start:
    case CALCULATE_COUPON.start:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          isLoading: true,
        },
      };

    case RECALCULATE_COUPON.fail:
    case GET_REQUEST_DATA_FOR_COPY.fail:
    case GET_REQUEST_DATA_FOR_COPY.success:
    case CALCULATE_COUPON.fail:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          isLoading: false,
        },
      };

    case SET_INIT_STATE_CREATING_FORM:

      return {
        ...state,
        formCreating: getCopyInitState().formCreating,
      };

    case GET_MIN_INSURANCE_SUM_TABLES.success:
      return {
        ...state,
        minInsuranceSum: payload.result,
        isMinInsuranceSumClicked: payload.isMinInsuranceSumClicked,
      };

    case GET_REQUEST_COUNT.success:
      return {
        ...state,
        availableQuantityRequests: payload.count,
      };

    case GET_READY_BASKETS.start: {
      if (payload.isScrollUpdate) {
        return {
          ...state,
          readyBaskets: {
            ...state.readyBaskets,
            isScrollLoading: true,
          },
        };
      }

      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          data: [],
          openChildIds: {},
          childSelectedIds: {},
          selected: null,
          isTableLoading: true,
        },
      };
    }

    case GET_READY_BASKETS.success:
      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          data: payload.data,
          offset: payload.offset,
          limit: payload.limit,
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case GET_READY_BASKETS.fail:
      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          data: [],
          isScrollLoading: false,
          isTableLoading: false,
        },
      };

    case REMOVE_LAST_STRATEGIES: {
      const {
        formCreating: {
          strategyUuids,
          strategies,
        },
      } = state;
      const actualStrategyUuids = strategyUuids.slice(0, strategyUuids.length - payload);

      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategyUuids: actualStrategyUuids,
          strategies: actualStrategyUuids.reduce((acc, item) => ({
            ...acc,
            [item]: strategies[item],
          }), {}),
        },
      };
    }

    case SELECT_CHILD_CALCULATED_PERSON_BASKET:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          childSelectedIds: {
            ...state.calculatedPersonBaskets.childSelectedIds,
            [payload.id]: payload.basket,
          },
        },
      };

    case SELECT_CHILD_READY_BASKET:
      return {
        ...state,
        readyBaskets: {
          ...state.readyBaskets,
          childSelectedIds: {
            ...state.readyBaskets.childSelectedIds,
            [payload.id]: payload.basket,
          },
        },
      };

    case SELECT_CHILD_REGULAR_BASKET:
      return {
        ...state,
        regularBaskets: {
          ...state.regularBaskets,
          childSelectedIds: {
            ...state.regularBaskets.childSelectedIds,
            [payload.id]: payload.basket,
          },
        },
      };

    case CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS:
      return {
        ...state,
        calculatedPersonBaskets: {
          ...state.calculatedPersonBaskets,
          openChildIds: {},
          childSelectedIds: {},
          selected: null,
        },
      };

    case GET_ACTIVES.success:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.strategyUuid]: {
              ...state.formCreating.strategies[payload.strategyUuid],
              values: {
                ...state.formCreating.strategies[payload.strategyUuid].values,
                [STRATEGY_FIELD_NAME.ASSET_OPTIONS]: payload.data,
              },
            },
          },
        },
      };

    case EXECUTION_CALCULATING_BASKET.start:
      return {
        ...state,
        isExecutionLoading: true,
      };

    case EXECUTION_CALCULATING_BASKET.fail:
    case EXECUTION_CALCULATING_BASKET.success:
      return {
        ...state,
        isExecutionLoading: false,
      };

    case INS_LINE_ISZ_CALC.start:
      return {
        ...state,
        isIszCalcLoading: true,
      };

    case INS_LINE_ISZ_CALC.fail:
    case INS_LINE_ISZ_CALC.success:
      return {
        ...state,
        isIszCalcLoading: false,
      };

    case SET_WARNING_MESSAGE:
      return {
        ...state,
        warningMessage: payload,
      };

    case SET_JUST_NOW_EXECUTION_BASKET:
      return {
        ...state,
        justNowExecutionBasketPayload: payload,
      };

    case CHECK_MULTIPLE_ASSETS.success:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.uuid]: {
              ...state.formCreating.strategies[payload.uuid],
              values: {
                ...state.formCreating.strategies[payload.uuid].values,
                [STRATEGY_FIELD_NAME.IS_MULTIPLE_ASSET_CLASS]: payload.data,
              },
            },
          },
        },
      };

    case GET_GENERAL_ASSET_CLASS.success:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.uuid]: {
              ...state.formCreating.strategies[payload.uuid],
              values: {
                ...state.formCreating.strategies[payload.uuid].values,
                [STRATEGY_FIELD_NAME.ASSET_CLASS]: payload.data,
              },
            },
          },
        },
      };

    case GET_MAX_GUARANTEE_LEVEL.success:
      return {
        ...state,
        maxGuaranteeLevel: payload,
      };

    case CHANGE_FIELDS_FOR_NAME:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          guaranteedPayouts: state.formCreating.guaranteedPayouts.map((item, index) => {
            if (payload.index === index) {
              return {
                ...item,
                num: payload.index,
                [payload.name]: payload.value,
              };
            }

            return item;
          }),
        },
      };

    case DELETE_GUARANTEED_PAYOUT:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          guaranteedPayouts: state.formCreating.guaranteedPayouts.filter(
            (item, index) => payload.index !== index,
          ),
        },
      };

    case CHANGE_SUCCESS_FEE_FLAG:
      return {
        ...state,
        formCreating: {
          ...state.formCreating,
          strategies: {
            ...state.formCreating.strategies,
            [payload.uuid]: {
              ...state.formCreating.strategies[payload.uuid],
              values: {
                ...state.formCreating.strategies[payload.uuid].values,
                successFeeFlag: payload.successFeeFlag,
                successFee: payload.successFeeFlag ? payload.successFee : null,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};
