const PERSON_BLOCK_NAMES = {
  lastName: 'lastName',
  firstName: 'firstName',
  patronymic: 'patronymic',
  gender: 'gender',
  birthDate: 'birthDate',
  birthPlace: 'birthPlace',
  citizenship: 'citizenship',
  taxResidenceCountry: 'taxResidenceCountry',
};

const DOCUMENTS_BLOCK_NAMES = {
  documentType: 'documentType',
  issuedBy: 'issuedBy',
  serial: 'serial',
  when: 'when',
  validUntil: 'validUntil',
  passportNumber: 'passportNumber',
  kp: 'kp',
};

const PAYMENT_SUM_NAMES = {
  deathAnyReason: 'deathAnyReason',
  deathAccident: 'deathAccident',
  injuryAccident: 'injuryAccident',
  hospitalAccident: 'hospitalAccident',
  disabilityOusv: 'disabilityOusv',
  disabilityReason: 'disabilityReason',
  criticalDiseases: 'criticalDiseases',
};

const INN_SNILS_BLOCK_NAMES = {
  inn: 'inn',
  snils: 'snils',
};

const ADRESS_BLOCK_NAMES = {
  addressFias: 'addressFias',
  fiasId: 'fiasId',
  kladId: 'kladId',
  country: 'country',
  index: 'index',
  republic: 'republic',
  area: 'area',
  city: 'city',
  street: 'street',
  house: 'house',
  houseNumber: 'houseNumber',
  apartment: 'apartment',

  addressFiasLiving: 'addressFiasLiving',
  fiasIdLiving: 'fiasIdLiving',
  kladIdLiving: 'kladIdLiving',
  countryLiving: 'countryLiving',
  indexLiving: 'indexLiving',
  republicLiving: 'republicLiving',
  areaLiving: 'areaLiving',
  cityLiving: 'cityLiving',
  streetLiving: 'streetLiving',
  houseLiving: 'houseLiving',
  houseNumberLiving: 'houseNumberLiving',
  apartmentLiving: 'apartmentLiving',
};

const CONTACTS_BLOCK_NAMES = {
  mobilePhone: 'mobilePhone',
  homePhone: 'homePhone',
  workPhone: 'workPhone',
  contactPhone: 'contactPhone',
  email: 'email',
};

const BANK_BLOCK_NAMES = {
  bankBic: 'bic',
  bankName: 'bankName',
  bankInn: 'bankInn',
  bankKpp: 'kpp',
  correctionAccount: 'corAccount',
  checkingAccount: 'curAccount',
  bankInnSwift: 'bankInnSwift',
  ibanAccountNumber: 'ibanAccountNumber',
  swift: 'swift',
  currency: 'currency',
  recipientFullName: 'recipientFullName',
  recipientAddress: 'recipientAddress',
  ibBankSwift: 'ibBankSwift',
  ibAccountNum: 'ibAccountNum',
  ibRuAccountNum: 'ibRuAccountNum',
};

const PRODUCT_FORM_NAMES = {
  policySum: 'policySum',
  productPeriod: 'productPeriod',
};

const RELATIONSHIP_NAME = {
  relationship: 'relationship',
};

const EXPORT_1C_NAMES = {
  exportPath: 'exportPath',
  partnerId: 'partnerId',
  dateBeg: 'dateBeg',
  dateEnd: 'dateEnd',
  statuses: 'statuses',
  overwrite: 'overwrite',
};

const INPUT_NAMES = {
  ...EXPORT_1C_NAMES,
  ...PERSON_BLOCK_NAMES,
  ...DOCUMENTS_BLOCK_NAMES,
  ...ADRESS_BLOCK_NAMES,
  ...PRODUCT_FORM_NAMES,
  ...INN_SNILS_BLOCK_NAMES,
  ...CONTACTS_BLOCK_NAMES,
  ...BANK_BLOCK_NAMES,
  ...RELATIONSHIP_NAME,
  ...PAYMENT_SUM_NAMES,
};

export default INPUT_NAMES;
