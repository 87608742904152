import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { TextInput, SelectInput } from 'components/_deprecated';
import {
  LANG_DICTIONARY,
  INPUT_NAMES,
} from 'consts';
import { getInputProps, getOptionsForSelect } from 'helpers';
import { searchBank, resetSearchBank } from 'redux/rootActions';
import CustomCheckBox from 'components/_buttons/CustomCheckBox';
import { Form } from 'semantic-ui-react';

import './styles.scss';
import block from 'bem-cn';
import { getOptions } from 'redux/actions';

const b = block('bank-not-rf-block');

const {
  RECIPIENT_BANK_SWIFT,
  INN_IF_AVAILABLE,
  IBAN_ACCOUNT_NUMBER,
  DEFAULT_CURRENCY,
  USE_INTERMEDIARY_BANK,
  RECIPIENT_FULL_NAME,
  RECIPIENT_ADDRESS,
  INTERMEDIARY_BANK_ACCOUNT_NUMBER,
  INTERMEDIARY_BANK_SWIFT,
  INTERMEDIARY_BANK_RUSSIA_ACCOUNT_NUMBER,
} = LANG_DICTIONARY;

const defaultProps = {
  props: {},
  isLoading: false,
  searchResult: null,
  onSearch: null,
  selectSearch: null,
  resetSearch: null,
  setFieldValue: null,
  checkNumbers: () => null,
  inputProps: {},
  currencies: {},
  getOptionList: null,
};

const propTypes = {
  props: PropTypes.shape({
    modifiedProps: PropTypes.func,
  }),
  inputProps: PropTypes.object,
  resetSearch: PropTypes.func,
  selectSearch: PropTypes.func,
  setFieldValue: PropTypes.func,
  checkNumbers: PropTypes.func,
  onSearch: PropTypes.func,
  searchResult: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  currencies: PropTypes.object,
  getOptionList: PropTypes.func,
};

class BankBlockNotRF extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDisabledFields: false,
    };
    const { getOptionList } = this.props;
    getOptionList('currencies');
  }

  handleResultSelect = async (e, { result }) => {
    const { resetSearch, selectSearch, setFieldValue } = this.props;
    const { data, data: { bic, name: { short } } } = result;
    setFieldValue(INPUT_NAMES.bankBic, bic);
    setFieldValue(INPUT_NAMES.bankName, short);
    selectSearch(data);
    resetSearch();
    this.setState({ isDisabledFields: true });
  };

  handleSearchChange = (e, { value }) => {
    const { onSearch, setFieldValue } = this.props;
    setFieldValue(INPUT_NAMES.bankBic, value);
    onSearch(value);
  };

  handleSearchNameChange = (e, { value }) => {
    const { onSearch, setFieldValue } = this.props;
    setFieldValue(INPUT_NAMES.bankName, value);
    onSearch(value);
  };

  render() {
    const {
      inputProps,
      inputProps: {
        setFieldValue,
        values: {
          swift,
          recipientFullName,
          recipientAddress,
          ibBankSwift,
          ibAccountNum,
          ibRuAccountNum,
          intermediaryBank,
          currency,
        },
      },
      currencies,
    } = this.props;

    const { isDisabledFields } = this.state;

    return (
      <Container>
        <Row>
          <Col md="3" ms="12">
            <SelectInput
              name={INPUT_NAMES.currency}
              options={getOptionsForSelect(currencies
                .currencies?.sort((a, d) => a.localeCompare(d)))}
              placeholder={DEFAULT_CURRENCY}
              required
              {...inputProps}
              value={currency}
            />
          </Col>
          <Col md="3" sm="12">
            <TextInput
              name={INPUT_NAMES.swift}
              placeholder={RECIPIENT_BANK_SWIFT}
              onResultSelect={this.handleResultSelect}
              value={swift}
              {...getInputProps(this.props, INPUT_NAMES.swift)}
              required
            />
          </Col>
          <Col md="6" sm="12">
            <TextInput
              name={INPUT_NAMES.ibanAccountNumber}
              placeholder={IBAN_ACCOUNT_NUMBER}
              disabled={isDisabledFields}
              {...getInputProps(this.props, INPUT_NAMES.ibanAccountNumber)}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" ms="12">
            <TextInput
              name={INPUT_NAMES.recipientFullName}
              placeholder={RECIPIENT_FULL_NAME}
              value={recipientFullName}
              disabled={isDisabledFields}
              {...getInputProps(this.props, INPUT_NAMES.recipientFullName)}
              required
            />
          </Col>
          <Col md="6" ms="12">
            <TextInput
              name={INPUT_NAMES.recipientAddress}
              placeholder={RECIPIENT_ADDRESS}
              value={recipientAddress}
              disabled={isDisabledFields}
              {...getInputProps(this.props, INPUT_NAMES.recipientAddress)}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="12">
            <TextInput
              name={INPUT_NAMES.bankInnSwift}
              placeholder={INN_IF_AVAILABLE}
              disabled={isDisabledFields}
              {...getInputProps(this.props, INPUT_NAMES.bankInnSwift)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" sm="12">
            <Form.Field className={b('bankBlockCheckbox')}>
              <CustomCheckBox
                name="intermediaryBank"
                checked={intermediaryBank}
                onChange={(name, checked) => setFieldValue('intermediaryBank', !checked)}
              />
              <p>
                {USE_INTERMEDIARY_BANK}
              </p>
            </Form.Field>
          </Col>
        </Row>
        {intermediaryBank && (
          <>
            <Row>
              <Col md="3" sm="12">
                <TextInput
                  name={INPUT_NAMES.ibBankSwift}
                  placeholder={INTERMEDIARY_BANK_SWIFT}
                  value={ibBankSwift}
                  {...getInputProps(this.props, INPUT_NAMES.ibBankSwift)}
                  required
                />
              </Col>
              <Col md="9" sm="12">
                <TextInput
                  name={INPUT_NAMES.ibAccountNum}
                  placeholder={INTERMEDIARY_BANK_ACCOUNT_NUMBER}
                  value={ibAccountNum}
                  {...getInputProps(this.props, INPUT_NAMES.ibAccountNum)}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <TextInput
                  name={INPUT_NAMES.ibRuAccountNum}
                  placeholder={INTERMEDIARY_BANK_RUSSIA_ACCOUNT_NUMBER}
                  value={ibRuAccountNum}
                  {...getInputProps(this.props, INPUT_NAMES.ibRuAccountNum)}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

function mapStateToProps({
  bank: {
    bankSearchResult,
    isLoading,
  },
  helperReducer: {
    currencies,
  },
}) {
  return {
    searchResult: bankSearchResult,
    isLoading,
    currencies,
  };
}

BankBlockNotRF.defaultProps = defaultProps;
BankBlockNotRF.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    onSearch: searchBank,
    resetSearch: resetSearchBank,
    getOptionList: getOptions,
  },
)(BankBlockNotRF);
