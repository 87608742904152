import {
  SET_TOUCH_FIELD,
  VALIDATION_FIELD,
  CHANGE_FIELD_VALUE,
  SET_CURRENT_AGENT_TYPE,
  CHANGE_INSURED_IS_SAME,
  SET_ALL_TOUCHED_FIELD,
  CLEAR_AGENT_ERRORS,
  CLEAR_AGENT_DATA,
  CHANGE_DISABLE_INSURED_IS_SAME,
  COPY_CONTRACT,
  SET_ADDRESS_DATA_AGENT,
  CLEAR_BANK_BLOCKS,
} from '../types';

export const setAddressDataAgent = (data, form, blockName) => ({
  type: SET_ADDRESS_DATA_AGENT,
  payload: { data, form, blockName },
});

export const changeAgentFieldValue = ({
  name,
  value,
  blockName,
  form,
}) => ({
  type: CHANGE_FIELD_VALUE,
  payload: {
    name,
    value,
    blockName,
    form,
  },
});

export const validateAgentFieldValues = (errors, form) => ({
  type: VALIDATION_FIELD,
  payload: { errors, form },
});

export const setAgentTouchField = ({
  name,
  value,
  blockName,
  form,
}) => ({
  type: SET_TOUCH_FIELD,
  payload: {
    name,
    value,
    blockName,
    form,
  },
});

export const setCurrentAgentType = (type) => ({
  type: SET_CURRENT_AGENT_TYPE,
  payload: type,
});

export const changeInsuredIsSame = (value) => ({
  type: CHANGE_INSURED_IS_SAME,
  payload: value,
});

export const setAgentAllTouchFields = (form) => ({
  type: SET_ALL_TOUCHED_FIELD,
  payload: form,
});

export const clearAgentErrors = (form) => ({
  type: CLEAR_AGENT_ERRORS,
  payload: form,
});

export const clearStoreAgentData = () => ({ type: CLEAR_AGENT_DATA });

export const clearBankBlocks = (form) => ({ type: CLEAR_BANK_BLOCKS, payload: form });

export const changeDisableInsuredIsSame = (value) => ({
  type: CHANGE_DISABLE_INSURED_IS_SAME,
  payload: value,
});

export const getCopyNszContract = (id, history) => ({
  type: COPY_CONTRACT.request,
  payload: { id, history },
});
