/* eslint-disable no-useless-escape */

const REGEXP = {
  CYRILLIC: /^[-а-яА-ЯёЁ]+$/,
  CYRILLIC_LATIN: /^[ёЁа-яА-Я-a-zA-Z\s]+$/,
  CYRILLIC_LATIN_NUMBERS: /^[#№0-9ёЁа-яА-Яa-zA-Z\s]+$/,
  CYRILLIC_LATIN_NUMBERS_SIMBOLS: /^[#№0-9ёЁа-яА-Яa-zA-Z\s."'/)(-]+$/,
  LATIN_UPPERCASE_LETTERS_AND_DIGITS_REGEX: /^[A-Z0-9]+$/,
  ADDRESS_SYMBOLS_NOT_RF: /^[A-Za-z0-9 ,."'/—№-]+$/,
  USER_NAME: /^[а-яА-ЯЁё]*(?:-[а-яА-ЯЁё]*)?(?:\s[а-яА-ЯЁё]*)?(?:\s-\s[а-яА-ЯЁё]*)?$/,
  USER_NAME_WITH_CYRILLIC_LATIN: /^[а-яА-ЯЁёa-zA-Z]*(?:-[а-яА-ЯЁёa-zA-Z]*)?(?:\s[а-яА-ЯЁёa-zA-Z]*)?(?:\s-\s[а-яА-ЯЁёa-zA-Z]*)?$/,
  USER_NAME_WITH_BRACKETS: /^[а-яА-ЯЁё()]*(?:-[а-яА-ЯЁё()]*)?(?:\s[а-яА-ЯЁё()]*)?(?:\s-\s[а-яА-ЯЁё()]*)?$/,
  USER_NAME_WITH_LATIN_AND_BRACKETS: /^(?:[а-яА-ЯЁёa-zA-Z()]+(?:\s(?!\s)[а-яА-ЯЁёa-zA-Z()()\-]*)?)*$/,
  FOREIGN_NAME: /^[A-Za-z\s]+$/,
  BIRTH_PLACE: /^[/\-.,а-я-0-9А-ЯёЁ\s]+$/,
  BIRTH_PLACE_NOT_RF: /^[/\-.,а-я-0-9А-ЯёЁa-zA-Z\s]+$/,
  PHONE_LENGTH: /^[+0-9]{8,12}$/,
  PHONE_MOBILE: /^\+7\([\d]{3}\)[\d-]{6,10}$/,
  PHONE_HOME: /^8\([\d]{3}\)[\d-]{8,12}$/,
  NUMBERS: /^[0-9]+$/,
  ONLY_NUMBERS: /[^0-9]/g,
  ONLY_NUMBERS_AND_COLON: /([^:0-9])/g,
  MAX_TWENTY: /\d{20,}/,
  MIN_SIX: /\d{6,}/,
  MIN_FOUR: /\d{4,}/,
  MIN_FIVE: /\d{5,}/,
  MIN_TWELVE: /\d{12,}/,
  KP: /^[-0-9]{6,7}$/,
  ALL_BUT_NUMBERS: /[^0-9]/g,
  ALL_BUT_NUMBERS_AND_POINT: /[^0-9.]+|\.(?=.*\.)/g,
  MORE_THAN_ONE_DECIMAL: /^(\d+\.?\d{0,1})\d*$/,
  FIRST_CHAR: /(^|\s|-)\S/g,
  SQUARE_BRACKETS: /[[\]]/g,
  SALES_TEMPLATE: /\/sales\/\d{1,}/,
  ROUTE_TEMPLATE: /\/\d+/g,
  CONTRACT_ID: /[^_\d]/g,
  COMMA_GLOBAL: /,/g,
  EMAIL_EN: /^[a-zA-Z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+@[a-zA-Z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+\.[a-zA-Z\d]+$/i,
  EMAIL_RU: /^[а-яА-Я\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+@[а-яА-Я\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+\.[а-яА-Я\d]+$/i,
  EMAIL_RU_DOMAIN_EN: /^[a-zA-Z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+@[а-яА-Я\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.]+\.[а-яА-Я\d]+$/i,
  POINT_IS_REPEATED: /\.{2,}/,
  SEPARATOR_THOUSANDS: /(^\d{1,3}|\d{3})(?=(?:\d{3})+(?:$|\.))/g,
  NUMBERS_CYRILLIC_LATIN_DEF: /^[0-9A-Za-zА-Яа-я-№\s]+$/,
  IP_ADDRESS: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
  EDIT_BASKET_PAGE: /\/product\/constructor-isz\/edit/,
  BRACKETS_CONTENT: /\s\([A-Za-z0-9А-Яа-я\s]+\)/,
  NUMBER_UP_TO_TWO_DECIMAL: /^\d+,?\d{0,2}$/,
  SPACE_NUMBER: /(\d)(?=(\d\d\d)+([^\d]|$))/g,
  SPACES_TABS_LINE_BREAKS: /\s/gm,
  SPACES_TABS_LINE_BREAKS_START_AND_END: /^\s+|\s+$/g,
  SPACES_TABS_LINE_BREAKS_BETWEEN_WORDS: /\s+/g,
};

export default REGEXP;
