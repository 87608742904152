const blockNames = {
  addressBlock: 'addressBlock',
  addressBlockLiving: 'addressBlockLiving',
  personBlock: 'personBlock',
  documentBlock: 'documentBlock',
  bankBlock: 'bankBlock',
  bankBlockNotRF: 'bankBlockNotRF',
  innSnilsBlock: 'innSnilsBlock',
  contactBlock: 'contactBlock',
  relationBlock: 'relationBlock',
  addressBlockNotRF: 'addressBlockNotRF',
  contactBlockNotRF: 'contactBlockNotRF',
  addressBlockLivingNotRF: 'addressBlockLivingNotRF',
  documentBlockNotRF: 'documentBlockNotRF',
};

export default blockNames;
