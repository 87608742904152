import { createSelector } from 'reselect';
import { BLOCK_NAMES } from 'consts';

const getMainPath = (state, form) => state.agentData[form];

/* eslint-disable */
const getAgentPersonBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.personBlock];
const getAgentPersonBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.personBlock];
const getAgentPersonBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.personBlock];
const getAgentDocumentBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.documentBlock];
const getAgentDocumentBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.documentBlock];
const getAgentDocumentBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.documentBlock];
const getAgentAddressBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlock];
const getAgentAddressBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlock];
const getAgentAddressBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlock];
const getAgentBankBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.bankBlock];
const getAgentBankBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.bankBlock];
const getAgentBankBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.bankBlock];
const getAgentAddressLivingBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlockLiving];
const getAgentAddressLivingBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlockLiving];
const getAgentAddressLivingBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlockLiving];
const getAgentInnSnilsBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.innSnilsBlock];
const getAgentInnSnilsBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.innSnilsBlock];
const getAgentInnSnilsBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.innSnilsBlock];
const getAgentContactBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.contactBlock];
const getAgentContactBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.contactBlock];
const getAgentContactBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.contactBlock];
const getAgentRelationBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.relationBlock];
const getAgentRelationBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.relationBlock];
const getAgentRelationBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.relationBlock];
const getAgentDocumentBlockNotRFValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.documentBlockNotRF];
const getAgentDocumentBlockNotRFErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.documentBlockNotRF];
const getAgentDocumentBlockNotRFTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.documentBlockNotRF];
const getAgentAddressBlockNotRFValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlockNotRF];
const getAgentAddressBlocNotRFkErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlockNotRF];
const getAgentAddressBlockNotRFTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlockNotRF];
const getAgentBankBlockNotRFValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.bankBlockNotRF];
const getAgentBankBlockNotRFErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.bankBlockNotRF];
const getAgentBankBlockNotRFTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.bankBlockNotRF];
const getAgentAddressLivingBlockNotRFValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlockLivingNotRF];
const getAgentAddressLivingBlockNotRFErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlockLivingNotRF];
const getAgentAddressLivingBlockNotRFTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlockLivingNotRF];
const getAgentContactBlockNotRFValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.contactBlockNotRF];
const getAgentContactBlockNotRFErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.contactBlockNotRF];
const getAgentContactBlockNotRFTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.contactBlockNotRF];
/* eslint-enable */

export const getAgentPersonBlockSelector = createSelector(
  getAgentPersonBlockValues,
  getAgentPersonBlockErrors,
  getAgentPersonBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentDocumentBlockSelector = createSelector(
  getAgentDocumentBlockValues,
  getAgentDocumentBlockErrors,
  getAgentDocumentBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressBlockSelector = createSelector(
  getAgentAddressBlockValues,
  getAgentAddressBlockErrors,
  getAgentAddressBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentBankBlockSelector = createSelector(
  getAgentBankBlockValues,
  getAgentBankBlockErrors,
  getAgentBankBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressLivingBlockSelector = createSelector(
  getAgentAddressLivingBlockValues,
  getAgentAddressLivingBlockErrors,
  getAgentAddressLivingBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentInnSnilsBlockSelector = createSelector(
  getAgentInnSnilsBlockValues,
  getAgentInnSnilsBlockErrors,
  getAgentInnSnilsBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentContactBlockSelector = createSelector(
  getAgentContactBlockValues,
  getAgentContactBlockErrors,
  getAgentContactBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentRelationBlockSelector = createSelector(
  getAgentRelationBlockValues,
  getAgentRelationBlockErrors,
  getAgentRelationBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentDocumentBlockNotRFSelector = createSelector(
  getAgentDocumentBlockNotRFValues,
  getAgentDocumentBlockNotRFErrors,
  getAgentDocumentBlockNotRFTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressBlockNotRFSelector = createSelector(
  getAgentAddressBlockNotRFValues,
  getAgentAddressBlocNotRFkErrors,
  getAgentAddressBlockNotRFTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentBankBlockNotRFSelector = createSelector(
  getAgentBankBlockNotRFValues,
  getAgentBankBlockNotRFErrors,
  getAgentBankBlockNotRFTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressLivingBlockNotRFSelector = createSelector(
  getAgentAddressLivingBlockNotRFValues,
  getAgentAddressLivingBlockNotRFErrors,
  getAgentAddressLivingBlockNotRFTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentContactBlockNotRFSelector = createSelector(
  getAgentContactBlockNotRFValues,
  getAgentContactBlockNotRFErrors,
  getAgentContactBlockNotRFTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getInsuredIsSame = ({ agentData: { insuredIsSame } }) => insuredIsSame;
export const getDisableInsuredIsSame = ({
  agentData: {
    disableInsuredIsSame,
  },
}) => disableInsuredIsSame;
