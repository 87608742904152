import {
  changeBeneficiaryForm,
  getCopyValues,
  fromNullToStringObjects,
  getStartOfDayDate,
  initStateLivingAddress,
  checkLivingAddress,
  getInsLineIszObjectsProductForm,
} from 'helpers';
import { INSURED_FIELDS } from 'consts';
import {
  SET_PRODUCT_VALUES,
  SET_INSURER_VALUES,
  SET_INSURED_VALUES,
  SET_INSURED_IS_SAME,
  CHANGE_BENEFICIARY_COUNT,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  CHECK_PRODUCT_START,
  CHECK_PRODUCT_SUCCESS,
  CHECK_PRODUCT_FAIL,
  GET_PRODUCT_FORM_START,
  GET_PRODUCT_FORM_SUCCESS,
  GET_PRODUCT_FORM_FAIL,
  CHECK_SUM_START,
  CHECK_SUM_SUCCESS,
  CHECK_SUM_FAIL,
  SET_BENEFICIARY_VALUES,
  SAVE_FORMS_FORM_START,
  SAVE_FORMS_SUCCESS,
  SAVE_FORMS_FAIL,
  RESET_FORMS,
  RESET_ERRORS,
  GET_DATA_CONTRACT_START,
  GET_DATA_CONTRACT_SUCCESS,
  GET_DATA_CONTRACT_FAIL,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  SAVE_SCAN_FAIL,
  GET_CONTRACT_FILE_SUCCESS,
  GET_CONTRACT_FILE_FAIL,
  START_VALIDATE_TABS,
  CLEAR_VALIDATE_TABS,
  SET_PRODUCT_SCENARIO,
  SET_FUND_DESCRIPTION,
  GET_CONTRACT_COPY_SUCCESS,
  GET_ADDITIONAL_DOCS_SUCCESS,
  CHECK_ACCOUNT_NUMBERS_FAIL,
  CHECK_ACCOUNT_NUMBERS_SUCCESS,
  SET_INIT_DATA,
  FILL_INSURER_DATA_CHECKOUT,
  FILL_INSURED_DATA_ISZ,
  GET_ISZ_RESTIRCTIONS,
  GET_CURRENT_COURSE,
  SET_ANNUL_CONTRACT,
  SET_CONTRACT_FORMED,
  RESET_CTS_IDS,
  SET_EDIT_BASKET,
  SET_CTS_ID_ARR,
  DOWNLOAD_PRESENTATION,
  GET_SIGNING_TYPE,
  SET_SIGNING_TYPE,
  SET_IS_SCAN_UPLOAD_BY_CLIENT,
  SET_INIT_STATE_SIGNING_TYPE,
  CHECK_IS_ACTIVE_CONFIRM_BUTTON,
  SET_SMS_SENT,
  SENT_SMS,
  CLIENT_TEST_GET_QUESTIONS_SUCCESS,
  CHECK_PACKAGE_SERVICE_START,
  CHECK_PACKAGE_SERVICE_SUCCESS,
  SET_INSURER_IS_RESIDENT_RF,
  SET_INSURED_IS_RESIDENT_RF,
} from './types';
import { EXECUTION_CALCULATING_BASKET, SET_PRODUCT_ID } from '../basketConstructor/types';

const INITIAL_STATE = {
  productValues: {
    productPeriod: null,
    policySum: '',
    productVersion: null,
    premiumFreqId: null,
    birthDate: {},
  },
  insurerValues: null,
  insuredValues: null,
  insuredIsSame: true,
  contractData: {},
  beneficiaryValues: null,
  beneficiaryByLaw: true,
  sumError: null,
  insuredAgeError: null,
  childdAgeError: null,
  error: null,
  stage: 1,
  beneficiaries: [{}],
  products: [],
  productForm: {},
  isLoadingProductForm: false,
  isPrinted: false,
  ctsId: null,
  contractFiles: [],
  contractFile: null,
  validateTabsBeneficiaries: false,
  scenario: '',
  isLoadingScan: false,
  mainRequestId: 0,
  childRequestIds: [],
  constructorProduct: false,
  nszValues: {},
  isLoadingDailyLimit: false,
  isSuccessCheckSum: false,
  currencyRate: 0,
  currencyRateId: 0,
  refExchangeBankId: 0,
  isExchangeCoefFound: false,
  guaranteeLevel: null,
  isAnnul: false,
  isContractFormed: false,
  ctsIdArr: [],
  isEditBasket: false,
  productId: null,
  isDownloadingPresent: false,
  creatingProductId: null,
  isViewConstructorK1: false,
  selectedSigningType: '',
  isScanUploadByClient: false,
  isSmsSent: false,
  refUnionServicePackageId: null,
  isCopyContract: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_CTS_ID_ARR: {
      const { arr } = payload;

      return {
        ...state,
        ctsIdArr: arr,
      };
    }

    case SET_PRODUCT_ID: {
      const { productId } = payload;

      return {
        ...state,
        productId,
      };
    }

    case SET_EDIT_BASKET: {
      const { isEditBasket } = payload;

      return {
        ...state,
        isEditBasket,
      };
    }

    case RESET_CTS_IDS: {
      return {
        ...state,
        ctsIdArr: [],
      };
    }

    case GET_ISZ_RESTIRCTIONS.fail: {
      const { productForm } = state;

      return {
        ...state,
        productForm: {
          ...productForm,
          iszRestrictions: [],
        },
      };
    }

    case SET_ANNUL_CONTRACT: {
      const { isAnnul } = payload;

      return {
        ...state,
        isAnnul,
      };
    }

    case SET_CONTRACT_FORMED: {
      const { isContractFormed } = payload;

      return {
        ...state,
        isContractFormed,
      };
    }

    case GET_ISZ_RESTIRCTIONS.success: {
      const { restrictions } = payload;
      const { productForm } = state;

      return {
        ...state,
        productForm: {
          ...productForm,
          iszRestrictions: restrictions,
        },
      };
    }

    case GET_CURRENT_COURSE.fail: {
      return state;
    }

    case GET_CURRENT_COURSE.success: {
      const {
        currencyRate,
        currencyRateId,
        refExchangeBankId,
        refExchangeRatesRegimesCoefId,
      } = payload;
      const { productForm } = state;

      return {
        ...state,
        productForm: {
          ...productForm,
          currencyRate,
          currencyRateId,
          refExchangeBankId,
          refExchangeRatesRegimesCoefId,
        },
        isExchangeCoefFound: true,
      };
    }

    case FILL_INSURED_DATA_ISZ: {
      const { productForm: { insurerIsInsured } } = state;
      if (insurerIsInsured) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        insuredValues: {
          ...payload,
        },
        insuredIsSame: false,
      };
    }

    case FILL_INSURER_DATA_CHECKOUT: {
      return {
        ...state,
        insurerValues: {
          ...payload,
        },
      };
    }

    case CHECK_ACCOUNT_NUMBERS_SUCCESS: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }
    case CHECK_ACCOUNT_NUMBERS_FAIL: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }

    case EXECUTION_CALCULATING_BASKET.success: {
      return {
        ...state,
        productValues: {
          productPeriod: null,
          policySum: '',
          productVersion: null,
          premiumFreqId: null,
          birthDate: {},
        },
        guaranteeLevel: payload.guaranteeLevel,
        mainRequestId: payload.mainRequestId,
        childRequestIds: payload.childRequestIds,
        constructorProduct: true,
      };
    }
    case GET_CONTRACT_COPY_SUCCESS: {
      const { productForm } = state;
      const newInsurer = fromNullToStringObjects(payload.insurer);
      const newInsured = fromNullToStringObjects(payload.insured);

      return {
        ...state,
        mainRequestId: payload.mainRequestId,
        childRequestIds: payload.childRequestIds,
        beneficiaries: payload.beneficiary,
        guaranteeLevel: payload.guaranteeLevel,
        constructorProduct: payload.constructorProduct,
        productForm: {
          ...productForm,
          iszRestrictions: payload.iszRestrictions,
          insLineIszParamsFront: payload.insLineIszParamsFront,
        },
        productValues: {
          productPeriod: payload.productTermId,
          productVersion: payload.insLineIszObjectId,
          policySum: payload.policyAmmount,
          insuranceAmmount: payload.insuranceAmmount,
          regDate: payload.dateReg,
          premiumFreqId: payload.premiumFreqId,
          birthDate: getStartOfDayDate(payload.insured.birthDate),
        },
        insurerValues: {
          ...newInsurer,
          birthDate: getStartOfDayDate(payload.insurer.birthDate),
          when: getStartOfDayDate(payload.insurer.when),
          validUntil: getStartOfDayDate(payload.insurer.validUntil),
          isManualEnter: !payload.insurer.fiasId,
          isManualEnterLiving: !checkLivingAddress(payload.insurer) &&
            !payload.insurer.fiasIdLiving,
          livingAddressIsSame: checkLivingAddress(payload.insurer),
          republic: payload.insurer.republic || payload.insurer.city,
          republicLiving: payload.insurer.republicLiving || payload.insurer.cityLiving,
          ...(payload.insurer.fiasId === payload.insurer.fiasIdLiving &&
            payload.insurer.isResidentRF && initStateLivingAddress),
          ...(checkLivingAddress(payload.insurer) && initStateLivingAddress),
          isBankRU: !payload.insurer.swift,
          intermediaryBank: Boolean(payload.insurer.ibBankSwift),
        },
        insuredValues: payload.insurerIsInsured ? null : {
          ...newInsured,
          birthDate: getStartOfDayDate(payload.insured.birthDate),
          when: getStartOfDayDate(payload.insured.when),
          livingAddressIsSame: checkLivingAddress(payload.insured),
          validUntil: getStartOfDayDate(payload.insured.validUntil),
          isManualEnter: !payload.insured.fiasId,
          isManualEnterLiving: !checkLivingAddress(payload.insured) &&
            !payload.insured.fiasIdLiving,
          republic: payload.insured.republic || payload.insured.city,
          republicLiving: payload.insured.republicLiving || payload.insured.cityLiving,
          ...(checkLivingAddress(payload.insured) && initStateLivingAddress),
        },
        insuredIsSame: payload.insurerIsInsured,
        isBeneficiaries: payload.isBeneficiaryByLaw,
        scenario: payload.scenario,
        isScanUploadByClient: false,
        isCopyContract: true,
      };
    }
    case SET_PRODUCT_VALUES: {
      return { ...state, productValues: payload, stage: 2 };
    }

    case SET_INSURER_VALUES: {
      const values = payload;
      if (!values.patronymic) {
        values.patronymic = '';
      }

      return { ...state, insurerValues: payload, stage: 3 };
    }

    case SET_INSURED_VALUES: {
      const { insuredIsSame } = state;
      const values = insuredIsSame
        ? { ...getCopyValues(INSURED_FIELDS, payload), relationship: 0 }
        : payload;

      if (!values.patronymic) {
        values.patronymic = '';
      }

      return { ...state, insuredValues: values, stage: 4 };
    }

    case SET_INSURED_IS_SAME: {
      return {
        ...state,
        insuredIsSame: payload,
      };
    }

    case SET_BENEFICIARY_VALUES: {
      return {
        ...state,
        stage: 5,
      };
    }

    case CHANGE_BENEFICIARY_COUNT: {
      const { beneficiaries } = state;
      changeBeneficiaryForm(beneficiaries, payload);

      return { ...state };
    }

    case GET_PRODUCTS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: payload,
        isLoading: false,
      };
    }

    case GET_PRODUCTS_FAIL: {
      return {
        ...state,
        products: [],
        isLoading: false,
      };
    }

    case CHECK_PRODUCT_START: {
      return {
        ...state,
        creatingProductId: payload.id,
      };
    }

    case CHECK_PRODUCT_SUCCESS: {
      return {
        ...state,
        productValues: {
          productPeriod: null,
          productVersion: null,
          policySum: '',
          premiumFreqId: null,
          birthDate: {},
        },
      };
    }

    case SET_PRODUCT_SCENARIO: {
      return {
        ...state,
        scenario: payload,
      };
    }

    case CHECK_PRODUCT_FAIL: {
      return {
        ...state,
      };
    }

    case GET_PRODUCT_FORM_START: {
      return {
        ...state,
        isLoadingProductForm: true,
      };
    }

    case GET_PRODUCT_FORM_SUCCESS: {
      const { productValues, fund, productForm } = state;
      const {
        data,
        baskedId,
        constructorProduct,
        data: {
          productTerms, insLineIszObjects, productsPremiumFreq, scenario,
        },
      } = payload;
      const selectorValue = productTerms[0].termId;

      const isOnePremiumFreq = productsPremiumFreq.length === 1;

      switch (scenario) {
        case 'isz_1':
        case 'isz_2':
        case 'isz_3':
        case 'isz_4':
        case 'isz_5':
        case 'isz_6':
          if (insLineIszObjects?.length && !productValues.productVersion) {
            productValues.productVersion =
            insLineIszObjects.reduce((a, b) => (a.id < b.id ? a : b)).id;
          }
          if (productTerms?.length && !productValues.productPeriod) {
            productValues.productPeriod =
            productTerms.reduce((a, b) => (a.termId < b.termId ? a : b)).termId;
          }
          break;
        default: break;
      }

      if (constructorProduct) {
        return {
          ...state,
          productForm: {
            ...productForm,
            ...data,
          },
          mainRequestId: baskedId,
          constructorProduct,
          fund: {
            ...fund,
            nameShort: data.nameShort,
            color: data.color,
            description: data.description,
          },
          isLoadingProductForm: false,
          productValues: {
            ...productValues,
            productPeriod: selectorValue,
            regDate: data.regDate,
            premiumFreqId: isOnePremiumFreq
              ? productsPremiumFreq[0].value
              : productValues.premiumFreqId,
          },
          insLineIszObjects: getInsLineIszObjectsProductForm(insLineIszObjects),
        };
      }

      return {
        ...state,
        mainRequestId: baskedId,
        productForm: {
          ...data,
          ...productForm,
        },
        constructorProduct,
        fund: {
          ...fund,
          nameShort: data.nameShort,
          color: data.color,
          description: data.description,
        },
        isLoadingProductForm: false,
        insLineIszObjects: getInsLineIszObjectsProductForm(insLineIszObjects),
        productValues: {
          ...productValues,
          premiumFreqId: isOnePremiumFreq
            ? productsPremiumFreq[0].value
            : productValues.premiumFreqId,
        },
      };
    }

    case GET_PRODUCT_FORM_FAIL: {
      return {
        ...state,
        isLoadingProductForm: false,
      };
    }

    case CHECK_SUM_START: {
      return {
        ...state,
        isLoadingDailyLimit: true,
        isSuccessCheckSum: false,
        sumError: null,
      };
    }

    case CHECK_SUM_SUCCESS: {
      return {
        ...state,
        sumError: null,
        isSuccessCheckSum: true,
        isLoadingDailyLimit: false,
      };
    }

    case CHECK_SUM_FAIL: {
      return {
        ...state,
        sumError: payload,
        isLoadingDailyLimit: false,
      };
    }

    case SAVE_FORMS_FORM_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SAVE_FORMS_SUCCESS: {
      const { ctsIdArr } = state;

      ctsIdArr.push(payload);

      return {
        ...state,
        error: null,
        isLoading: false,
        ctsId: payload,
        ctsIdArr,
      };
    }

    case CHECK_PACKAGE_SERVICE_START: {
      return {
        ...state,
        refUnionServicePackageId: null,
      };
    }

    case CHECK_PACKAGE_SERVICE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case SAVE_FORMS_FAIL: {
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    }

    case RESET_FORMS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case RESET_ERRORS: {
      return {
        ...state,
        sumError: null,
        error: null,
      };
    }

    case GET_DATA_CONTRACT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_DATA_CONTRACT_SUCCESS: {
      return {
        ...state,
        contractData: {
          ...payload,
          allScanTypes: payload.allScanTypes,
        },
        isLoading: false,
      };
    }

    case SET_FUND_DESCRIPTION:
      return {
        ...state,
        fund: payload,
      };

    case GET_DATA_CONTRACT_FAIL: {
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    }

    case UPDATE_STATUS_SUCCESS: {
      const { isPrint, status, statusCode } = payload;

      return {
        ...state,
        ...(isPrint && { isPrinted: true }),
        contractData: {
          ...state.contractData,
          statusCurrent: status,
          statusCode,
        },
      };
    }

    case UPDATE_STATUS_FAIL: {
      return {
        ...state,
        error: payload,
      };
    }

    case SAVE_SCAN_FAIL: {
      return {
        ...state,
        error: payload,
      };
    }

    case GET_CONTRACT_FILE_SUCCESS: {
      return {
        ...state,
        contractFile: payload,
        isPrinted: true,
      };
    }

    case GET_CONTRACT_FILE_FAIL: {
      return {
        ...state,
        error: payload,
      };
    }

    case GET_ADDITIONAL_DOCS_SUCCESS: {
      const rebuildData = payload.map(({ id, description, fileSystemPath }) => ({
        text: description,
        id,
        fileSystemPath,
      }));

      return {
        ...state,
        additionalDocuments: rebuildData,
      };
    }

    case START_VALIDATE_TABS: {
      return { ...state, validateTabsBeneficiaries: true };
    }

    case CLEAR_VALIDATE_TABS: {
      return { ...state, validateTabsBeneficiaries: false };
    }

    case SET_INIT_DATA:
      return {
        ...state,
        productValues: {
          productPeriod: null,
          policySum: '',
          productVersion: null,
          premiumFreqId: null,
          birthDate: {},
        },
        insurerValues: null,
        insuredValues: null,
        insuredIsSame: true,
        contractData: {},
        beneficiaryValues: null,
        beneficiaryByLaw: true,
        sumError: null,
        insuredAgeError: null,
        childdAgeError: null,
        error: null,
        stage: 1,
        beneficiaries: [{}],
        products: [],
        productForm: {},
        isLoadingProductForm: false,
        isPrinted: false,
        ctsId: null,
        contractFiles: [],
        contractFile: null,
        validateTabsBeneficiaries: false,
        scenario: '',
        isLoadingScan: false,
        mainRequestId: 0,
        childRequestIds: [],
        constructorProduct: false,
        nszValues: {},
        isLoadingDailyLimit: false,
        isSuccessCheckSum: false,
        currencyRate: 0,
        currencyRateId: 0,
        refExchangeBankId: 0,
        isExchangeCoefFound: false,
        guaranteeLevel: null,
        isAnnul: false,
        isContractFormed: false,
        ctsIdArr: [],
        isEditBasket: false,
      };

    case DOWNLOAD_PRESENTATION.fail:
    case DOWNLOAD_PRESENTATION.success: {
      return {
        ...state,
        isDownloadingPresent: false,
      };
    }

    case DOWNLOAD_PRESENTATION.start: {
      return {
        ...state,
        isDownloadingPresent: true,
      };
    }

    case GET_SIGNING_TYPE.success: {
      const defaultSigningType = payload.find((item) => item.isDefault)?.value;

      return {
        ...state,
        signingTypes: payload,
        selectedSigningType: defaultSigningType,
      };
    }

    case SET_SIGNING_TYPE: {
      return {
        ...state,
        selectedSigningType: payload,
      };
    }

    case SET_IS_SCAN_UPLOAD_BY_CLIENT: {
      return {
        ...state,
        isScanUploadByClient: payload,
      };
    }

    case SET_INIT_STATE_SIGNING_TYPE: {
      return {
        ...state,
        selectedSigningType: '',
        isActive: false,
        isScanUploadByClient: false,
      };
    }

    case CHECK_IS_ACTIVE_CONFIRM_BUTTON.success: {
      return {
        ...state,
        isActive: payload.isActive,
        smsTimer: payload.smsRepeatTimeLimit,
      };
    }

    case SET_SMS_SENT: {
      return {
        ...state,
        isSmsSent: payload,
      };
    }

    case SENT_SMS.success: {
      return {
        ...state,
        smsTimer: payload.smsRepeatTimeLimit,
      };
    }
    case CLIENT_TEST_GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: payload,
        isLoading: false,
      };
    }

    case SET_INSURER_IS_RESIDENT_RF: {
      const { insurerValues } = state;

      return {
        ...state,
        insurerValues: {
          ...insurerValues,
          isResidentRF: payload,
        },
      };
    }
    case SET_INSURED_IS_RESIDENT_RF: {
      const { insuredValues } = state;

      return {
        ...state,
        insuredValues: {
          ...insuredValues,
          isResidentRF: payload,
        },
      };
    }

    default:
      return state;
  }
};
